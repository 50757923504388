import React, { useState } from 'react';
import { Table, TableBody, TableHead, Paper, Grid, TextField, TableRow, TableCell, IconButton, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { backendDateFormat, displayDateFormatShort } from '../../../constants';
import { CkEditorText } from '../../../shared_elements';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { globalDeleteService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
import { DeletePopUp } from '../../../shared_elements';

function TechnicalSummary({ data, setData, onFieldChange, callthisapi, getResponseBack, currentFormDetail, setInitialValue, setLoading, isLoading }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState({ modal: false, data: null })

    const techAction = data?.powerplant_fleet_tech_summaries && data?.powerplant_fleet_tech_summaries?.tech_open_action?.filter(item => item?.id)?.length === data?.powerplant_fleet_tech_summaries?.tech_open_action?.length

    const techActionCall = (modeTye, row) => {
        if (modeTye === 'add') {
            setData(prev => (
                {
                    ...prev,
                    powerplant_fleet_tech_summaries: {
                        ...prev.powerplant_fleet_tech_summaries,
                        tech_open_action: [
                            ...prev.powerplant_fleet_tech_summaries?.tech_open_action,
                            { description: null, assigned_responsibility: null, selected_by: null }]
                    }
                }

            ));
        } else if (row?.id) {
            setLoading(true)
            globalDeleteService(`/technical/t015/basic-inspection/${row?.id}/delete/?module=action`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Technical Open Actions:',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    } else {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null })
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Technical Open Actions:',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    }
                });
        } else {
            setData(prev => (
                {
                    ...(prev),
                    powerplant_fleet_tech_summaries: {
                        ...prev.powerplant_fleet_tech_summaries,
                        tech_open_action:
                            [...data?.powerplant_fleet_tech_summaries.tech_open_action]?.filter(item => item?.id)
                    }
                }

            ));
        }

    }

    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5> Technical Summary : </h5>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            className='engine-stand-select'
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "125px" }}
                            id="demonstrated"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.technical_details?.demonstrated)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'demonstrated', value, 'powerplant_fleet_tech_summaries', '', '', 'technical_details')} // need to check 
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>demonstrated a well-run technical department.</span>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='Please enter.. Details general summary of their technical efficiencies or lack there of.'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.technical_details?.technical_efficiencies_notes || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.technical_details?.technical_efficiencies_notes)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData((prev) => ({
                                    ...prev,
                                    powerplant_fleet_tech_summaries: {
                                        ...prev?.powerplant_fleet_tech_summaries,
                                        technical_details: {
                                            ...prev?.powerplant_fleet_tech_summaries?.technical_details,
                                            technical_efficiencies_notes: value
                                        }
                                    }
                                }))}

                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "125px" }}
                            id="organisational_chart"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.technical_details?.organisational_chart)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'organisational_chart', value, 'powerplant_fleet_tech_summaries', '', '', 'technical_details')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>shared a copy of their Organisational Chart.</span>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='Please enter.. details of reporting lines and department structures'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.technical_details?.department_structures || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.technical_details?.department_structures)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData((prev) => ({
                                    ...prev,
                                    powerplant_fleet_tech_summaries: {
                                        ...prev?.powerplant_fleet_tech_summaries,
                                        technical_details: {
                                            ...prev?.powerplant_fleet_tech_summaries?.technical_details,
                                            department_structures: value
                                        }
                                    }
                                }))}
                            />
                        </div>
                    </Grid>
                </Grid>
                <h6 style={{ margin: '10px 0px 0px' }}> Technical Open Actions :</h6>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <p style={{ margin: '20px 0px 0px' }}>The items below require follow up actions after the Mid-Lease Inspection visit</p>
                    </li>
                    <li className="list-inline-item" style={{ float: 'right' }}>
                        <Button disabled={!onCheckFormPermission(currentFormDetail) ? true : techAction ? false : true} onClick={() => techActionCall("add")} color="primary" variant="contained" size="small" style={{ margin: '5px' }} >+ ADD</Button>
                    </li>
                </ul>
                <Table className='mui-table-format-t015'>
                    <TableHead className='table-align-head'>
                        <TableRow >
                            <TableCell> <h6 >SR No</h6> </TableCell>
                            <TableCell> <h6>Description</h6> </TableCell>
                            <TableCell> <h6>Responsibility</h6> </TableCell>
                            <TableCell> <h6>By</h6> </TableCell>
                            <TableCell> <h6>Action</h6> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.powerplant_fleet_tech_summaries?.tech_open_action?.map((item, index) => (
                            <TableRow>
                                <TableCell align='center'>{index + 1}</TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        margin='normal'
                                        fullWidth
                                        id='description'
                                        inputProps={{ maxLength: 50 }}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        InputLabelProps={{ shrink: true }}
                                        value={item?.description || ''}
                                        onChange={(e) => onFieldChange(e, "description", e.target.value, 'powerplant_fleet_tech_summaries', index, '', 'tech_open_action')}
                                        placeholder="Enter."
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <Autocomplete
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        options={['SAEL', 'LESSEE']}
                                        getOptionLabel={option => option}
                                        id="assigned_responsibility"
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.assigned_responsibility || null}
                                        onChange={(e, value) => onFieldChange(e, "assigned_responsibility", value, 'powerplant_fleet_tech_summaries', index, '', 'tech_open_action')}
                                        renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <Autocomplete
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        options={['Redelivery', 'Next MLI', 'ASAP']}
                                        getOptionLabel={option => option}
                                        id="selected_by"
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.selected_by || null}
                                        onChange={(e, value) => onFieldChange(e, "selected_by", value, 'powerplant_fleet_tech_summaries', index, '', 'tech_open_action')}
                                        renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton disabled={!onCheckFormPermission(currentFormDetail)} color='secondary' >
                                        <DeleteOutlinedIcon onClick={() => { item?.id ? setDeleteModal({ modal: true, data: item }) : techActionCall() }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                        ))
                        }

                    </TableBody>
                </Table>
                <h6 style={{ margin: '25px 0px 10px' }}> Additional Comments / Highlights / Concerns : </h6>
                <Grid item xs={12} md={12} sm={12}>
                    <div>
                        <CkEditorText
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            htmlData={data?.powerplant_fleet_tech_summaries?.additional_comments || ''}
                            onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.additional_comments)}
                            onFocusData={(e, value) => setInitialValue(e, value)}
                            onChangeData={(value) => setData({
                                ...data,
                                powerplant_fleet_tech_summaries: {
                                    ...data.powerplant_fleet_tech_summaries,
                                    additional_comments: value
                                }
                            })}
                        />
                    </div>
                </Grid>
                <h5 style={{ margin: '15px 0px 10px' }}> Report Details :</h5>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h6 style={{ margin: '10px 0px 10px' }}> Report Completed By :</h6>
                    </li>
                    <li className="list-inline-item" style={{ display: 'inline-flex' }}>
                        <TextField
                            style={{ width: '250px', marginLeft: '20px' }}
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            margin='normal'
                            id='report_completed_by'
                            inputProps={{ maxLength: 100 }}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            InputLabelProps={{ shrink: true }}
                            value={data?.powerplant_fleet_tech_summaries?.report_completed_by || ''}
                            onChange={(e) => onFieldChange(e, "report_completed_by", e.target.value, 'powerplant_fleet_tech_summaries')}
                            placeholder="Enter."
                        />
                    </li>
                </ul>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h6 style={{ margin: '15px 0px 15px' }}> Report Completion Date :  </h6>
                    </li>
                    <li className="list-inline-item" style={{ display: 'inline-flex' }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                margin="normal"
                                id="report_completion_date"
                                format={displayDateFormatShort}
                                style={{ width: "250px", marginLeft: '2px' }}
                                InputLabelProps={{ shrink: true }}
                                value={data?.powerplant_fleet_tech_summaries?.report_completion_date || null}
                                onChange={(date) => onFieldChange('', 'report_completion_date', date ? moment(date).format(backendDateFormat) : null, 'powerplant_fleet_tech_summaries', '', 'date')}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </li>
                </ul>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h6 style={{ margin: '10px 0px 10px' }}> Report Reviewed By :</h6>
                    </li>
                    <li className="list-inline-item" style={{ display: 'inline-flex' }}>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            margin='normal'
                            style={{ width: '250px', marginLeft: '31px' }}
                            id='report_reviewed_by'
                            inputProps={{ maxLength: 100 }}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            InputLabelProps={{ shrink: true }}
                            value={data?.powerplant_fleet_tech_summaries?.report_reviewed_by || ''}
                            onChange={(e) => onFieldChange(e, "report_reviewed_by", e.target.value, 'powerplant_fleet_tech_summaries')}
                            placeholder="Enter."
                        />
                    </li>
                </ul>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h6 style={{ margin: '15px 0px 15px' }}>  Review Completion Date :  </h6>
                    </li>
                    <li className="list-inline-item" style={{ display: 'inline-flex' }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                margin="normal"
                                id="review_completion_date"
                                format={displayDateFormatShort}
                                style={{ width: "250px" }}
                                InputLabelProps={{ shrink: true }}
                                value={data?.powerplant_fleet_tech_summaries?.review_completion_date || null}
                                onChange={(date) => onFieldChange('', 'review_completion_date', date ? moment(date).format(backendDateFormat) : null, 'powerplant_fleet_tech_summaries', '', 'date')}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </li>
                </ul>
                <h6 style={{ margin: '10px 0px 10px' }}> Reviewer Additional Comments / Highlights / Concerns : </h6>
                <Grid item xs={12} md={12} sm={12}>
                    <div>
                        <CkEditorText
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            htmlData={data?.powerplant_fleet_tech_summaries?.reviewer_comments || ''}
                            onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.reviewer_comments)}
                            onFocusData={(e, value) => setInitialValue(e, value)}
                            onChangeData={(value) => setData({
                                ...data,
                                powerplant_fleet_tech_summaries: {
                                    ...data.powerplant_fleet_tech_summaries,
                                    reviewer_comments: value
                                }
                            })}
                        />
                    </div>
                </Grid>
                <Table className='mui-table-format-t015' style={{ margin: "25px 0px 10px" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell><h6 style={{ textAlign: 'center' }}>Inspector Signature</h6></TableCell>
                            <TableCell><h6 style={{ textAlign: 'center' }}>Reviewer Signature</h6></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <div>
                                    <CkEditorText
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        htmlData={data?.powerplant_fleet_tech_summaries?.inspector_signature || ''}
                                        onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.inspector_signature)}
                                        onFocusData={(e, value) => setInitialValue(e, value)}
                                        onChangeData={(value) => setData({
                                            ...data,
                                            powerplant_fleet_tech_summaries: {
                                                ...data.powerplant_fleet_tech_summaries,
                                                inspector_signature: value
                                            }
                                        })}
                                    />
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <CkEditorText
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        htmlData={data?.powerplant_fleet_tech_summaries?.reviewer_signature || ''}
                                        onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.reviewer_signature)}
                                        onFocusData={(e, value) => setInitialValue(e, value)}
                                        onChangeData={(value) => setData({
                                            ...data,
                                            powerplant_fleet_tech_summaries: {
                                                ...data.powerplant_fleet_tech_summaries,
                                                reviewer_signature: value
                                            }
                                        })}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
            {deleteModal?.modal ?
                <DeletePopUp
                    modal={deleteModal.modal}
                    toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
                    title="Technical Open Actions"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => techActionCall('', deleteModal?.data)}
                /> : null
            }
        </section>
    );
}

export default TechnicalSummary