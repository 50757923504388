import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { regexConstants } from '../../../constants/regEx';
import { globalPostService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus } from '../../../utils_v2'
import { globalCurrency } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const EditProjectCurrency = ({params, toggleModalFn, modal}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [projectBudget, setProjectBudget] = useState({});
    const [error, setError] = useState({});
    const onSubmit = () => {
        let validationInputs = {
            currency:projectBudget.currency ? '':'Please select Currency',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            globalPostService(`technical/project/${params.project_slug}/budgets/updateCurrency/`, projectBudget)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){                
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    toggleModalFn();
                    trackActivity('Project Management', {
                        event_type: 'Updated Project Currency',
                        page_title: 'Expense Management Project Currency',
                        currency: projectBudget.currency,
                        project_slug: params.project_slug
                      });
                }else{
                    trackActivity('Project Management', {
                        event_type: 'Update Project Currency Error!',
                        page_title: 'Expense Management Project Currency',
                        currency: projectBudget.currency,
                        project_slug: params.project_slug,
                        error: validationInputs,
                        error_message: response.data.message
                      });
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Fragment>
            <Dialog
                maxWidth="md"
                open={modal}
                onClose={toggleModalFn}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit Project Currency"}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Autocomplete
                                options = {globalCurrency}
                                getOptionLabel={option => option.name}
                                id="currency"
                                value={projectBudget.currency ? projectBudget.currency : null}
                                onChange={(e, value) => setProjectBudget({...projectBudget, currency:value})}
                                renderInput={params => <TextField required error={error.currency} helperText={error.currency} onFocus={() => setError({...error, currency:''})} {...params} label="Currency" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="budget_amount"
                                label="Amount"
                                fullWidth
                                margin="normal"
                                value={projectBudget.budget_amount ? projectBudget.budget_amount : ''}
                                InputLabelProps={{shrink: true}}
                                inputProps={{maxLength: 10}}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? setProjectBudget({...projectBudget, budget_amount:e.target.value}) : e.preventDefault()}}
                                error={error.budget_amount}
                                helperText={error.budget_amount}
                                onFocus={() => setError({...error, budget_amount:''})}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>
                        Cancel
                    </Button>
                    <Button disabled={isLoading} onClick={onSubmit} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>  
        </Fragment>
    )
}
export default withRouter(EditProjectCurrency);