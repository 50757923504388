import React, { useState } from 'react';
import { Grid, Tabs, Tab, Paper,  DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { EmptyCollection} from '../../shared_elements';
import CloseIcon from "@material-ui/icons/Close"
import AppsCard from './AppsCard';
import config from '../../config';
import { getLocalStorageInfo } from '../../utils_v2';
import {leaseManagementIcon,recordDataRoomIcon,maintenanceManagementIcon,mrCalcIcon,aircraftValuationIcon, camoIcon } from '../assets/img';

const ApplicationsAccess = ({ handleClose }) => {
	const [tabIndex, setTabIndex] = useState('active');
	const applications = [
		{
			label: 'Lease Management',
			description: 'Effectively manage commercial leases and contracts of the entire fleet - aircraft, engines and even sub-modules through the entire economic life of the asset',
			logo: leaseManagementIcon,
			access: getLocalStorageInfo()?.user?.permission?.contracts,
			url: config.domain.lease,
			isBeta: false
		},
		{
			label: 'Records Dataroom',
			description: 'Centralised, secure online Documents Management System (DMS) for digitised aircraft records storage retrieval and collaboration',
			logo: recordDataRoomIcon,
			access: getLocalStorageInfo()?.user?.permission?.records,
			url: config.domain.records,
			isBeta: false
		},
		{
			label: 'Maintenance Claim Management',
			description: 'Seamlessly track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims.',
			logo: maintenanceManagementIcon,
			access: getLocalStorageInfo()?.user?.permission?.maintenance,
			url: config.domain.maintenance,
			isBeta: false
		},
		{
			label: 'CommVerge',
			description: 'A potent decision application for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations.',
			logo: mrCalcIcon,
			access: getLocalStorageInfo()?.user?.permission?.mr_calculator,
			url: config.domain.commverge,
			isBeta: false
		},
		{
			label: 'fin-S',
			description: 'Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers.',
			logo: aircraftValuationIcon,
			access: getLocalStorageInfo()?.user?.permission?.valuation,
			url: config.domain.valuation,
			isBeta: false
		},
        {
			label: 'Airworthiness Management',
			description: 'Elevate aircraft safety and compliance with our Continuing Airworthiness Management Product. Streamline maintenance, track inspections, and ensure your fleet always soars with confidence.',
			logo: camoIcon,
			access: getLocalStorageInfo()?.user?.permission?.camo,
			url: config.domain.camo,
			isBeta: false
		},
	]
	return (
		<>
			<DialogTitle  style={{backgroundColor:'unset',color:'unset',padding:"10px 30px 10px 40px"}}>Applications
				<span style={{ float: 'right' }}><CloseIcon onClick={handleClose} /></span>
			</DialogTitle>
			<DialogContent dividers ={true}>
				<section className='application-landing-page' >
					{applications.filter(item => !item.access).length ?
						<Paper square style={{ marginBottom: '10px' }}>
							<Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="simple tabs example">
								<Tab style={{ textTransform: 'none' }} label="ACTIVE APPLICATION(s)" value='active' />
								<Tab style={{ textTransform: 'none' }} label="INACTIVE APPLICATION(s)" value='inactive' />
							</Tabs>
						</Paper> : null
					}
					{tabIndex === 'active' ?
						<>
							{(() => {
								const activeApps = applications.filter(item => item.access);
								if (activeApps.length) {
									return (
										<div>
											<Grid container spacing={2}>
												{activeApps.map((app) =>
													<AppsCard item={app} />
												)}
											</Grid>
										</div>
									)
								} else {
									return (
										<div style={{ textAlign: 'center', padding: '20px 0' }}>
											<EmptyCollection title='No active application access ' />
										</div>
									)
								}
							})()}
						</> : null
					}
					{tabIndex === 'inactive' ?
						<>
							{(() => {
								const inActiveApps = applications.filter(item => !item.access);
								if (inActiveApps?.length) {
									return (
										<div>
											<Grid container spacing={2}>
												{inActiveApps.map((app) =>
													<AppsCard item={app} />
												)}
											</Grid>
										</div>
									)
								} else {
									return null
								}
							})()}
						</> : null
					}
				</section>
			</DialogContent>
            <DialogActions>
                <Button variant='outlined' size='small' color='primary' style={{marginRight:"15px"}} onClick={handleClose} >Close</Button>
            </DialogActions>
		</>
	)
}
export default ApplicationsAccess;