import React, { useState, useEffect } from 'react';
import { Link, browserHistory } from 'react-router';
import { Grid, Avatar, Menu, MenuItem, TextField, Tooltip, Badge, Hidden } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFetchApi } from './apiServices';
import { Logo, Title, ReportAnIssue } from './components';
import { cartIcon } from '../assets/img';
import appConfig from '../../config';
import {  getLocalStorageInfo, eraseGlobalCookie } from '../../utils_v2';
import { trackLogin, trackLogOut } from '../../utils/mixpanel';
import "../assets/styles/layout.scss"
import { getGlobalCookie, setGlobalCookie } from '../../utils';
import { globalPostService } from '../../utils_v2/globalApiServices';
import PageLoader from '../components/PageLoader';

const UserInfo = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const userDetail = getLocalStorageInfo()?.user;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onLogout = () => {
        trackLogOut(getLocalStorageInfo())
        localStorage.clear();
        eraseGlobalCookie('lessorAccess')
        eraseGlobalCookie('domain')
        eraseGlobalCookie('userName')
        browserHistory.push('/login');
        eraseGlobalCookie('redirectURIProjects')
        sessionStorage.removeItem('initial_pop');
    }

    return (
        <>
            <span className='user-details' aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar style={{ height: '30px', width: '30px', placeSelf: 'center' }} alt={userDetail?.name || ''} src={userDetail?.profile_pic || ''} />
                <div className='flex-centered' style={{ height: '44px' }}>
                    <ul className="list-inline" style={{ padding: '5px' }} >
                        <li>{userDetail?.name ? <h5>{userDetail.name}</h5> : null}</li>
                        <li>{userDetail?.designation ? <p>{userDetail.designation}</p> : null}</li>
                    </ul>

                </div>
                <ArrowDropDownIcon style={{ alignSelf: 'center', marginLeft: '5px' }} fontSize='small' color='primary' />
            </span>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className='user-menu-list'
            >
                <MenuItem onClick={() => { handleClose(); window.open(`${appConfig.domain.subDomian}user-profile`, '_blank') }}>Profile</MenuItem>
                { userDetail?.permission?.console?.user_invite &&
                    (userDetail?.permission?.console?.user_invite?.indexOf('C') != -1 && getLocalStorageInfo()?.defaultLessor?.lessor_type == 1) ?
                    <MenuItem onClick={() => { handleClose(); window.open(`${appConfig.domain.subDomian}assets-listing?addUser=true`, '_blank') }}>Invite User</MenuItem>
                    : null}
                <MenuItem onClick={() => { handleClose(); onLogout() }}>Logout</MenuItem>
            </Menu>
        </>
    )
}
const Header = (props) => {
    const [reportAnIssueInfo, setReportAnIssueInfo] = useState({ modal: false });
    const [loading, setLoading] = useState(false);

    const { fetchData } = useFetchApi()
    useEffect(() => {
        if (!getLocalStorageInfo()?.user?.permission) {
            trackLogOut(getLocalStorageInfo())
            localStorage.clear();
            browserHistory.push('/login')
        }
    }, [])

    const userAuthorization = (lessorId) => {
        setLoading(true)
 let location = browserHistory.getCurrentLocation().pathname
      globalPostService('/api/user-authorization/', { lessor_id: lessorId })
            .then(response => {
                setLoading(false)
              if (response.data.statusCode == 200) {
                let userDetail =response.data.data;
                userDetail = {
                  ...userDetail,
                  access: response.data.data.lessor_access_token,
                  defaultLessor: response.data.data.current_lessor,
                  user: response.data.data.user
                }
                let baseDomain = '.sparta.aero', expireAfter = new Date();
                localStorage.setItem('userInfo', JSON.stringify(userDetail));
                let cookieData = JSON.stringify({ access: response.data.data.lessor_access_token, id: response.data.data.current_lessor.id, refresh: '', environment: appConfig.env.key })
                let cookieName = JSON.stringify({ name: response.data.data.user.name, designation: response.data.data.user.designation })
                setGlobalCookie('userName', cookieName, 3)
                setGlobalCookie("lessorAccess", cookieData, 3)
                setGlobalCookie("domain", baseDomain, 3)
                let redirectURIProjects = getGlobalCookie('redirectURIProjects')
                if (redirectURIProjects !== undefined && redirectURIProjects !== null && redirectURIProjects !== '' && redirectURIProjects !== '/' && !redirectURIProjects.includes('login') && !redirectURIProjects.includes('select-lessor') && redirectURIProjects.includes('projects.sparta') && !redirectURIProjects.includes('signup') && !redirectURIProjects.includes('termscondition') && !redirectURIProjects.includes('data-policy')) {
                  window.location.assign(redirectURIProjects)
                } else {
                  if (userDetail.user.permission.project_management) {
                    browserHistory.push('/technical/projects');
                  } else if (userDetail.defaultLessor.lessor_type == 4) {} 
                }
                if (!userDetail) {
                  if (!location.includes('lgoin') && !location.includes('select-lessor')) {
                    setGlobalCookie('redirectURIProjects', window.location.href, 3)
                  }
                  window.location.reload();
                }
                setTimeout(() => trackLogin(userDetail, () => {
                  window.location.reload()
                }), 500)
              }
            })
      }

    const userInfo = getLocalStorageInfo();
   const lessors = userInfo.lessor_list.map(item => item.lessor_type && item.lessor_type.value == 3 && item.owner !== undefined && item.owner !== null ? {
      ...item,
      name: item.owner ? item.owner.name : ''
    } : item)
   
    const onChangeLessor = (lessor) => {console.log(lessor?.id); userAuthorization(lessor?.id)}
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    let is_saas = false
    Object.keys(userInfo.user.permission).map(permission => {
        if (userInfo.user.permission[permission].is_saas == true) {
            is_saas = true
        }
        return permission
    })

    return (
        <>
        <header className='primary-header'>
            <Grid spacing={1} container alignItems='center'>
                <Grid item xs={5} className="flex-centered padding-right-0 text-center">
                    <ul className="list-inline header-logo-search  first-block">
                        <li className="list-inline-item">
                            <Logo />
                        </li>
                        <li className="list-inline-item">
                            <Title />
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={7}>
                    <div className='app-other-nav'>
                        <Hidden smDown={true}>
                            <div className='header-right-item short-icons'>
                                <ul className='list-inline'>
                                    <li className='list-inline-item'>
                                        <Link to='/whats-new'>
                                            <Tooltip title="What's New !" arrow><RecordVoiceOverIcon fontSize='small' color='primary' /></Tooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Tooltip title='Report An Issue' arrow><HeadsetMicIcon onClick={() => setReportAnIssueInfo({ modal: true })} fontSize='small' color='primary' /></Tooltip>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Link to='/faqs'>
                                            <Tooltip title="FAQ's" arrow><ForumIcon fontSize='small' color='primary' /></Tooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Link onClick={() => window.open(`${appConfig.domain.subDomian}notifications`)}>
                                            <Tooltip title='Notifications' arrow>
                                                <Badge style={{ display: 'inline-block' }} badgeContent={fetchData?.notificationCount || 0} color="error" max={99}>
                                                    <NotificationsActiveIcon fontSize='small' color='primary' />
                                                </Badge>
                                            </Tooltip>
                                        </Link>
                                    </li>
                                    {
                                        is_saas && userInfo?.user?.permission?.console?.cart ?
                                            <li className="list-inline-item" style={location?.pathname?.includes('cart') ? { borderBottom: '4px solid #2f8cff' } : {}}>
                                                <img alt="cartIcon" width='20px' onClick={() => window.open(`${appConfig.domain.subDomian}license/cart`, '_blank')} src={cartIcon} style={{ cursor: 'pointer' }} /> {props?.notifications?.card_count ? <span className="cart-count">{props.notifications.card_count}</span> : null}
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                            {lessors?.length > 0 ?
                                <div className='console-management-modal header-right-item'>
                                    {(() => {
                                        let defaultLessor = getLocalStorageInfo()?.defaultLessor;
                                        const selectedLessor = defaultLessor?.id ? { id: defaultLessor.id, name: defaultLessor.name } : null;
                                        return (
                                            <div className='autocomplete-width'>
                                                <Autocomplete
                                                    disableClearable
                                                    filterSelectedOptions={true}
                                                    options={lessors}
                                                    getOptionLabel={option => option.name}
                                                    id="lessor"
                                                    value={selectedLessor}
                                                    onChange={(e, value) => onChangeLessor(value)}
                                                    renderInput={params => <TextField  {...params} margin="none" InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                />
                                            </div>

                                        )
                                    })()}
                                </div> : getLocalStorageInfo()?.defaultLessor?.name ?
                                    getLocalStorageInfo()?.defaultLessor?.name
                                    : '--'
                            }
                        </Hidden>
                        <div className='header-right-item'>
                            <UserInfo />
                        </div>

                    </div>
                </Grid>
            </Grid>
            {reportAnIssueInfo?.modal ?
                <ReportAnIssue
                setLoading={setLoading}
                    reportAnIssueInfo={reportAnIssueInfo}
                    toggleModalFn={() => setReportAnIssueInfo({ modal: false })}
                /> : null
            }
        </header>
        {loading ? <PageLoader /> :null}
        </>
    )
}

export default Header