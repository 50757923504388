import mixpanel from 'mixpanel-browser';
import { getLocalStorageInfo } from './index';
import config from '../config';
mixpanel.init(
    config.mixpanel.key, {
    debug: true,
    loaded: function (mixpanel) {
        console.log('mixpanel', mixpanel)
    }
},
);

export const trackLogin = (user, callback) => {
    trackUser(user, () => mixpanel.track('Project Management', {
        page_title: 'Selected Lessor',
        event_type: 'Logged In',
        lessor_name: user.defaultLessor.name,
        source: user.sso ? 'SSO' : 'Application'
    }, callback()))
}

export const trackLogOut = (user) => {
    trackUser(user, () => {
        mixpanel.track('Project Management', {
            page_title: 'Log Out',
            event_type: 'Logged Out'
        })
    })
}

export const trackUser = (user, callback) => {
    if (user !== undefined && user !== null) {
        mixpanel.people.set({
            name: user.user.name,
            user_id: user.user.id,
            lessor_name: user.defaultLessor && user.defaultLessor.name ? user.defaultLessor.name : '',
            lessor_id: user.defaultLessor && user.defaultLessor.id ? user.defaultLessor.id : '',
            user_type: user.user.type.label
        });
        mixpanel.identify(user.user.id)
        callback()
    }
}

export const trackActivity = (activityName, metadata) => {
    trackUser(getLocalStorageInfo(), () => {
        mixpanel.track(
            activityName,
            {
                ...metadata,
                application_module: 'Projects',
                lessor_name: getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.name ? getLocalStorageInfo().defaultLessor.name : '',
                name: getLocalStorageInfo().user.name,
                user_type: getLocalStorageInfo().user.type.label
            }
        )
    })
}
