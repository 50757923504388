import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import { helperFunc } from '../helperFunc'

const FilePreview = ({ fileUrl,disabledField }) => {
    return (
        <div>  <div className=' attachment-text-div'>
            <div className='truncate attachment-link'  >
                {helperFunc.getAttachmentName(fileUrl)}
            </div>
            {disabledField ? null : <span className='cursor-pointer' onClick={() => helperFunc.downloadFile(fileUrl)}>
               <GetAppIcon color="primary" fontSize='small' />
           </span> }
           
        </div></div>
    )
}

export default FilePreview