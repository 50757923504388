import EngineInformation from "./EngineInformation";
import TechnicalRecords from "./TechnicalRecords";
import EngineCondition from "./EngineCondition";
import EngineStand from "./EngineStand";
import PowerPlant from "./PowerPlant";
import FleetComposition from "./FleetComposition";
import TechnicalSummary from "./TechnicalSummary";
export {
    EngineInformation,
    TechnicalRecords,
    EngineCondition,
    EngineStand,
    PowerPlant,
    FleetComposition,
    TechnicalSummary
}

export const gtalable_dropdownField =
    [{ lable: "aware that SAEL's engine was leased.", value: 'is_aware', dropdown: ['were', 'were not'] },
    { lable: "familiar with the current condition of the engine.", value: "is_corrent_condition", dropdown: ['were', 'were not'] },
    { lable: "PMA parts in the gaspath.", value: "is_pma", dropdown: ['uses','does not use'] },
    { lable: "DER repairs.", value: "is_der", dropdown:['uses','does not use']},
    { lable: "familiar with the shop visit provisions in the lease.", value: "is_shop_visit", dropdown: ['were', 'were not'] },
    { lable: "familiar with the redelivery conditions in the lease.", value: "is_redelivery", dropdown: ['were', 'were not'] },
    { lable: "familiar with replacement parts clause in the lease.", value: "is_replacement", dropdown: ['were', 'were not'] },
    { lable: "aware that a QEC was delivered with the engine.", value: "is_qec", dropdown: ['were', 'were not'] },
    { lable: "aware that a transportation stand was delivered.", value: "is_transportation", dropdown: ['were', 'were not'] },
    ]


