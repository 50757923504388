import React, { useState } from 'react';
import { Table, TableBody, TableHead, Paper, Grid, TextField, Button, IconButton, TableRow, TableCell } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import { backendDateFormat, displayDateFormatShort } from '../../../constants';
import { globalDeleteService } from '../../../globalServices';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { CkEditorText } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { DeletePopUp } from '../../../shared_elements';
import { trackActivity } from '../../../utils/mixpanel';

function EngineCondition({ data, setData, onFieldChange, callthisapi, getResponseBack, currentFormDetail, setInitialValue, setLoading, isLoading }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState({ modal: false, data: null })

    const svNextDis = data?.engine_condition_stands ? data?.engine_condition_stands?.next_sv_date?.filter(item => item?.id)?.length === data?.engine_condition_stands?.next_sv_date?.length : []
    const fh_fc_ratio = Number(data?.engine_condition_stands?.avg_fh / data?.engine_condition_stands?.avg_fc)
    const interval_fhsCal = Number(data?.engine_condition_stands?.expected_fh_interval?.thrust_is - data?.engine_condition_stands?.tsl_hsv)

    const calculateAchieveFHS = () => {
        const trendingAt = Number(data?.engine_condition_stands?.expected_fh_interval?.trending_at);
        const averageOf = Number(data?.engine_condition_stands?.expected_fh_interval?.average_of);

        if (trendingAt == null || averageOf == null ||
            isNaN(trendingAt) || isNaN(averageOf) ||
            averageOf === 0) {
            return '--';
        }
        const result = (trendingAt / averageOf) * 1000;
        if (isNaN(result) || !isFinite(result)) {
            return '--';
        }
        if (result === 0) {
            return '--';
        }
        const rounded = Math.round(result * 100) / 100;
        if (rounded % 1 === 0) {
            return rounded?.toString() || '--';
        }
        return rounded?.toFixed(2) || '--';

    };

    const svNextCall = (modeTye, row) => {
        if (modeTye === 'add') {
            setData(prev => (
                {
                    ...prev,
                    engine_condition_stands: {
                        ...prev?.engine_condition_stands,
                        next_sv_date: [
                            ...prev?.engine_condition_stands?.next_sv_date,
                            { sv_type: null, date: null, tsn: null, csn: null, llp_limiter: null, egtm: null }]
                    }
                }

            ));

        } else if (row?.id) {
            setLoading(true)
            globalDeleteService(`/technical/t015/basic-inspection/${row?.id}/delete/?module=sv_date`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        setDeleteModal({ modal: false, data: null })
                        setLoading(false)
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Estimated next shop visit date',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    } else {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null })
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Estimated next shop visit date',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    }
                });
        } else {
            setData(prev => (
                {
                    ...(prev),
                    engine_condition_stands: {
                        ...prev.engine_condition_stands,
                        next_sv_date:
                            [...data?.engine_condition_stands.next_sv_date]?.filter(item => item?.id)
                    }
                }

            ));
        }

    }
    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5>Engine Condition Assessment :</h5>
                <Grid container spacing={1}>
                    <Grid item>
                        <span>The engine was</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['not installed', 'installed in position 1', 'installed in position 2']}
                            getOptionLabel={option => option}
                            id="installed_on_wing"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '220px' }}
                            value={['not installed', 'installed in position 1', 'installed in position 2'].filter(item => item === data?.engine_condition_stands?.condition_assessment?.installed_on_wing)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'installed_on_wing', value, 'engine_condition_stands', '', '', 'condition_assessment')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                        />
                        <span className='span-style'>on-wing at the time of the inspection.The engine was installed on Aircraft Reg</span>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.engine_condition_stands?.aircraft_reg || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                            style={{ verticalAlign: 'middle', marginLeft: '5px', width: '140px' }}
                        />
                        <span className='span-style'>/ having MSN</span>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.engine_condition_stands?.having_msn || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                            style={{ verticalAlign: 'middle', marginLeft: '5px', width: '140px' }}
                        />
                        <span className='span-style'>and is in</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['serviceable', 'unserviceable']}
                            style={{ display: 'inline-grid', width: '150px', marginLeft: '5px', }}
                            getOptionLabel={option => option}
                            id="condition_assessment"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={['serviceable', 'unserviceable'].filter(item => item === data?.engine_condition_stands?.condition_assessment?.condition_assessment)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'condition_assessment', value, 'engine_condition_stands', '', '', 'condition_assessment')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' />}
                        />
                        <span className='span-style'>condition.</span>
                    </Grid>
                </Grid>
                <div style={{ marginTop: '10px' }}>
                    <CkEditorText
                        placeholder='If not installed, provide preservation type & storage location, otherwise Enter.. "N/A" '
                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                        htmlData={data?.engine_condition_stands?.condition_assessment?.install_note || ''}
                        onBlurData={() => callthisapi('', data?.engine_condition_stands?.condition_assessment?.install_note)}
                        onFocusData={(e, value) => setInitialValue(e, value)}
                        onChangeData={(value) => setData({
                            ...data,
                            engine_condition_stands: {
                                ...data?.engine_condition_stands,
                                condition_assessment: {
                                    ...data?.engine_condition_stands?.condition_assessment,
                                    install_note: value
                                }
                            }
                        })}
                    />
                </div>
                <Table className='mui-table-format-t015'>
                    <TableBody>
                        <TableRow>
                            <TableCell><h6>Average Daily Flight Hours</h6></TableCell>
                            <TableCell >
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='avg_fh'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    value={data?.engine_condition_stands?.avg_fh || ''}
                                    placeholder="Enter.."
                                    fullWidth
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'avg_fh', e.target.value ? e.target.value : null, 'engine_condition_stands') : e.preventDefault() }}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </TableCell>
                            <TableCell><h6>Average Daily Flight Cycles</h6> </TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='avg_fc'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    value={data?.engine_condition_stands?.avg_fc || ''}
                                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'avg_fc', e.target.value ? e.target.value : null, 'engine_condition_stands') : e.preventDefault() }}
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 5 }}
                                />
                            </TableCell>
                            <TableCell><h6>FH: FC Ratio</h6> </TableCell>
                            <TableCell>
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    id='fh_fc_ratio'
                                    value={data?.engine_condition_stands?.fh_fc_ratio ? data?.engine_condition_stands?.fh_fc_ratio : fh_fc_ratio === Infinity || isNaN(fh_fc_ratio) || fh_fc_ratio === 0 || fh_fc_ratio === "0" ? '--' : fh_fc_ratio.toFixed(2) || '--'}
                                    placeholder="Enter.."
                                    fullWidth
                                    disabled
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>

                            <TableCell><h6>TSL HSV</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='tsl_hsv'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    value={data?.engine_condition_stands?.tsl_hsv || ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsl_hsv', e.target.value ? e.target.value : null, 'engine_condition_stands') : e.preventDefault() }}
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                />
                            </TableCell>
                            <TableCell><h6>CSL HSV</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='csl_hsv'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    value={data?.engine_condition_stands?.csl_hsv || ''}
                                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csl_hsv', e.target.value ? e.target.value : null, 'engine_condition_stands') : e.preventDefault() }}
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                />
                            </TableCell>
                            <TableCell><h6>Average Derate %</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='avg_derate_percentage'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    value={data?.engine_condition_stands?.avg_derate_percentage || ''}
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: !regexConstants.numberWithDot.test(data?.engine_condition_stands?.avg_derate_percentage) && 3 }}
                                    onChange={(e) => { // source chatgpt
                                        const value = e.target.value;
                                        const numericValue = value?.replace(/[^\d.]/g, '');
                                        const dotCount = (numericValue.match(/\./g) || [])?.length;
                                        let isValid = false;
                                        if (dotCount > 1) {
                                            isValid = false;
                                        } else if (numericValue === '') {
                                            isValid = true;
                                        } else if (dotCount === 1) {
                                            const [integerPart, fractionalPart = ''] = numericValue?.split('.');
                                            const integerNum = integerPart === '' ? 0 : parseInt(integerPart, 10);
                                            const num = parseFloat(numericValue);
                                            isValid = (
                                                integerPart?.length <= 3 &&
                                                integerNum <= 100 &&
                                                fractionalPart?.length <= 2 &&
                                                num <= 100 &&
                                                regexConstants.numberWithDot.test(numericValue)
                                            );
                                        } else {
                                            const integerNum = parseInt(numericValue, 10);
                                            isValid = (
                                                numericValue.length <= 3 &&
                                                integerNum <= 100 &&
                                                regexConstants.numberWithDot.test(numericValue)
                                            );
                                        }
                                        if (isValid || value === '') {
                                            onFieldChange(e, 'avg_derate_percentage', value ? numericValue : null, 'engine_condition_stands');
                                        }
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table className='mui-table-format-t015' style={{ marginTop: '10px' }} >
                    <TableBody >
                        <TableRow>
                            <TableCell><h6>Last SV Date</h6></TableCell>
                            <TableCell width='200'>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        margin="normal"
                                        id="last_sv_date"
                                        format={displayDateFormatShort}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        value={data?.engine_condition_stands?.last_sv_date ? data?.engine_condition_stands?.last_sv_date : null}
                                        onChange={(date) => onFieldChange('', 'last_sv_date', date ? moment(date).format(backendDateFormat) : null, 'engine_condition_stands', '', 'date')}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell><h6>Last SV Reason</h6></TableCell>
                            <TableCell width='700'>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='last_sv_reason'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    value={data?.engine_condition_stands?.last_sv_reason ? data?.engine_condition_stands?.last_sv_reason : null}
                                    onChange={(e) => onFieldChange(e, 'last_sv_reason', e.target.value, 'engine_condition_stands')}
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 250 }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <h5 style={{ margin: "10px 0px" }}>Expected Flight Hour Interval : </h5>
                <Grid container spacing={1}>
                    <Grid item>
                        <TextField
                            variant='outlined'
                            id='operator_id'
                            placeholder="Enter.."
                            disabled
                            value={data?.operator_id || ''}
                            style={{ verticalAlign: 'middle', width: '140px' }}
                        />
                        <span className='span-style'> HSV interval for</span>
                        <TextField
                            variant='outlined'
                            id='current_tr'
                            placeholder="Enter.."
                            disabled
                            value={data?.current_tr || ''}
                            style={{ verticalAlign: 'middle', width: '120px' }}
                        />
                        <span className='span-style'>thrust is </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='thrust_is'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fh_interval?.thrust_is || ''}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'thrust_is', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fh_interval') : e.preventDefault() }}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle', width: '120px' }}
                            inputProps={{ maxLength: 10 }}
                        />
                        <span className='span-style'>FHs.The remaining heavy shop visit interval is</span>
                        <TextField
                            variant='outlined'
                            id='interval_fhs'
                            value={data?.engine_condition_stands?.expected_fh_interval?.interval_fhs >= 0 ? data?.engine_condition_stands?.expected_fh_interval?.interval_fhs : interval_fhsCal > 0 ? Math.round(interval_fhsCal) : '--'}
                            placeholder="Enter..."
                            size="small"
                            disabled
                            style={{ verticalAlign: 'middle', width: '120px' }}
                            className='span-style'
                        />
                        <span>FHs.</span>
                    </Grid>
                    <Grid item>
                        <span>EGT Margin at date of inspection is trending at</span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='trending_at'
                            className='span-style'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fh_interval?.trending_at >= 0 ? data?.engine_condition_stands?.expected_fh_interval?.trending_at : ''}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'trending_at', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fh_interval') : e.preventDefault() }}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle', width: '120px' }}
                            inputProps={{ maxLength: 10 }}
                        />
                        <span>°C. Based on an average of </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='average_of'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fh_interval?.average_of >= 0 ? data?.engine_condition_stands?.expected_fh_interval?.average_of : ''}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'average_of', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fh_interval') : e.preventDefault() }}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle', width: '120px' }}
                            inputProps={{ maxLength: 10 }}
                        />
                        <span className='span-style'> °C of margin lost per 1,000 cycles flown.It is expected that the Engine</span>
                        <span> may potentially achieve </span>
                        <TextField
                            variant='outlined'
                            id='achieve_fhs'
                            disabled
                            placeholder="Enter..."
                            value={data?.engine_condition_stands?.expected_fh_interval?.achieve_fhs ?? calculateAchieveFHS()}
                            size="small"
                            style={{ verticalAlign: 'middle', width: '120px' }}
                            inputProps={{ maxLength: 10 }}
                        />
                        <span> FHs on-wing until EGT margin limitation.</span>
                    </Grid>
                </Grid>
                <h5 style={{ margin: "10px 0px" }}>Expected Flight Cycle Interval :</h5>
                <Grid container spacing={1}>
                    <Grid item>
                        <span style={{ marginRight: '5px' }}>Engine has got</span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='engine_fcs'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fc_interval?.engine_fcs || ''}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'engine_fcs', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fc_interval') : e.preventDefault() }}
                            placeholder="Enter..."
                            size="small"
                            style={{ width: '80px' }}
                            inputProps={{ maxLength: 5 }}
                        />
                        <span className='span-style'>FCs remaining at </span>
                        <TextField
                            variant='outlined'
                            id='remaining_at'
                            disabled
                            value={data?.current_tr || ''}
                            placeholder="Enter..."
                            size="small"
                            style={{ width: '120px' }}
                            inputProps={{ maxLength: 5 }}
                        />
                        <span className='span-style'>thrust. The limiting hot section LLP is the</span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='section_llp'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fc_interval?.section_llp || ''}
                            onChange={(e) => onFieldChange(e, 'section_llp', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fc_interval')}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle' }}
                            inputProps={{ maxLength: 100 }}
                        />
                        <span className='span-style'>which has got </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='fcs_remaining'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fc_interval?.fcs_remaining || ''}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'fcs_remaining', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fc_interval') : e.preventDefault() }}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle', width: '80px' }}
                            inputProps={{ maxLength: 5 }}
                        />
                        <span className='span-style'>FCs remaining.</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span>The LLP with the lowest life that may result in a HSV is the </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='hsv'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fc_interval?.hsv || ''}
                            onChange={(e) => onFieldChange(e, 'hsv', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fc_interval')}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle' }}
                            inputProps={{ maxLength: 100 }}
                        />
                        <span className='span-style'>which will require removal in  </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='removal_fcs'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            value={data?.engine_condition_stands?.expected_fc_interval?.removal_fcs || ''}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'removal_fcs', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_fc_interval') : e.preventDefault() }}
                            placeholder="Enter..."
                            size="small"
                            style={{ verticalAlign: 'middle', width: '80px' }}
                            inputProps={{ maxLength: 5 }}
                        />
                        <span className='span-style'>FCs.</span>
                    </Grid>
                </Grid>
                <h5 style={{ margin: '10px 0px 10px' }}>Expected Interval (other) :</h5>
                <Grid item xs={12}>
                    <span>Engine </span>
                    <Autocomplete
                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                        options={['is', 'is not']}
                        getOptionLabel={option => option}
                        style={{ display: 'inline-grid', marginLeft: '5px', width: '110px' }}
                        id="ad_engine"
                        value={['is', 'is not'].filter(item => item === data?.engine_condition_stands?.expected_interval_other?.ad_engine)[0] || null}
                        onFocus={(e) => setInitialValue(e.target.value)}
                        onBlur={(e) => { callthisapi('', e.target.value) }}
                        onChange={(e, value) => onFieldChange(e, 'ad_engine', value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                        renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                    />
                    <span className='span-style'>limited by an Airworthiness Directive which will result in a premature removal.</span>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Table className='mui-table-format-t015' style={{ marginTop: '10px' }} >
                            <TableBody >
                                <TableRow>
                                    <TableCell width='850px'>
                                        <CkEditorText
                                            placeholder='If yes, please provide AD Ref and Details. If no, please enter.. "N/A".'
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            htmlData={data?.engine_condition_stands?.expected_interval_other?.ad_note || ''}
                                            onBlurData={() => callthisapi('', data?.engine_condition_stands?.expected_interval_other?.ad_note)}
                                            onFocusData={(e, value) => setInitialValue(e, value)}
                                            onChangeData={(value) => setData({
                                                ...data,
                                                engine_condition_stands: {
                                                    ...data?.engine_condition_stands,
                                                    expected_interval_other: {
                                                        ...data?.engine_condition_stands?.expected_interval_other,
                                                        ad_note: value
                                                    }
                                                }
                                            })}
                                        />
                                    </TableCell>
                                    <TableCell width='100'>
                                        <Autocomplete
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            options={['FH Limit', 'FC Limit', 'Calender', 'N/A']}
                                            getOptionLabel={option => option}
                                            style={{ display: 'inline-grid', width: '140px' }}
                                            id="ad_type"
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            value={['FH Limit', 'FC Limit', 'Calender', 'N/A'].filter(item => item === data?.engine_condition_stands?.expected_interval_other?.ad_type)[0] || null}
                                            onChange={(e, value) => onFieldChange(e, 'ad_type', value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                        />
                                    </TableCell>
                                    <TableCell width='150'>
                                        <TextField
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            variant='outlined'
                                            margin='normal'
                                            id='ad_value'
                                            placeholder='Enter'
                                            inputProps={{ maxLength: 10 }}
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            value={data?.engine_condition_stands?.expected_interval_other?.ad_value || ''}
                                            onChange={(e) => onFieldChange(e, 'ad_value', e.target.value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <span>Engine </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['is', 'is not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '110px' }}
                            id="bsi_engine"
                            value={['is', 'is not'].filter(item => item === data?.engine_condition_stands?.expected_interval_other?.bsi_engine)[0] || null}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            onChange={(e, value) => onFieldChange(e, 'bsi_engine', value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>limited by reduced interval inspections as a result of Borescope findings.    </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className='mui-table-format-t015' style={{ marginTop: '10px' }} >
                            <TableBody >
                                <TableRow>
                                    <TableCell width='850px'>
                                        <CkEditorText
                                            placeholder='If yes, please provide BSI task and findings. If no, please enter.. "N/A".'
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            htmlData={data?.engine_condition_stands?.expected_interval_other?.bsi_note || ''}
                                            onBlurData={() => callthisapi('', data?.engine_condition_stands?.expected_interval_other?.bsi_note)}
                                            onFocusData={(e, value) => setInitialValue(e, value)}
                                            onChangeData={(value) => setData({
                                                ...data,
                                                engine_condition_stands: {
                                                    ...data?.engine_condition_stands,
                                                    expected_interval_other: {
                                                        ...data?.engine_condition_stands?.expected_interval_other,
                                                        bsi_note: value
                                                    }
                                                }
                                            })}
                                        />
                                    </TableCell>
                                    <TableCell width='100px'>
                                        <Autocomplete
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            options={['FH Limit', 'FC Limit', 'Calender', 'N/A']}
                                            getOptionLabel={option => option}
                                            className='engine-stand-select'
                                            style={{ display: 'inline-grid', width: '140px' }}
                                            id="bsi_type"
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            value={['FH Limit', 'FC Limit', 'Calender', 'N/A'].filter(item => item === data?.engine_condition_stands?.expected_interval_other?.bsi_type)[0] || null}
                                            onChange={(e, value) => onFieldChange(e, 'bsi_type', value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                        />
                                    </TableCell>
                                    <TableCell width='150px'>
                                        <TextField
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            variant='outlined'
                                            margin='normal'
                                            id='bsi_value'
                                            placeholder='Enter'
                                            inputProps={{ maxLength: 10 }}
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            value={data?.engine_condition_stands?.expected_interval_other?.bsi_value || ''}
                                            onChange={(e) => onFieldChange(e, 'bsi_value', e.target.value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: '10px' }} >
                        <span>Engine </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['is', 'is not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '110px' }}
                            id="other_engine"
                            value={['is', 'is not'].filter(item => item === data?.engine_condition_stands?.expected_interval_other?.other_engine)[0] || null}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            onChange={(e, value) => onFieldChange(e, 'other_engine', value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>limited by another reason which may cause premature removal.    </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className='mui-table-format-t015' style={{ marginTop: '10px' }} >
                            <TableBody >
                                <TableRow>
                                    <TableCell width='850px'>
                                        <CkEditorText
                                            placeholder='If yes, please provide information. If no, please enter.. "N/A".'
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            htmlData={data?.engine_condition_stands?.expected_interval_other?.other_note || ''}
                                            onBlurData={() => callthisapi('', data?.engine_condition_stands?.expected_interval_other?.other_note)}
                                            onFocusData={(e, value) => setInitialValue(e, value)}
                                            onChangeData={(value) => setData({
                                                ...data,
                                                engine_condition_stands: {
                                                    ...data?.engine_condition_stands,
                                                    expected_interval_other: {
                                                        ...data?.engine_condition_stands?.expected_interval_other,
                                                        other_note: value
                                                    }
                                                }
                                            })}
                                        />
                                    </TableCell>
                                    <TableCell width='100px'>
                                        <Autocomplete
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            options={['FH Limit', 'FC Limit', 'Calender', 'N/A']}
                                            getOptionLabel={option => option}
                                            className='engine-stand-select'
                                            style={{ display: 'inline-grid', width: '140px' }}
                                            id="other_type"
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            value={['FH Limit', 'FC Limit', 'Calender', 'N/A'].filter(item => item === data?.engine_condition_stands?.expected_interval_other?.other_type)[0] || null}
                                            onChange={(e, value) => onFieldChange(e, 'other_type', value, 'engine_condition_stands', '', '', 'expected_interval_other')}
                                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                        />
                                    </TableCell>
                                    <TableCell width='150px'>
                                        <TextField
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            variant='outlined'
                                            margin='normal'
                                            id='other_value'
                                            placeholder='Enter'
                                            inputProps={{ maxLength: 10 }}
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            value={data?.engine_condition_stands?.expected_interval_other?.other_value || ''}
                                            onChange={(e) => onFieldChange(e, 'other_value', e.target.value ? e.target.value : null, 'engine_condition_stands', '', '', 'expected_interval_other')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h5 style={{ margin: '10px 0px 5px' }}>Estimated Next Shop Visit Date : </h5>
                    </li>
                    <li className="list-inline-item" style={{ float: 'right' }}>
                        <Button disabled={!onCheckFormPermission(currentFormDetail) ? true : svNextDis ? false : true} onClick={() => svNextCall("add")} color="primary" variant="contained" size="small" style={{ margin: '5px' }} >+ ADD</Button>
                    </li>
                </ul>
                <Table className='mui-table-format-t015'>
                    <TableHead className='table-align-head'>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell><h6>Date</h6> </TableCell>
                            <TableCell><h6>TSN</h6> </TableCell>
                            <TableCell><h6>CSN</h6> </TableCell>
                            <TableCell><h6>LLP Limit</h6> </TableCell>
                            <TableCell><h6>EGTM</h6> </TableCell>
                            <TableCell><h6>Action</h6> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            [data?.engine_condition_stands?.next_hsv_due_to_fh]?.map((item, index) => (
                                <TableRow>
                                    <TableCell style={{ textAlignLast: 'center' }}> Next HSV due to FH </TableCell>
                                    <TableCell style={{ textAlignLast: 'center' }}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date"
                                                format={displayDateFormatShort}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                value={item?.date || null}
                                                disabled
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell style={{ textAlignLast: 'center' }}>
                                        <TextField value={item?.tsn ?? ''} disabled variant='outlined' fullWidth placeholder="Enter.." id='tsn' />
                                    </TableCell>
                                    <TableCell style={{ textAlignLast: 'center' }}>
                                        <TextField value={item?.csn ?? ''} disabled variant='outlined' fullWidth placeholder="Enter.." id='csn' />
                                    </TableCell>
                                    <TableCell style={{ textAlignLast: 'center' }}>
                                        <TextField
                                            value={item?.llp_limiter >= 0 ? item?.llp_limiter ?? '' : ''}
                                            disabled
                                            variant='outlined'
                                            fullWidth
                                            placeholder="Enter.."
                                            id='llp_limiter'
                                        />
                                    </TableCell>
                                    <TableCell style={{ textAlignLast: 'center' }}>
                                        <TextField
                                            value={item?.egtm ?? ''}
                                            variant='outlined'
                                            fullWidth
                                            placeholder="Enter.."
                                            id='egtm'
                                            disabled
                                        />
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        {[data?.engine_condition_stands?.next_hsv_due_to_fc]?.map((item, index) => (
                            <TableRow>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    Next HSV due to FC
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date"
                                            format={displayDateFormatShort}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={item?.date || null}
                                            disabled
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField value={item?.tsn ?? ''} disabled variant='outlined' fullWidth placeholder="Enter.." id='tsn' />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField value={item?.csn ?? ''} disabled variant='outlined' fullWidth placeholder="Enter.." id='csn' />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        value={item?.llp_limiter ?? ''}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Enter.."
                                        id='llp_limiter'
                                        disabled
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField value={item?.egtm >= 0 ? item?.egtm ?? '' : ''} disabled variant='outlined' fullWidth placeholder="Enter.." id='egtm' />
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        ))
                        }
                        {data?.engine_condition_stands?.next_sv_date?.map((item, index) => (
                            <TableRow>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Next HSV due to (Other)"
                                        id='date'
                                        value={item?.sv_type}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        onChange={(e) => onFieldChange(e, 'sv_type', e.target.value ? e.target.value : null, 'engine_condition_stands', index, '', 'next_sv_date')}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            margin="normal"
                                            id="date"
                                            format={displayDateFormatShort}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={item?.date || null}
                                            onChange={(date) => onFieldChange('', 'date', date ? moment(date).format(backendDateFormat) : null, 'engine_condition_stands', index, 'date', 'next_sv_date')}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Enter.."
                                        id='tsn'
                                        value={item?.tsn}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value ? e.target.value : null, 'engine_condition_stands', index, '', 'next_sv_date') : e.preventDefault() }}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Enter.."
                                        id='csn'
                                        value={item?.csn}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value ? e.target.value : null, 'engine_condition_stands', index, '', 'next_sv_date') : e.preventDefault() }}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Enter.."
                                        id='llp_limiter'
                                        value={item?.llp_limiter}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        onChange={(e) => onFieldChange(e, 'llp_limiter', e.target.value ? e.target.value : null, 'engine_condition_stands', index, '', 'next_sv_date')}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Enter.."
                                        id='egtm'
                                        value={item?.egtm}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        onChange={(e, value) => onFieldChange(e, 'egtm', e.target.value ? e.target.value : null, 'engine_condition_stands', index, '', 'next_sv_date')}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton disabled={!onCheckFormPermission(currentFormDetail)} color='secondary' >
                                        <DeleteOutlinedIcon onClick={() => { item?.id ? setDeleteModal({ modal: true, data: item }) : svNextCall() }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </Paper>
            {deleteModal?.modal ?
                <DeletePopUp
                    modal={deleteModal.modal}
                    toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
                    title="Estimated Next Shop Visit Date"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => svNextCall('', deleteModal?.data)}
                /> : null
            }
        </section>
    );
}

export default EngineCondition