import React, { useState } from 'react';
import { Table, TableBody, TableHead, Paper, TextField, TableRow, TableCell,IconButton, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router'
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { gtalable_dropdownField } from '.';
import moment from 'moment';
import { regexConstants } from '../../../constants/regEx';
import MomentUtils from '@date-io/moment';
import { globalDeleteService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { backendDateFormat, displayDateFormatShort } from '../../../constants';
import { GoogleLocationField } from '../../Elements';
import { trackActivity } from '../../../utils/mixpanel';
import { DeletePopUp } from '../../../shared_elements';


function EngineInformation({ data, onFieldChange, callthisapi, basicDropdown, setData, getResponseBack, usersList, currentFormDetail, setInitialValue, setLoading, isLoading }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState({ modal: false, data: null })
    const [errors, setErrors] = useState({});
    const keyAttendDisable = data?.key_attendees ? data?.key_attendees?.filter(item => item?.id)?.length === data?.key_attendees?.length : []

    const keyAttendee = (modeTye, row) => {
        if (modeTye === 'add') {
            setData(prev => ({
                ...prev,
                key_attendees: [...(prev.key_attendees || []), { name: null, email: null, position: null }]
            }));
        } else if (row?.id) {
            setLoading(true)
            globalDeleteService(`/technical/t015/basic-inspection/${row?.id}/delete/?module=key_attendees`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Key Attendee Delete',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    } else {
                        getResponseBack()
                        setDeleteModal({ modal: false, data: null })
                        setLoading(false)
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Key Attendee Delete',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    }
                });
        } else {
            setData(prev => ({ ...(prev), key_attendees: [...data?.key_attendees]?.filter(item => item?.id) }));
        }
    }


    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5> Engine Information : </h5>
                <Table className='mui-table-format-t015' >
                    <TableBody>
                        <TableRow>
                            <TableCell><h6>ESN</h6></TableCell>
                            <TableCell >
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    id='serial_number'
                                    placeholder="Enter.."
                                    fullWidth
                                    disabled
                                    value={currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.msn : currentFormDetail?.asset?.esn}
                                />
                            </TableCell>
                            <TableCell><h6>Lessee</h6> </TableCell>
                            <TableCell>
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={basicDropdown?.lessee || []}
                                    getOptionLabel={option => option}
                                    id="lessee_id"
                                    value={data?.lessee_id || null}
                                    onChange={(e, value) => onFieldChange(e, 'lessee_id', value)}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell><h6>Operator</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='operator_id'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={data?.operator_id || ''}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'operator_id', e.target.value) }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6>Model</h6></TableCell>
                            <TableCell>
                                <TextField
                                    variant='outlined'
                                    placeholder='Select...'
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    id="model_id"
                                    value={currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.aircraft_type : currentFormDetail?.asset?.engine_type}
                                />
                            </TableCell>
                            <TableCell><h6>Leased TR</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    id='leased_tr'
                                    margin='normal'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    value={data?.leased_tr || ''}
                                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'leased_tr', e.target.value ? e.target.value : null) : e.preventDefault() }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                            <TableCell><h6>Current TR</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    margin='normal'
                                    id='current_tr'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    value={data?.current_tr || ''}
                                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'current_tr', e.target.value ? e.target.value : null) : e.preventDefault() }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6>Stand</h6></TableCell>
                            <TableCell>
                                    <Autocomplete
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        options={['YES', 'NO', 'N/A']}
                                        getOptionLabel={option => option}
                                        id="is_on_stand"
                                        value={['YES', 'NO', 'N/A'].filter(item => item === data?.is_on_stand)[0] || null}
                                        onChange={(e, value) => onFieldChange(e, 'is_on_stand', value)}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                    />
                            </TableCell>
                            <TableCell><h6>QEC Kit</h6></TableCell>
                            <TableCell>
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: false, name: "NO" }, { id: true, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="has_qec_kit"
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.has_qec_kit))[0] || ''}
                                    onChange={(e, value) => onFieldChange(e, 'has_qec_kit', value?.id)}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5>Inspection General Information :</h5>
                <Table className='mui-table-format-t015' >
                    <TableBody >
                        <TableRow>
                            <TableCell><h6>SAEL Inspector</h6></TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={usersList || []}
                                    getOptionLabel={option => option?.name}
                                    id="sael_inspector_id"
                                    value={data?.sael_inspector_id || null}
                                    onChange={(e, value) => onFieldChange(e, 'sael_inspector_id', value)}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell><h6>Inspection Location</h6></TableCell>
                            <TableCell>
                                <GoogleLocationField
                                    locationdisabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    location={data?.inspection_location ? data?.inspection_location : ''}
                                    onFieldChange={onFieldChange}
                                    callFormApi={() => callthisapi('', data?.inspection_location)}
                                    formkey='inspection_location'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6>Inspection Date</h6></TableCell>
                            <TableCell>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        margin="normal"
                                        id="inspection_date"
                                        format={displayDateFormatShort}
                                        fullWidth
                                        // disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        value={data?.inspection_date ? data?.inspection_date : null}
                                        onChange={(date) => onFieldChange('', 'inspection_date', date ? moment(date).format(backendDateFormat) : null)}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell><h6>Physical Review</h6></TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: false, name: "NO" }, { id: true, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="has_physical_review"
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.has_physical_review))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'has_physical_review', value?.id)}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h5 style={{ margin: '10px 0px 10px' }}> Key Attendees from Lessee / Operator :</h5>
                    </li>
                    <li className="list-inline-item" style={{ float: 'right' }}>
                        <Button disabled={!onCheckFormPermission(currentFormDetail) ? true : keyAttendDisable ? false : true} color="primary" variant="contained" size="small" style={{ margin: '5px' }} onClick={() => keyAttendee("add")}>+ ADD</Button>
                    </li>
                </ul>
                <Table className='mui-table-format-t015'>
                    <TableHead className='table-align-head'>
                        <TableRow>
                            <TableCell><h6>Name</h6></TableCell>
                            <TableCell><h6>Email</h6></TableCell>
                            <TableCell><h6>Position</h6></TableCell>
                            <TableCell><h6>Action</h6></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.key_attendees?.length > 0 && data?.key_attendees?.map((row, index) => (
                            <TableRow key={row}>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        margin='normal'
                                        id='name'
                                        placeholder="Enter.."
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        value={row?.name || ''}
                                        onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'name', e.target.value, 'key_attendees', index) }}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        margin='normal'
                                        id='email'
                                        inputProps={{ maxLength: 50 }}
                                        placeholder="Enter.."
                                        value={row?.email || ''}
                                        onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'email', e.target.value, 'key_attendees', index) }}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => {
                                            const emailValue = e.target.value.trim();
                                            setErrors(prevErrors => ({
                                                ...prevErrors,
                                                [index]: {
                                                    ...prevErrors[index],
                                                    email: emailValue === '' || regexConstants.email.test(emailValue) ? '' : 'Please enter a valid Email Address'
                                                }
                                            }));
                                            callthisapi('', emailValue);
                                        }}
                                    />
                                    {errors[index]?.email?.trim()?.length ? <span style={{ color: 'red', fontSize: '12px' }}>
                                        {errors[index]?.email}
                                    </span> : null}
                                </TableCell>
                                <TableCell style={{ textAlignLast: 'center' }}>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        margin='normal'
                                        id='position'
                                        inputProps={{ maxLength: 50 }}
                                        placeholder="Enter.."
                                        value={row?.position || ""}
                                        onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'position', e.target.value, 'key_attendees', index) }}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton disabled={!onCheckFormPermission(currentFormDetail)} color='secondary'>
                                        <DeleteOutlinedIcon onClick={() => { row?.id ? setDeleteModal({ modal: true, data: row }) : keyAttendee() }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5> Lease / GTA Conditions & Checklist :</h5>
                <Table className='mui-table-format-t015'>
                    <TableBody>
                        <TableRow>
                            <TableCell><h6 style={{ paddingLeft: "10px" }}>Lease Start Date</h6></TableCell>
                            <TableCell>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            margin="normal"
                                            id="lease_start_date"
                                            format={displayDateFormatShort}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={data?.lease_conditions?.lease_start_date ? data?.lease_conditions?.lease_start_date : null}
                                            onChange={(date) => onFieldChange('', 'lease_start_date', date ? moment(date).format(backendDateFormat) : null, 'lease_conditions', '', 'date')}
                                            inputVariant='outlined'
                                        />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell><h6 style={{ paddingLeft: "10px" }}>Lease End Date</h6></TableCell>
                            <TableCell>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                            margin="normal"
                                            id="lease_end_date"
                                            format={displayDateFormatShort}
                                            fullWidth
                                            // disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={data?.lease_conditions?.lease_end_date ? data?.lease_conditions?.lease_end_date : null}
                                            onFocus={(e) => setInitialValue(e.target.value)}
                                            onBlur={(e) => { callthisapi('', e.target.value) }}
                                            onChange={(date) => onFieldChange('', 'lease_end_date', date ? moment(date).format(backendDateFormat) : null, 'lease_conditions', '', 'date')}
                                            inputVariant='outlined'
                                        />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6 style={{ paddingLeft: "10px" }}>Redelivery Location</h6></TableCell>
                            <TableCell>
                                <GoogleLocationField
                                    locationdisabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    location={data?.lease_conditions?.redelivery_location ? data?.lease_conditions?.redelivery_location : ''}
                                    onFieldChange={onFieldChange}
                                    extraParams='lease_conditions'
                                    callFormApi={() => callthisapi('', data?.lease_conditions?.redelivery_location)}
                                    formkey='redelivery_location'
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                />
                            </TableCell>
                            <TableCell ><h6 style={{ paddingLeft: "10px" }}>Redelivery Checks</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='redelivery_checks'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.redelivery_checks ? data?.lease_conditions?.redelivery_checks : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'redelivery_checks', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6 style={{ paddingLeft: "10px" }}>PMA Parts / DER Repairs Allowed</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='pma_parts_der_repairs_allowed'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.pma_parts_der_repairs_allowed ? data?.lease_conditions?.pma_parts_der_repairs_allowed : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'pma_parts_der_repairs_allowed', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                            <TableCell ><h6 style={{ paddingLeft: "10px" }}>Reserves Payable / Comp Terms</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='reserves_payable_comp_terms'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.reserves_payable_comp_terms ? data?.lease_conditions?.reserves_payable_comp_terms : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'reserves_payable_comp_terms', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6 style={{ paddingLeft: "10px" }}>Definition of Heavy Shop Visit (HSV)</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='definition_of_hsv'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.definition_of_hsv ? data?.lease_conditions?.definition_of_hsv : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'definition_of_hsv', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                            <TableCell ><h6 style={{ paddingLeft: "10px" }}>Redelivery ARC Requirements</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='redelivery_arc_requirements'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.redelivery_arc_requirements ? data?.lease_conditions?.redelivery_arc_requirements : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'redelivery_arc_requirements', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell ><h6 style={{ paddingLeft: "10px" }}>Minimum Hourly Return Condition</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='minimum_hourly_return_condition'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.minimum_hourly_return_condition ? data?.lease_conditions?.minimum_hourly_return_condition : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'minimum_hourly_return_condition', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                            <TableCell ><h6 style={{ paddingLeft: "10px" }}>Minimum Cycle Return Condition</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='minimum_cycle_return_condition'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.minimum_cycle_return_condition ? data?.lease_conditions?.minimum_cycle_return_condition : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'minimum_cycle_return_condition', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6 style={{ paddingLeft: "10px" }}>Minimum Return Condition Other</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='lease_conditions'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 250 }}
                                    value={data?.lease_conditions?.minimum_return_condition_other ? data?.lease_conditions?.minimum_return_condition_other : null}
                                    onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'minimum_return_condition_other', e.target.value, 'lease_conditions') }}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table className='mui-table-format-t015' style={{ margin: "15px 0px 5px" }}>
                    <TableBody>
                        {gtalable_dropdownField?.map((item, index) => (
                            <TableRow>
                                <TableCell width='310' >
                                    <TextField
                                        disabled
                                        variant='outlined'
                                        margin='normal'
                                        fullWidth
                                        id='lessee_id'
                                        placeholder="Enter.."
                                        value={data?.lessee_id || ''}
                                    />
                                </TableCell>
                                <TableCell width='190' >
                                    <Autocomplete
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        options={item?.dropdown || []}
                                        getOptionLabel={option => option}
                                        id="lease_gta_condition_para"
                                        value={data?.lease_gta_condition_para && data?.lease_gta_condition_para[item?.value] || null}
                                        onChange={(e, value) => onFieldChange(e, item?.value, value, 'lease_gta_condition_para')}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value) }}
                                        renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                    />
                                </TableCell>
                                <TableCell ><h6 style={{ paddingLeft: "10px" }}>{item?.lable}</h6></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {deleteModal?.modal ?
                <DeletePopUp
                    modal={deleteModal.modal}
                    toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
                    title="Key Attendees from Lessee / Operator"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => keyAttendee('', deleteModal?.data)}
                /> : null
            }
        </section>
    );
}

export default withRouter(EngineInformation)
