import React, { useState } from 'react'
import { Dialog } from '@material-ui/core';
import ApplicationsAccess from '../../components/ApplicationAccess';
import { caretIcon } from '../../assets/img';
import { getLocalStorageInfo } from '../../../utils_v2';

const Title = () => {
  const [switcherModal, setSwitcherModal] = useState(false)
  const userInfo = getLocalStorageInfo()
  const { maintenance, contracts, records, mr_calculator } = userInfo?.user?.permission || {};
  const hasPermissions = maintenance || contracts || records || mr_calculator;
  const toggleModalFn = (status) => setSwitcherModal(status)
  return (
    <div>
      <h5 className={`para-ui ${hasPermissions ? "app-switch-head" : ""}`}
        onClick={hasPermissions ? () => { toggleModalFn(true) } : null}>
        Projects Management
        {hasPermissions && <img width="22" src={caretIcon} alt="Icon" style={{ marginLeft: "2px", verticalAlign: "bottom" }} />}
      </h5>
      {switcherModal ? <Dialog
        open={switcherModal}
        onClose={() => toggleModalFn(false)}
        aria-labelledby="scroll-dialog-title"
        fullScreen={true}
        className='application-swicther-modal'
      ><ApplicationsAccess handleClose={() => toggleModalFn(false)} />
      </Dialog> : null}
    </div>
  )
}

export default Title