import React, { useState } from 'react';
import { Table, TableBody, Paper, Grid, TextField, Button, IconButton, TableRow, TableCell } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useSnackbar } from 'notistack';
import { CkEditorText } from '../../../shared_elements';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { globalDeleteService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
import { DeletePopUp } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';

function PowerPlant({ data, setData, onFieldChange, callthisapi, getResponseBack, currentFormDetail, setInitialValue, setLoading, isLoading }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState({ modal: false, data: null, modeTye: '' })

    const powerPlantDis = data?.powerplant_fleet_tech_summaries ? data?.powerplant_fleet_tech_summaries?.powerplant_management?.filter(item => item?.id)?.length === data?.powerplant_fleet_tech_summaries?.powerplant_management?.length : []
    const powerPlantMain = data?.powerplant_fleet_tech_summaries ? data?.powerplant_fleet_tech_summaries?.powerplant_maintenance?.filter(item => item?.id)?.length === data?.powerplant_fleet_tech_summaries?.powerplant_maintenance?.length : []

    const powerPlantCall = (modeTye, row, module) => {
        if (modeTye === 'add') {
            if (module === 'maintenance') {
                setData(prev => (
                    {
                        ...prev,
                        powerplant_fleet_tech_summaries: {
                            ...prev.powerplant_fleet_tech_summaries,
                            powerplant_maintenance: [
                                ...prev.powerplant_fleet_tech_summaries?.powerplant_maintenance,
                                { description: null, key_values: [{ key: null, value: null }, { key: null, value: null }, { key: null, value: null }] }]
                        }
                    }

                ));
            } else {
                setData(prev => (
                    {
                        ...prev,
                        powerplant_fleet_tech_summaries: {
                            ...prev.powerplant_fleet_tech_summaries,
                            powerplant_management: [
                                ...prev.powerplant_fleet_tech_summaries?.powerplant_management,
                                { management_type: null, entry: null, selection: null }]
                        }
                    }

                ));
            }

        } else if (row?.id) {
            setLoading(true)
            globalDeleteService(`/technical/t015/basic-inspection/${row?.id}/delete/?module=${module}`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null, modeTye: '' })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: `${module === "maintenace" ? 'Maintenance Planning' : 'Powerplant Management'} Delete`,
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    } else {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null, modeTye: '' })
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: `${module === "maintenace" ? 'Maintenance Planning' : 'Powerplant Management'} Delete`,
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    }
                });
        } else {
            if (module === 'maintenace') {
                setData(prev => (
                    {
                        ...(prev),
                        powerplant_fleet_tech_summaries: {
                            ...prev.powerplant_fleet_tech_summaries,
                            powerplant_maintenance:
                                [...data?.powerplant_fleet_tech_summaries.powerplant_maintenance]?.filter(item => item?.id)
                        }
                    }
                ));
            } else {
                setData(prev => (
                    {
                        ...(prev),
                        powerplant_fleet_tech_summaries: {
                            ...prev.powerplant_fleet_tech_summaries,
                            powerplant_management:
                                [...data?.powerplant_fleet_tech_summaries.powerplant_management]?.filter(item => item?.id)
                        }
                    }

                ));
            }
        }

    }

    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5>Powerplant and Technical Services :</h5>
                <h6 style={{ margin: '10px 0px 10px' }}>Maintenance Planning : </h6>
                <Grid container spacing={1}>
                    <Grid item>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                            inputProps={{ maxLength: 50 }}
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "130px" }}
                            id="provided"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.maintenance_planning?.provided)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'provided', value, 'powerplant_fleet_tech_summaries', '', '', 'maintenance_planning')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span> provided the inspector a copy of their AMP / MPD,which </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['is', 'is not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '110px' }}
                            id="customized"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['is', 'is not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.maintenance_planning?.customized)[0] || ''}
                            onChange={(e, value) => onFieldChange(e, 'customized', value, 'powerplant_fleet_tech_summaries', '', '', 'maintenance_planning')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>a customized version for Lessee's fleet.</span>
                    </Grid>
                    <Grid item>
                        <span className='span-style' style={{ margin: "0px 0px" }}>Engine task intervals </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['do', 'do not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "120px" }}
                            id="task_interval"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['do', 'do not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.maintenance_planning?.task_interval)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'task_interval', value, 'powerplant_fleet_tech_summaries', '', '', 'maintenance_planning')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>differ from the generic AMP / MPD .</span>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details. If no, please enter.. "N/A".'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.maintenance_planning?.planning_notes || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.maintenance_planning?.planning_notes)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data?.powerplant_fleet_tech_summaries,
                                        maintenance_planning: {
                                            ...data?.powerplant_fleet_tech_summaries?.maintenance_planning,
                                            planning_notes: value
                                        }
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <li className="list-inline-item">
                        <Button disabled={!onCheckFormPermission(currentFormDetail) ? true : powerPlantMain ? false : true} onClick={() => powerPlantCall("add", '', 'maintenance')} color="primary" variant="contained" size="small" style={{ margin: '5px' }} >+ ADD</Button>
                    </li>
                </ul>
                <Table className='mui-table-format-t015'>
                    <TableBody>
                        {data?.powerplant_fleet_tech_summaries?.powerplant_maintenance?.map((item, index) => (
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        placeholder="Enter.."
                                        fullWidth
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        inputProps={{ maxLength: 50 }}
                                        value={item?.description || ''}
                                        onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'description', e.target.value, 'powerplant_fleet_tech_summaries', index, '', 'powerplant_maintenance') }}
                                    />
                                </TableCell>
                                {item?.key_values?.map((row, nestedIndex) => (
                                    <>
                                        <TableCell>
                                            <Autocomplete
                                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                                options={["FH Limit", "FC Limit", "Calender", "N/A"]}
                                                getOptionLabel={option => option}
                                                id="key"
                                                className='engine-stand-select'
                                                onFocus={(e) => setInitialValue(e.target.value)}
                                                onBlur={(e) => { callthisapi('', e.target.value); }}
                                                value={row?.key || null}
                                                onChange={(e, value) => { onFieldChange(e, 'key', value, 'powerplant_fleet_tech_summaries', nestedIndex, '', 'powerplant_maintenance', index) }}
                                                renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                                variant='outlined'
                                                id='value'
                                                onFocus={(e) => setInitialValue(e.target.value)}
                                                onBlur={(e) => { callthisapi('', e.target.value); }}
                                                inputProps={{ maxLength: 50 }}
                                                onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'value', e.target.value ? e.target.value : null, 'powerplant_fleet_tech_summaries', nestedIndex, '', 'powerplant_maintenance', index) }}
                                                placeholder="Enter.."
                                                fullWidth
                                                value={row?.value || ''}
                                            />
                                        </TableCell>
                                    </>
                                ))
                                }
                                <TableCell align='center'>
                                    <IconButton disabled={!onCheckFormPermission(currentFormDetail)} color='secondary' >
                                        <DeleteOutlinedIcon onClick={() => { item?.id ? setDeleteModal({ modal: true, data: item, modeTye: 'maintenance' }) : powerPlantCall('', '', 'maintenace') }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
                <p style={{ margin: '20px 0px 10px' }}>General comments on Maintenance Planning :</p>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details. If no, please enter.. "N/A".'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.maintenance_comments || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.maintenance_comments)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data.powerplant_fleet_tech_summaries,
                                        maintenance_comments: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>

                <h5 style={{ margin: '20px 0px 10px' }}>Powerplant Management :</h5>
                <Grid container spacing={1}>
                    <Grid item>
                        <span>PIREPs are recorded  </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['on paper', 'electronically']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '160px' }}
                            id="records"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['on paper', 'electronically'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.records)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'records', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>Inspector</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['was', 'was not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '130px' }}
                            id="inspector"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['was', 'was not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.inspector)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'inspector', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'> provided an overview. </span>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['do', 'do not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '130px' }}
                            id="maintain"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['do', 'do not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.maintain)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'maintain', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'> maintain ETOPS configuration for their fleet.  </span>
                    </Grid>
                    <Grid item style={{ margin: '5px 0px 5px' }} >
                        <span> Engine trend condition monitoring is managed by the </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['airline', 'OEM']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '140px' }}
                            id="manage_by"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['airline', 'OEM'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.manage_by)[0] || ''}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'manage_by', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'> Lessee</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '130px' }}
                            id="granted"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.granted)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'granted', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'> granted SAEL access to ECM data through OEM portal.</span>
                    </Grid>
                    <Grid item >
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['do', 'do not']}
                            getOptionLabel={option => option}
                            className='engine-stand-select'
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '120px' }}
                            id="use_derate"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['do', 'do not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.use_derate)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'use_derate', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'> use derate, with a fleet average </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='fleet_average'
                            placeholder="Enter..."
                            size="small"
                            inputProps={{ maxLength: 50 }}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            style={{ display: 'inline-grid', marginRight: '2px', width: '90px' }}
                            value={data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.fleet_average || ''}
                            onChange={(e) => { // source chatgpt
                                const value = e.target.value;
                                const numericValue = value?.replace(/[^\d.]/g, '');
                                const dotCount = (numericValue.match(/\./g) || [])?.length;
                                let isValid = false;
                                if (dotCount > 1) {
                                    isValid = false;
                                } else if (numericValue === '') {
                                    isValid = true;
                                } else if (dotCount === 1) {
                                    const [integerPart, fractionalPart = ''] = numericValue?.split('.');
                                    const integerNum = integerPart === '' ? 0 : parseInt(integerPart, 10);
                                    const num = parseFloat(numericValue);
                                    isValid = (
                                        integerPart?.length <= 3 &&
                                        integerNum <= 100 &&
                                        fractionalPart?.length <= 2 &&
                                        num <= 100 &&
                                        regexConstants.numberWithDot.test(numericValue)
                                    );
                                } else {
                                    const integerNum = parseInt(numericValue, 10);
                                    isValid = (
                                        numericValue.length <= 3 &&
                                        integerNum <= 100 &&
                                        regexConstants.numberWithDot.test(numericValue)
                                    );
                                }
                                if (isValid || value === '') {
                                    onFieldChange(e, 'fleet_average', value ? numericValue : null, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps');
                                }
                            }}
                        />
                        <span className='span-style'>% </span>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '125px' }}
                            id="provided"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.powerplant_pireps?.provided)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'provided', value, 'powerplant_fleet_tech_summaries', '', '', 'powerplant_pireps') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'> provided the inspector an overview of their systems</span>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                            style={{ verticalAlign: 'middle' }}
                        />
                        <span className='span-style'> use the following systems for tracking aircraft maintenance, task task performance, and aircraft / engine records:</span>
                    </Grid>
                </Grid>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <li className="list-inline-item">
                        <Button disabled={!onCheckFormPermission(currentFormDetail) ? true : powerPlantDis ? false : true} onClick={() => powerPlantCall("add", '', "management")} color="primary" variant="contained" size="small" style={{ margin: '5px' }} >+ ADD</Button>
                    </li>
                </ul>
                <Table className='mui-table-format-t015'>
                    <TableBody >
                        {data?.powerplant_fleet_tech_summaries?.powerplant_management?.map((item, index) => (
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        placeholder="Enter.."
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        id='management_type'
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.management_type || ''}
                                        onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'management_type', e.target.value, 'powerplant_fleet_tech_summaries', index, '', 'powerplant_management') }}
                                    />
                                </TableCell>
                                <TableCell >
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        placeholder="Enter.."
                                        fullWidth
                                        id='entry'
                                        inputProps={{ maxLength: 50 }}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.entry || ''}
                                        onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'entry', e.target.value ? e.target.value : null, 'powerplant_fleet_tech_summaries', index, '', 'powerplant_management') }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        options={['viewed', 'not viewed', 'N/A']}
                                        getOptionLabel={option => option}
                                        id="selection"
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.selection || null}
                                        onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'selection', value, 'powerplant_fleet_tech_summaries', index, '', 'powerplant_management') }}
                                        renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton disabled={!onCheckFormPermission(currentFormDetail)} color='secondary' >
                                        <DeleteOutlinedIcon onClick={() => { item?.id ? setDeleteModal({ modal: true, data: item, modeTye: 'management' }) : powerPlantCall('', '', 'management') }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
                <p style={{ margin: '20px 0px 10px' }}>General comments on Powerplant Management :</p>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.powerplant_general_comment || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.powerplant_general_comment)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data.powerplant_fleet_tech_summaries,
                                        powerplant_general_comment: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            {deleteModal?.modal ?
                <DeletePopUp
                    modal={deleteModal.modal}
                    toggleModalFn={() => setDeleteModal({ modal: false, data: null, modeTye: '' })}
                    title={deleteModal?.modeTye === "maintenace" ? 'Maintenance Planning' : "Powerplant Management"}
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => powerPlantCall('', deleteModal?.data, deleteModal?.modeTye)}
                /> : null
            }
        </section>
    );
}
export default PowerPlant