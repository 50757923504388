import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
class EditImageDialog extends Component {
  constructor(props){
    super(props);
  }
  editorRef = React.createRef();

  handleClickButton = () => {
    const editorInstance = this.editorRef.current.getInstance();
    var blob = editorInstance.toDataURL();
    this.props.saveAnnotateImg(blob);
  };
  render(){
    let editorRef = React.createRef();
    const { imgAnnotation, toggleModalFn, title,isLoading } = this.props;
    return(
      <Dialog
        fullScreen
        open={imgAnnotation.modal}
        aria-labelledby="scroll-dialog-title"
        disableEnforceFocus
      >
        <DialogTitle id="scroll-dialog-title">
          {title ? title:'Edit Image'}
        </DialogTitle>
        <DialogContent dividers={true}>
          <div style={{margin:'0 auto'}}>
            <ImageEditor
              ref={this.editorRef}
              includeUI={{
                loadImage: {
                  path: imgAnnotation.data.image,
                  name: 'SampleImage',
                },
                initMenu: 'filter',
                uiSize: {
                  width: '1000px',
                  height: '700px',
                },
                menuBarPosition: 'bottom',
              }}
              cssMaxHeight={500}
              cssMaxWidth={700}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
              }}
              usageStatistics={false}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModalFn} variant="outlined" size='small' color="primary">Cancel</Button>
          <Button variant="contained" color="primary" size='small' onClick={this.handleClickButton}>{isLoading ? <CircularProgress color='white' size={20} /> :  "Save"}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default EditImageDialog;
