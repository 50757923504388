import React, { Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip,FormControlLabel,Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import { AutocompleteCreatable, LabelValueCard, PageLoader } from '../../../shared_elements'
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, getLocalStorageInfo, localTimeFn } from '../../../utils_v2';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { expenseObj, globalCurrency } from '../';
import { budgetAccessGroup, checkBudgetPermission } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const AddEditExpense = ({params, toggleModalFn, mode, modal, id, expenses, project, getResponseBack,existing_categories, approverList, raisedFor}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isDropZoneLoader, setDropZoneLoader] = useState(false)
    const [expenseCrud, setExpense] = useState({...expenseObj, amount_currency:project.currency ? globalCurrency.find(item => item.code === project.currency) :null});
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState({});
    let assets = [];
    for (const workorder of project.work_orders) {
        let wo_asset={
            'id':workorder.asset.id,
            'type':workorder.asset.type,
            'name':workorder.asset.name
        }
//        let name=''
//        if (workorder.asset.type==1){
//            name='A/C :'+workorder.asset.aircraft_type + ' - ' +workorder.asset.msn;
//        }else if (workorder.asset.type==2){
//            name='EN :'+workorder.asset.engine_type + ' - ' +workorder.asset.esn;
//        }else if (workorder.asset.type==3){
//            name='APU: '+workorder.asset.apu_type + ' - ' +workorder.asset.serial_number;
//        }else if (workorder.asset.type==4){
//            name='LG: '+workorder.asset.lg_position + ' - ' +workorder.asset.serial_number;
//        }
//        console.log('name',name)
        assets.push(wo_asset)
    }
    useEffect(() => {
        getCategories();
    }, []);
    const getCategories = () => {
        if(id){
            setCategories(existing_categories);
            let expense = expenses.find(expense => expense.id === id);
            setExpense(expense);
         }
        if (mode !== 'view'){
            setLoading(true);
            globalGetService(`technical/budget/categories/`)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    setCategories(response.data.data);
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }
    }
    const onAddEditAllocation = (type) => {
        let payload = Object.assign({}, expenseCrud);
        let validationInputs = {
            category:payload.category ? '':'Please select Category',
            user:payload.user ? '':'Please select Raised For',
            approver:payload.approver ? '':'Please select Approver',
            sub_category:payload.sub_category ? '':'Please select Sub Category',
            from_date:payload.from_date ? moment(payload.from_date).isValid() ? '':'Please enter Valid Date':'Please enter Date From',
            to_date:payload.to_date ? moment(payload.to_date).isValid() ? '':'Please enter Valid Date':'Please enter Date To',
            bill_amount:payload.bill_amount?'':'Please enter Bill Amount',
            currency:payload.currency ? '':'Please select Currency',
            conversion_rate:payload.conversion_rate ? '':'Please enter Conversion Rate',
        }
        if(payload.from_date && payload.to_date){
            if(moment(payload.from_date).isValid() && moment(payload.to_date).isValid()){
                if(moment(payload.from_date).isAfter(payload.to_date)){
                    validationInputs['to_date'] = 'Date To should be greater than Date From';
                }
            }
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            let formData = new FormData();
            Object.keys(payload).map(key => {
                if(typeof payload[key] == 'object' && key != 'files'){
                    formData.append(key, JSON.stringify(payload[key]))
                }else if (key === 'files') {
                    let index = 1
                    for (let file of payload.files) {
                        formData.append('file_' + index, file)
                        index++
                    }
                }else {
                    formData.append(key, payload[key])
                }
            });
            if(id){
                globalPutService(`technical/project/${params.project_slug}/budgets/expenses/`,formData)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        toggleModalFn();
                        getResponseBack();
                        trackActivity('Project Management', {
                            event_type: 'Updated Expense',
                            page_title: 'Expense Management Expense',
                            project_id: project.id,
                            project_name: project.name,
                            project_slug: params.project_slug,
                            type: type
                          });
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }else{
                        trackActivity('Project Management', {
                            event_type: 'Update Expense Error!',
                            page_title: 'Expense Management Expense',
                            project_id: project.id,
                            project_name: project.name,
                            project_slug: params.project_slug,
                            type: type,
                            error: validationInputs,
                            error_message: response.data.message
                          });
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                });
            }else{
                globalPostService(`technical/project/${params.project_slug}/budgets/expenses/`, formData)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        if(type === 'single'){
                            toggleModalFn();    
                        }else{
                            setExpense({...expenseObj, amount_currency:project.currency ? globalCurrency.find(item => item.code === project.currency) :null});
                            setDropZoneLoader(true);
                            setTimeout(() => {
                                setDropZoneLoader(false);
                            }, 5)
                        }     
                        getResponseBack();
                        trackActivity('Project Management', {
                            event_type: 'Added Expense',
                            page_title: 'Expense Management Expense',
                            project_name: project.name,
                            project_slug: params.project_slug,
                            type: type
                          });
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}}) 
                    }else{
                        trackActivity('Project Management', {
                            event_type: 'Add Expense Error!',
                            page_title: 'Expense Management Expense',
                            project_name: project.name,
                            project_slug: params.project_slug,
                            error: validationInputs,
                            error_message: response.data.message
                          });
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                });
            }
        }else{
            setError(validationInputs)
        }
    }
    const onFieldChange = (e, keyParam, value) => {
        if(keyParam === 'bill_amount' || keyParam === 'conversion_rate'){
            if(keyParam === 'bill_amount'){
                let amount = expenseCrud.conversion_rate && value ? (parseFloat(value) * parseFloat(expenseCrud.conversion_rate)).toFixed(2):0;
                setExpense({
                    ...expenseCrud,
                    [keyParam]: value,
                    amount:amount?amount:''
                });
            }else{
                let amount = expenseCrud.bill_amount && value ? (parseFloat(value) * parseFloat(expenseCrud.bill_amount)).toFixed(2):0;
                setExpense({
                    ...expenseCrud,
                    [keyParam]: value,
                    amount:amount
                });
            }
        }else{
            if(keyParam === 'category'){
                setExpense({
                    ...expenseCrud,
                    [keyParam]: value,
                    'sub_category': null
                });
            }else{
                setExpense({
                    ...expenseCrud,
                    [keyParam]: value
                });
            }
            
            
        }
    }
    const onResetErrorKey = (key) => {
       if(key === 'bill_amount' || key === 'conversion_rate'){
            setError({...error, [key]:'', amount:''});
       }else{
            setError({...error, [key]:''});
       }
    }
    if(mode === 'view' && expenseCrud.id === undefined){
        return null
    }else{
        return(
            <Fragment>
                <Dialog
                    maxWidth="md"
                    open={modal}
                    onClose={toggleModalFn}
                    aria-labelledby="scroll-dialog-title"
                    className='project-management-modal'
                >
                    <DialogTitle id="scroll-dialog-title">
                        {id? mode === 'edit' ? 'Edit':'View':'Submit'} Expense
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        { mode !== 'view' ?
                            <form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            options = {assets}
                                            getOptionLabel={option => option.name}
                                            id="asset"
                                            value={expenseCrud.asset ? expenseCrud.asset : null}
                                            onChange={(e, value) => onFieldChange(e, 'asset', value)}
                                            renderInput={params => <TextField  {...params} label="Asset" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
    
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <AutocompleteCreatable
                                            options={categories}
                                            paramsKey='category'
                                            optionKey='name'
                                            label='Expense Category'
                                            value={expenseCrud.category ? expenseCrud.category : null}
                                            onFieldChange={(e, paramsKey, newValue) => onFieldChange(e, 'category', newValue)}
                                            resetErrorKey={() => onResetErrorKey('category')}
                                            error={error.category}
                                            helperText={error.category}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <AutocompleteCreatable
                                            options = {expenseCrud.category && expenseCrud.category.id ? categories.find(category => category.id === expenseCrud.category.id).sub_categories : []}
                                            paramsKey='sub_category'
                                            optionKey='name'
                                            label='Expense Sub-Category'
                                            value={expenseCrud.sub_category ? expenseCrud.sub_category : null}
                                            onFieldChange={(e, paramsKey, newValue) => onFieldChange(e, 'sub_category', newValue)}
                                            resetErrorKey={() => onResetErrorKey('sub_category')}
                                            error={error.sub_category}
                                            helperText={error.sub_category}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            options = {raisedFor}
                                            getOptionLabel={option => option.name}
                                            id="user"
                                            value={expenseCrud.user ? expenseCrud.user : null}
                                            onChange={(e, value) => onFieldChange(e, 'user', value)}
                                            renderInput={params => <TextField required error={error.user} helperText={error.user} onFocus={() => onResetErrorKey('user')} {...params} label="Raised For" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            options = {approverList}
                                            getOptionLabel={option => option.name}
                                            id="approver"
                                            value={expenseCrud.approver ? expenseCrud.approver : null}
                                            onChange={(e, value) => onFieldChange(e, 'approver', value)}
                                            renderInput={params => <TextField required error={error.approver} helperText={error.approver} onFocus={() => onResetErrorKey('approver')} {...params} label="Approver" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="customer"
                                            label="Customer/Vendor"
                                            fullWidth
                                            margin="normal"
                                            value={expenseCrud.customer}
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 50}}
                                            onChange={(e) => onFieldChange(e, 'customer', e.target.value)}
                                            variant='outlined'
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                required
                                                id="from_date"
                                                label="Date From"
                                                format={fieldDateFormat}
                                                fullWidth
                                                clearable={true}
                                                InputLabelProps={{shrink: true}}
                                                value={expenseCrud.from_date ? expenseCrud.from_date : null}
                                                onChange={(data, value) => {onFieldChange(value, 'from_date', data ? moment(data).format(backendDateFormat):data); onResetErrorKey('from_date')}}
                                                error={error.from_date}
                                                helperText={error.from_date}
                                                inputVariant='outlined'
                                                onFocus={() => onResetErrorKey('from_date')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                required
                                                id="to_date"
                                                label="Date To"
                                                format={fieldDateFormat}
                                                fullWidth
                                                clearable={true}
                                                InputLabelProps={{shrink: true}}
                                                value={expenseCrud.to_date  ? expenseCrud.to_date : null}
                                                onChange={(data, value) => {onFieldChange(value, 'to_date', data ? moment(data).format(backendDateFormat):data);onResetErrorKey('to_date')}}
                                                error={error.to_date}
                                                helperText={error.to_date}
                                                inputVariant='outlined'
                                                onFocus={() => onResetErrorKey('to_date')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="location"
                                            label="Location"
                                            fullWidth
                                            margin="normal"
                                            value={expenseCrud.location}
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 50}}
                                            onChange={(e) => onFieldChange(e, 'location', e.target.value)}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            required
                                            id="bill_amount"
                                            label="Bill Amount"
                                            fullWidth
                                            margin="normal"
                                            value={expenseCrud.bill_amount ? expenseCrud.bill_amount : ''}
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 10}}
                                            onChange={(e) => { regexConstants.numberWithDot4.test(e.target.value) ? onFieldChange(e, 'bill_amount', e.target.value) : e.preventDefault()}}
                                            error={error.bill_amount}
                                            helperText={error.bill_amount}
                                            variant='outlined'
                                            onFocus={() => onResetErrorKey('bill_amount')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            options = {globalCurrency}
                                            getOptionLabel={option => option.code}
                                            id="currency"
                                            value={expenseCrud.currency ? expenseCrud.currency : null}
                                            onChange={(e, value) => onFieldChange(e, 'currency', value)}
                                            renderInput={params => <TextField required error={error.currency} helperText={error.currency} onFocus={() => onResetErrorKey('currency')} {...params} label="Currency" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            required
                                            id="conversion_rate"
                                            label="Conversion Rate"
                                            fullWidth
                                            margin="normal"
                                            value={expenseCrud.conversion_rate ? expenseCrud.conversion_rate:''}
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 5}}
                                            onChange={(e) => { regexConstants.numberWithDot4.test(e.target.value) ? onFieldChange(e, 'conversion_rate', e.target.value) : e.preventDefault()}}
                                            error={error.conversion_rate}
                                            helperText={error.conversion_rate}
                                            variant='outlined'
                                            onFocus={() => onResetErrorKey('conversion_rate')}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            disabled
                                            options = {globalCurrency}
                                            getOptionLabel={option => option.code}
                                            id="amount_currency"
                                            value={expenseCrud.amount_currency ? expenseCrud.amount_currency : null}
                                            onChange={(e, value) => onFieldChange(e, 'amount_currency', value)}
                                            renderInput={params => <TextField  {...params} label="Project Currency" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            disabled
                                            id="amount"
                                            label="Amount"
                                            fullWidth
                                            margin="normal"
                                            value={expenseCrud.amount ? expenseCrud.amount : ''}
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 10}}
                                            onChange={(e) => { regexConstants.numberWithDot4.test(e.target.value) ? onFieldChange(e, 'amount', e.target.value) : e.preventDefault()}}
                                            error={error.amount}
                                            helperText={error.amount}
                                            onFocus={() => onResetErrorKey('amount')}
                                            variant='outlined'
                                        />
                                    </Grid>
    
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="remarks"
                                            label="Remarks"
                                            fullWidth
                                            margin="normal"
                                            value={expenseCrud.remarks ? expenseCrud.remarks : ''}
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 255}}
                                            onChange={(e) => onFieldChange(e, 'remarks', e.target.value)}
                                            variant='outlined'
                                            multiline
                                            maxRows={3}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <label className="for-label">Expense Attachment</label>
                                        {!isDropZoneLoader ?
                                            <div>
                                                <DropzoneArea
                                                    acceptedFiles={['.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx, .csv']}
                                                    filesLimit={10}
                                                    showPreviewsInDropzone={false}
                                                    maxFileSize={52428800}
                                                    useChipsForPreview={true}
                                                    showPreviews={ true }
                                                    dropzoneText={<p>Drag & Drop Attachments Or Click Here </p>}
                                                    dropzoneClass="full-w-drag-drop-zone"
                                                    maxWidth="sm"
                                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                                    showAlerts={['error', 'info']}
                                                    onChange={(files) => onFieldChange('', 'files', files)}
                                                />
                                                <p><span style={{background:'#ffe160', fontSize:'11px', padding:'2px 3px'}}>Format Support: .png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx, .csv</span></p>
                                            </div>:
                                            <div></div>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox color="primary" checked={expenseCrud.send_email?true:false} onChange={(e) => onFieldChange(e, 'send_email', e.target.checked)} value='Allow email notifications' />
                                          }
                                          label={<span style={{fontSize:'14px'}}>Allow email notifications</span>}
                                        />
                                    </Grid>
    
                                </Grid>
                            </form>:null
                        }
                        {mode === 'view' ?
                            <div>
                                    <Grid container spacing={2}>
                                        <LabelValueCard md={3} label='Asset' value={expenseCrud.asset && expenseCrud.asset.name?expenseCrud.asset.name:'--'} />
                                        <LabelValueCard md={3} label='Category' value={expenseCrud.category && expenseCrud.category.name?expenseCrud.category.name:'--'} />
                                        <LabelValueCard md={3} label='Sub Category' value={expenseCrud.sub_category && expenseCrud.sub_category.name?expenseCrud.sub_category.name:'--'} />
                                        <LabelValueCard md={3} label='Status' value={expenseCrud.status?expenseCrud.status:'--'} />
                                        <LabelValueCard md={3} label='Raised For' value={expenseCrud.user && expenseCrud.user.name?expenseCrud.user.name:'--'} />
                                        <LabelValueCard md={3} label='Approver' value={expenseCrud.approver && expenseCrud.approver.name?expenseCrud.approver.name:'--'} />
                                        <LabelValueCard md={3} label='Customer/Vendor' value={expenseCrud.customer?expenseCrud.customer:'--'} />
                                        <LabelValueCard md={3} label='Date From' value={expenseCrud.from_date?moment(expenseCrud.from_date).format(displayDateFormatShort):'--'} />
                                        <LabelValueCard md={3} label='Date To' value={expenseCrud.to_date?moment(expenseCrud.to_date).format(displayDateFormatShort):'--'} />
                                        <LabelValueCard md={3} label='Location' value={expenseCrud.location?expenseCrud.location:'--'} />
                                        <LabelValueCard md={3} label='Bill Amount' value={`${expenseCrud.currency && expenseCrud.currency.code?expenseCrud.currency.code:''} ${expenseCrud.bill_amount?expenseCrud.bill_amount:''}`} />
                                        <LabelValueCard md={3} label='Conversion Rate' value={expenseCrud.conversion_rate?expenseCrud.conversion_rate:''} />
                                        <LabelValueCard md={3} label='Expense Amount' value={`${expenseCrud.amount_currency && expenseCrud.amount_currency.code ? expenseCrud.amount_currency.code:''} ${expenseCrud.amount?expenseCrud.amount:''}`}/>
                                        <LabelValueCard md={3} label='Created At' value={expenseCrud.created_at ? moment(expenseCrud.created_at).format(displayDateFormatShort):'--'} />
                                        <LabelValueCard md={3} label='Created By' value={expenseCrud.created_by && expenseCrud.created_by.name ? expenseCrud.created_by.name:'--'} />
                                        { expenseCrud.status === 'Approved' || expenseCrud.status === 'Paid' ?
                                            <Fragment>
                                                <LabelValueCard md={3} label='Approved Amount' value={expenseCrud.approved_amount?expenseCrud.approved_amount:'--'} />
                                                <LabelValueCard md={3} label='Approved By' value={expenseCrud.approved_by && expenseCrud.approved_by.name ? expenseCrud.approved_by.name:'--'} />
                                                <LabelValueCard md={3} label='Approved At' value={expenseCrud.approved_on?moment(expenseCrud.approved_on).format(displayDateFormatShort):'--'} />
                                                <LabelValueCard md={3} label='Approved Comment' value={expenseCrud.approved_comment?expenseCrud.approved_comment:'--'} />
                                            </Fragment>:null
                                        }
                                        { expenseCrud.status === 'Rejected' ?
                                            <Fragment>
                                                <LabelValueCard md={3} label='Rejected By' value={expenseCrud.rejected_by && expenseCrud.rejected_by.name?expenseCrud.rejected_by.name:'--'} />
                                                <LabelValueCard md={3} label='Rejected At' value={expenseCrud.rejected_on?moment(expenseCrud.rejected_on).format(displayDateFormatShort):'--'} />
                                                <LabelValueCard md={3} label='Rejection Reason' value={expenseCrud.rejected_comment?expenseCrud.rejected_comment:'--'} />
                                            </Fragment>:null
                                        }
                                        
                                        <LabelValueCard md={3} label='Remarks' value={expenseCrud.remarks?expenseCrud.remarks:'--'} />
                                    </Grid>                            
                            </div>:null
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={toggleModalFn} color="primary" variant='outlined' size='small'>
                            Cancel
                        </Button>
                        { mode !== 'view' ?
                            <Fragment>
                                { !id ?
                                    <Button disabled={isLoading} onClick={() => onAddEditAllocation('multi')} color="primary" variant='contained' size='small'>
                                        { isLoading ? <CircularProgress size={24} /> : 'Save & Add More' }
                                    </Button>:null
                                }
                                <Button disabled={isLoading} onClick={() => onAddEditAllocation('single')} color="primary" variant='contained' size='small'>
                                    { isLoading ? <CircularProgress size={24} /> : 'Save' }
                                    
                                </Button>
                            </Fragment>:null
                        }
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
    
}
export default withRouter(AddEditExpense);