export const compSubClass = [
 "Airframe 10 Year Check", "Airframe 12 Year Check", "Airframe 15 Year Structural Inspection", "Airframe 20,000 FC Structural Check", 
 "Airframe 2 Year Check", "Airframe 4 Year Check", "Airframe 6 Year Check" ,"Airframe 8 Year Check" ,"Airframe 9 Year/8C Check" ,
 "Airframe C Check", "Airframe Group 1", "Cabin Modification", "Airframe D Check" ,"Landing Gear Group", "Airframe S4C Check" , 
 "Scheduled Component" ,"APU Performance Restoration" ,"APU Repair" , "Engine LLP Replacement" ,"Engine Performance Restoration" ,"Landing Gear Overhaul" 
]
 export const airframeEvent = [
    "Airframe 10 Year Check" , "Airframe 12 Year Check" , "Airframe 15 Year Structural Inspection" ,"Airframe 20,000 FC Structural Check" ,"Airframe 2 Year Check" ,
    "Airframe 4 Year Check" , "Airframe 6 Year Check" , "Airframe 8 Year Check" ,"Airframe 9 Year/8C Check" , "Airframe C Check" ,
    "Airframe Group 1" , "Cabin Modification" , "Airframe D Check" , "Landing Gear Group" , "Airframe S4C Check" , "Scheduled Component" ,
 ]
 export const apuEvent = [
     "APU Performance Restoration", "APU Repair" 
 ]
 export const engineEvent = [
     "Engine Performance Restoration" ,"Engine Repair"
 ]
 export const lgEvent = [
    "Landing Gear Overhaul" , "Landing Gear Repair" 
 ]

 export const compName = [
    'AIRFRAME','APU','ENGINE','LG'
 ]