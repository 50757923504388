import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress } from '@material-ui/core';
import { checkApiStatus } from '../../../utils_v2';
import { globalPostService } from '../../../utils_v2/globalApiServices';
import { PreviewFileModal } from '../../../projects_management/Elements';

const ReportAnIssue = ({reportAnIssueInfo,setLoading, toggleModalFn}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [buttonLoader, setButtonLoader] = useState(false);
    const [issueInfo, setIssueInfo] = useState({description:'',attachments:[]});
    const [error, setError] = useState({});
    const [previewFile, setPreviewFile] = useState({})
    const [attachments, setAttachment] = useState([])
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {getPlatformConstants()},[]);
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/',{constant_types:['bug_module', 'bug_severity', 'bug_classification']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const onFieldChange = (key, value) => setIssueInfo({...issueInfo, [key]:value});
    const handleDropzoneChange = (uploadedFiles) => setAttachment(uploadedFiles);
    const handleChipClick = (event) => {
        setLoading(true)
        if (event.endsWith('.xlsx') || event.endsWith('.xls') || event?.endsWith('.docx') || event?.endsWith('.doc')) {
            let formData = new FormData();
            formData.append('file', attachments.find(item => item?.path === event));
            globalPostService(`camo/preview/file/`, formData)
                .then(response => {
                    if (response?.status === 200 && response?.statusText === "OK") {
                        setLoading(false)
                        setPreviewFile({ modal: true, data: response?.data, })
                    }
                });
        } else {
            setLoading(false)
            setPreviewFile({ modal: true, data: [attachments?.find(item => item?.path === event)], singlePreview: true })
        }
    };
    const onReportAnIssue = () => {
        let validationInputs = {
            title:issueInfo?.title?.trim()?.length ? '':'Please enter Title',
            module:issueInfo?.module ? '':'Please select Module',
            severity:issueInfo?.severity ? '':'Please select Severity',
            classification:issueInfo?.classification ? '':'Please select Classification',
        };
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
           setButtonLoader(true)
            const formData= new  FormData();
            formData.append('title', issueInfo.title);
            formData.append('description', issueInfo.description);
            formData.append('severity', issueInfo.severity.value);
            formData.append('module', issueInfo.module.value);
            formData.append('classification', issueInfo.classification.value);
            formData.append('current_page_url',window.location.href);
            if(attachments?.length){
                attachments.map((file) => {
                    formData.append('attachments', file);
                });
            }
            globalPostService(`console/report-bug/?module=inspection`, formData)
            .then(response => {
                if(checkApiStatus(response)){
                    toggleModalFn();
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setButtonLoader(false)
            })
        }else{
            setError(validationInputs);
        }
    }

    return(
        <>
            <Dialog
                open={reportAnIssueInfo.modal}
                onClose={toggleModalFn}
                className='report-issue-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    <div>
                        <h5>Report An Issue</h5>
                        <p style={{fontSize:'14px',marginLeft:"1px", fontFamily:'ibmplexRegular'}} >Please report only technical issues / bugs encountered while operating the SPARTA system. Issue will be reported to SPARTA IT team for action.</p>
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div>
                        <Grid spacing={1} container>
                            <Grid item md={12}>
                                <TextField 
                                    required
                                    name='title'
                                    label='Title'
                                    value={issueInfo?.title||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('title', e.target.value)}
                                    error={error?.title}
                                    helperText={error?.title||''}
                                    onFocus={() => setError({...error, 'title':''})}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 150 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    filterSelectedOptions={true}
                                    options = {pltConstants.filter((optionItem)=>optionItem.type === 'bug_module')}
                                    getOptionLabel={option => option.label}
                                    id="module"
                                    value={issueInfo?.module||null}
                                    onChange={(e, value) => {onFieldChange('module', value); setError({...error,'module':''})}}
                                    renderInput={params => <TextField required error={error?.module} helperText={error?.module||''} {...params} label="Module" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    filterSelectedOptions={true}
                                    options = {pltConstants.filter((optionItem)=>optionItem.type === 'bug_severity')}
                                    getOptionLabel={option => option.label}
                                    id="severity"
                                    value={issueInfo?.severity||null}
                                    onChange={(e, value) => {onFieldChange('severity', value); setError({...error,'severity':''})}}
                                    renderInput={params => <TextField required error={error?.severity} helperText={error?.severity||''} {...params} label="Severity" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    filterSelectedOptions={true}
                                    options = {pltConstants.filter((optionItem)=>optionItem.type === 'bug_classification')}
                                    getOptionLabel={option => option.label}
                                    id="classification"
                                    value={issueInfo?.classification||null}
                                    onChange={(e, value) => {onFieldChange('classification', value); setError({...error,'classification':''})}}
                                    renderInput={params => <TextField required error={error?.classification} helperText={error?.classification||''} {...params} label="Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField 
                                    name='description'
                                    label='Description'
                                    value={issueInfo?.description||''}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={5}
                                    onChange={(e) => onFieldChange('description', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 250 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <div>
                                    <DropzoneArea
                                        acceptedFiles={['.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx']}
                                        filesLimit={10}
                                        maxFileSize={104857600}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview={true}
                                        showPreviews={true}
                                        dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> Max File Limit : 10<br/> Supported Format : png, jpg, jpeg, pdf, xls, xlsx, doc, docx<br/> </p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['info','error']}
                                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                        clearOnUnmount={true}
                                        onChange={handleDropzoneChange}
                                        previewChipProps={{
                                            onClick: (event) => {
                                                handleChipClick(event?.target.textContent);
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={buttonLoader} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={buttonLoader} onClick={onReportAnIssue} color='primary' size='small' variant='contained'>
                        {buttonLoader ? <CircularProgress size={24} />:'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {previewFile?.modal ? <PreviewFileModal previewFile={previewFile} maxWidth="xl" toggleModalFn={() => setPreviewFile({ modal: false, data: [] })} /> : null}
        </>
    )
}
export default ReportAnIssue;