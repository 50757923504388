import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Button, TextField, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { tagsHd } from '../components';
import TagsList from '../components/TagsList';
import { DeletePopUp, EmptyCollection, PageLoader, STableLoader } from '../../../shared_elements';
import { globalDeleteService } from '../../../globalServices';
import { useSnackbar } from 'notistack';
import { checkApiStatus, onCheckPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';

const Tags = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagName, setTagName] = useState('');
    const [tagID, setTagID] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [deleteTags, setDeleteTags] = useState({modal:false, data:null})
    

    useEffect(() => {
        getTags('skeletonLoader');
            trackActivity('Project Management', {
                event_type: 'Page Visited',
                page_title: 'Tags',
            });
    }, []);

    const getTags = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`/technical/prs-lessor-tags/`)
            .then(response => {
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
                if (checkApiStatus(response)) {
                    setTags(response.data.data);
                }
            })
    };

    const addTag = () => {
        if (tagID === null) {
            const isTagDuplicate = tags.some(tag => tag.name.toLowerCase() === tagName.toLowerCase());         
            if (isTagDuplicate) {
                enqueueSnackbar("Tag already exists!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                return; 
            }
        } else {
            const isTagDuplicate = tags.some(tag => tag.name.toLowerCase() === tagName.toLowerCase() && tag.id !== tagID);
            if (isTagDuplicate) {
                enqueueSnackbar("Tag already exists!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                return;
            }
        }
        setLoading(true)
        const payload = { name: tagName };
        const API = tagID !== null ? globalPutService(`/technical/prs-lessor-tags/${tagID}/`, payload) : globalPostService(`/technical/prs-lessor-tags/`, payload);
        API.then(response => {
            setLoading(false);
            if (checkApiStatus(response)) {
                setTagName('');
                setTagID(null);
                getTags();
                setOpenModal(false);
                setLoading(false)
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    };

    const handleDialogClose = () => { setOpenModal(false);setTagName('');setTagID(null)};

    const handleDialogOpen = (tag = null) => {
        if (tag) {
            setTagName(tag.name);
            setTagID(tag.id);
        } else {
            setTagName('');
            setTagID(null);
        }
        setOpenModal(true);
    };
    const onDeleteTags = () =>{
        let id= deleteTags?.data?.id
        globalDeleteService(`/technical/prs-lessor-tags/${id}/`)
         .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        setDeleteTags({modal:false, data:null})
                        getTags()
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }else{

                    }
                })
    }

    return (
        <div style={{ margin: '20px 0 0 64px' }}>
            {skeletonLoader ? <STableLoader count={8} /> :
            <div>
                <Paper square style={{ padding: '4px 8px' }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item md={9}></Grid>
                        <Grid item md={3}>
                            <ul className="list-inline" style={{ float: 'right' }}>
                              { onCheckPermission('project_management','template','C') ?
                                <li className="list-inline-item">
                                    <Button onClick={() => handleDialogOpen()} size='small' color="primary" variant="contained">
                                        Add Tag
                                    </Button>                                         
                                </li>
                               :null}  
                            </ul>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper style={{ maxHeight: window.innerHeight - 120 + 'px', overflow: "auto" }}>
                    <Table className='mui-table-format' stickyHeader>
                        <TableHead>
                            <TableRow>
                                {tagsHd.map((tag) => (
                                    <TableCell key={tag.id} align={tag.label === 'Action(s)' ? 'right' : ''}>{tag.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tags?.map((item, index) =>
                                <TagsList
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    editTag={() => handleDialogOpen(item)}
                                    onDelete={() => setDeleteTags({modal:true, data:item})}
                                />
                            )}
                        </TableBody>
                    </Table>
                </Paper>
                <Paper>
                    {!tags?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                </Paper>
            </div>}
            <Dialog open={openModal} onClose={handleDialogClose} maxWidth='md' className='project-management-modal' aria-labelledby="scroll-dialog-title" >
                <DialogTitle>{tagID !== null ? 'Edit Tag' : 'Add Tag'}</DialogTitle>
                <DialogContent dividers style={{width:'300px'}}>
                    <TextField
                        name='tag-name'
                        id="tag-name"
                        label="Tag Name"
                        type="text"
                        value={tagName || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setTagName(e.target.value.trim())}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        inputProps={{ maxLength: 20 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='outlined' size='small' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addTag} size='small' variant='contained' color="primary" disabled={!tagName || isLoading}>
                       {isLoading?  <CircularProgress size={24} />:`${tagID !== null ? 'Save' : 'Save'}` }
                    </Button>
                </DialogActions>
            </Dialog>
            <DeletePopUp
                modal={deleteTags.modal}
                toggleModalFn={() => setDeleteTags({modal:false, data:null})}
                title="Delete Tags"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={onDeleteTags}
            />
            {isLoading ? <PageLoader/> : null}
        </div>      
    );
};

export default Tags;
