import React, { Component, Fragment } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Grid, TextField } from '@material-ui/core';
import { onCheckFormPermission } from '../../utils_v2';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { trackActivity } from '../../utils/mixpanel';
const SortableItem = SortableElement((props) => {
	return(
		<li className="list-inline-item sort-picture-item" style={{width: '225px', height:'208px', zIndex:9, border:'groove 1px', borderColor:'lightgray',margin:'10px',padding:'2px'}}>
			<div className="dropped-pic-card" style={{backgroundSize:'cover',backgroundImage: "url(" + props.item.thumbnail_image + ")"}}>
				<div className="dropped-picture-cta">
					<span onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.updateSlider();}}>
						<VisibilityIcon color="primary" fontSize='small' onClick={() => trackActivity('Project Management', {page_title: 'Inspection Pictures', event_type: 'Item Clicked', event_desc:'Clicked on View Image Icon', form_info: props?.formInfo})}/>
					</span>
					{onCheckFormPermission(props.formInfo) ?
						<Fragment>
							<span onClick={() => props.onEditInspection()}>
								<EditIcon color="primary" fontSize='small' onClick={() => trackActivity('Project Management', {page_title: 'Inspection Pictures', event_type: 'Item Clicked', event_desc:'Clicked on Edit Image Icon', form_info: props?.formInfo})}/>
							</span>
							<span onClick={() => props.onRemoveInspection()}>
								<DeleteOutlineIcon color="secondary" fontSize='small' onClick={() => trackActivity('Project Management', {page_title: 'Inspection Pictures', event_type: 'Item Clicked', event_desc:'Clicked on Delete Image Icon', form_info: props?.formInfo})}/>
							</span>
						</Fragment>:null
					}
				</div>
			</div>
		</li>
	)
});

const SortableList = SortableContainer(({items, onRemoveInspection, onEditInspection, updateSlider, formInfo, pageTitle}) => {
  return (
    <ul className="list-inline inspection-picture-list">
      {items.map((item, index) => (
        <SortableItem
			formInfo={formInfo}
			key={`item-${item.id}`}
			index={index}
			item={item}
			updateSlider={() => updateSlider(index)}
			onRemoveInspection={() => onRemoveInspection(item)}
			onEditInspection={() => onEditInspection(item)}
			pageTitle={pageTitle}
		/>
      ))}
    </ul>
  );
});
class SortTechList extends Component {
  render() {
    return <SortableList
			formInfo={this.props.formInfo}
			axis="xy"
			distance={1}
			items={this.props.items}
			onSortEnd={this.props.onSortEnd}
			updateSlider={(index) => this.props.updateSlider(index)}
			onRemoveInspection={(item) => this.props.onRemoveInspection(item)}
			onEditInspection={(item) => this.props.onEditInspection(item)}
		/>;
  }
}
export default SortTechList;
