import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils_v2';
import { globalGetService, globalExportService, globalFileUploadService, globalPostService } from '../../utils_v2/globalApiServices';

export const useFetchFormApi = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchData, setFetchData] = useState({
        currentFormDetail: {},
        technicalSpecs: [],
        aircraftModel: [],
        engineModal: [],
        lesseeList: [],
    });
    const [attachment, setAttachment] = useState({ modal: false, componentId: "", sectionId: "", files: null })
    const [loaderType, setLoaderType] = useState({ pageLoader: false, skeletonLoader: true, formLoader: false, buttonLoader: false })
    const getCurrentFormDetailApi = async () => {
        setLoaderType({ ...loaderType, formLoader: true })
        const response = await globalGetService(`technical/${props.params.assetType === 'engine' ? 'engine-workorder' : 'workorder'}/${props.params.workOrderSlug}/forms/`, { form: props.params.formSlug });
        if (checkApiStatus(response)) {
            setFetchData(prevState => ({
                ...prevState,
                currentFormDetail: response.data.data
            }));
            setLoaderType({ ...loaderType, formLoader: false })
        }
    };

    const changeFormStatusApi = async (data) => {
        setLoaderType(prevState => ({ ...prevState, pageLoader: true }));
        const response = await globalPostService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`, data);
        if (checkApiStatus(response)) {
            await getCurrentFormDetailApi();
            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        setLoaderType(prevState => ({ ...prevState, pageLoader: false }));
    };

    const getTechnicalSpecsApi = async (sectionId,apiType) => {
        if (apiType === "isUpload") {
            setLoaderType(prevState => ({ ...prevState, buttonLoader: true }));
        } else {
            setLoaderType(prevState => ({ ...prevState, pageLoader: true }));
        }
        const response = await globalGetService(`/technical/workorder/${props.params.workOrderSlug}/technical-specification-sections/`);
        if (checkApiStatus(response)) {
            setLoaderType(prevState => ({ ...prevState, pageLoader: false, skeletonLoader: false, buttonLoader: false }));
            if (apiType === "isUpload") {
                setAttachment({ ...attachment, modal: false, componentId: "", sectionId: "", files: null, fileName: null })
            }
            const updatedData = response?.data?.data?.find(item=>item.id===sectionId)
           const newData = fetchData?.technicalSpecs?.map((item)=>{
                if(item.id=== sectionId){
                    return {...item,...updatedData}
                } else return item
            })
            setFetchData(prevState => ({
                ...prevState,
                technicalSpecs: newData?.length>0 ?newData :response?.data?.data  ,
            }));
        } else {
            setLoaderType(prevState => ({ ...prevState, pageLoader: false, skeletonLoader: false, buttonLoader: false }));
        }
    };

    const editTechnicalSpecsApi = async (data) => {
        setLoaderType(prevState => ({ ...prevState, pageLoader: true }));
        const response = await globalPostService(`/technical/workorder/${props.params.workOrderSlug}/technical-specification-sections/save/`, data);
        setLoaderType(prevState => ({ ...prevState, pageLoader: false, skeletonLoader: false }));
        if (checkApiStatus(response)) {
            enqueueSnackbar(`Aircraft's technical specifications saved successfully!`, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            setFetchData(prevState => ({
                ...prevState,
                technicalSpecs: response.data.data
            }));
        }
    };

    const exportTechnicalSpecsApi = async (file) => {
        setLoaderType(prevState => ({ ...prevState, pageLoader: true }));
        const response = await globalExportService(`/technical/workorder/${props.params.workOrderSlug}/technical-specification-sections/`, { download: file });
        setLoaderType(prevState => ({ ...prevState, pageLoader: false }));
        downloadFileType(response, (`TE005_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), file);
        enqueueSnackbar('Technical Specifications Report downloaded successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    };

    const getAircraftTypesApi = async () => {
        const response = await globalGetService(`console/aircraft-types/`);
        if (checkApiStatus(response)) {
            const aircraftTypes = response.data.data.aircraftTypes.map(item => ({ value: item.id, label: item.name }));
            setFetchData(prevState => ({
                ...prevState,
                aircraftModel: aircraftTypes
            }));
        }
    };

    const getEngineTypesApi = async () => {
        const response = await globalGetService(`console/engine-types/`);
        if (checkApiStatus(response)) {
            const engineTypes = response.data.data.engineTypes.map(item => ({ value: item.id, label: item.name }));
            setFetchData(prevState => ({
                ...prevState,
                engineModal: engineTypes
            }));
        }
    };

    const getLesseesApi = async () => {
        const response = await globalGetService(`console/lessees/`);
        if (checkApiStatus(response)) {
            const lessees = response.data.data.lessees.map(item => ({ value: item.id, label: item.name }));
            setFetchData(prevState => ({
                ...prevState,
                lesseeList: lessees
            }));
        }
    };

    const removeComponentApi = async (id, sectionId) => {
        setLoaderType(prevState => ({ ...prevState, pageLoader: true }));
        const response = await globalPostService(`/technical/workorder/${props.params.workOrderSlug}/section/${sectionId}/custom-components/delete/`, { custom_component_ids: id });
        if (checkApiStatus(response)) {
            await getTechnicalSpecsApi(sectionId);
        }
    };

    const removeSubComponentApi = async (sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex, component, sectionId) => {
        const ids = component.sub_components.flatMap(subComponent =>
            subComponent.custom_components.map((customSubComponent, customComponentIndex) =>
                customComponentIndex === customSubComponentIndex ? customSubComponent.id : null
            )
        ).filter(Boolean);

        const response = await globalPostService(`/technical/workorder/${props.params.workOrderSlug}/section/${sectionId}/custom-components/delete/`, { custom_component_ids: ids });
        if (checkApiStatus(response)) {
            // Update state logic here if needed
        }
    };

    const uploadEquipmentAttachmentApi = async (sectionId, componentId, file) => {
        setLoaderType(prevState => ({ ...prevState, buttonLoader: true }));
        const formData = new FormData();
        formData.append('label', 'attachment');
        formData.append('component_id', componentId);
        formData.append('file', file);
        const response = await globalFileUploadService(`/technical/workorder/${props.params.workOrderSlug}/section/${sectionId}/upload-file/`, formData);
        if (checkApiStatus(response)) {
            setLoaderType(prevState => ({ ...prevState, buttonLoader: false }));
            await getTechnicalSpecsApi(sectionId,"isUpload");
        }
    };

    useEffect(() => {
        getCurrentFormDetailApi();
        getTechnicalSpecsApi();
        getAircraftTypesApi();
        getEngineTypesApi();
        getLesseesApi();
    }, [props]);

    return {
        loaderType,
        fetchData,
        setFetchData,
        attachment,
        setAttachment,
        changeFormStatusApi,
        exportTechnicalSpecsApi,
        removeComponentApi,
        uploadEquipmentAttachmentApi,
        editTechnicalSpecsApi
    };
};

export const usePreviewApi = () => {

}

