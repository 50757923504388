import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  globalGetService,
  globalPostService,
  globalPutService,
  globalDeleteService,
  globalFileUploadService,
  globalFileUploadPutService,
  globalExportService,
} from '../../utils_v2/globalApiServices';
import { checkApiStatus, dataURItoBlob, getLocalStorageInfo } from '../../utils_v2';
import { downloadFileType } from '../../utils';
export const useFetchFormApi = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [fetchData, setFetchData] = useState({ currentFormDetail: {}, findingList: [] });
  const [loaderType, setLoaderType] = useState({ pageLoader: false, skeletonLoader: true, formLoader: false, buttonLoader: false,uploadLoader:false })

  const getCurrentFormDetail = async () => {
    setLoaderType({ ...loaderType, skeletonLoader: true })
    const response = await globalGetService(`technical/${props.params.assetType === 'engine' ? 'engine-workorder' : 'workorder'}/${props.params.workOrderSlug}/forms/`, { form: props.params.formSlug });

    if (checkApiStatus(response)) {
      setLoaderType({ ...loaderType, skeletonLoader: false, pageLoader: false })
      setFetchData(prevState => ({
        ...prevState,
        currentFormDetail: response.data.data
      }));
      getFindingSections()
    } else {
      setLoaderType({ ...loaderType, skeletonLoader: false, pageLoader: false })
      enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  };
  const changeFormStatus = async (data) => {
    setLoaderType(prevState => ({ ...prevState, pageLoader: true }));
    const response = await globalPostService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`, data);
    if (checkApiStatus(response)) {
      await getCurrentFormDetail();
      enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    } else {
      enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
    setLoaderType(prevState => ({ ...prevState, pageLoader: false }));
  };

  const getFindingSections = async (query = {}) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    const response = await globalGetService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-sections/`, query);
    setLoaderType({ ...loaderType, skeletonLoader: false, pageLoader: false })
    if (checkApiStatus(response)) {
      setFetchData(prevState => ({
        ...prevState,
        findingSection: response.data.data
      }));
    }
  };

  const getFindingList = async (sectionId) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    const response = await globalGetService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${sectionId}/inspection-findings/`);
    setLoaderType({ ...loaderType, pageLoader: false })
    if (checkApiStatus(response)) {
      const listData = response?.data?.data?.find(ele => ele.id === sectionId)
      if (listData) {
        const updatedData = fetchData?.findingSection?.list?.map(ele => {
          if (ele.id === listData.id) {
            return { ...ele, finding_count: listData?.findings?.length }
          }
          return ele
        })
        setFetchData(prevState => ({
          ...prevState,
          findingList: listData?.findings || [],
          findingSection: { ...fetchData?.findingSection, list: updatedData }
        }));
      }
    }
  };

  const getFinding = async (sectionId, findingId) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    const response = await globalGetService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${sectionId}/inspection-findings/${findingId}/`);
    setLoaderType({ ...loaderType, pageLoader: false })
    return response;
  };

  const addEditFinding = async (data, loader) => {
    let validationInputs = {
      finding_type: data?.finding_type ? '' : 'Please enter Damage Type',
      remarks: data?.remarks?.trim()?.length ? '' : 'Please enter Description',
    }
    if (Object.keys(validationInputs).every((k) => validationInputs[k] === '')) {
      if(loader === "uploadLoader"){
        setLoaderType({ ...loaderType, uploadLoader: true })
      }else setLoaderType({ ...loaderType, buttonLoader: true })
      let formData = new FormData();
      Object.keys(data).forEach(key => {
        if (data && data[key] && key !== "images" && key!=="section") {
          formData.append(key, data[key]);
        }
      });

      if (data.images) {
        for (let file of data.images) {
          formData.append('files[]', file);
        }
      }

      const url = data.id
        ? `technical/workorder/${props.params.workOrderSlug}/aircraft-section/${data.section.id}/inspection-findings/${data.id}/`
        : `technical/workorder/${props.params.workOrderSlug}/aircraft-section/${data.section.id}/inspection-findings/`;
      const response = data.id
        ? await globalFileUploadPutService(url, formData)
        : await globalFileUploadService(url, formData);
      setLoaderType({ ...loaderType, buttonLoader: false,uploadLoader:false })
      return response;
    } else {
      return { data: { statusCode: 100, error: validationInputs } };
    }
  };

  const changeFindingImage = async (id, file) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    let formData = new FormData();
    formData.append('file', file);
    const response = await globalFileUploadPutService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-picture/${id}/`, formData);
    setLoaderType({ ...loaderType, pageLoader: false })
    return response;
  };

  const deleteFindingImage = async (imgId) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    const response = await globalDeleteService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-picture/${imgId}/`);
    setLoaderType({ ...loaderType, pageLoader: false })
    return response;
  };

  const annotateFindingImg = async (file, imageId) => {
    setLoaderType({ ...loaderType, buttonLoader: true })
    const editedImage = await dataURItoBlob(file);
    let formData = new FormData();
    formData.append('file', editedImage);
    const response = await globalPutService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-picture/${imageId}/`, formData);
    setLoaderType({ ...loaderType, buttonLoader: false })
    return response;
  };

  const pictorialFindingReport = async (queryParams) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    const response = await globalExportService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-sections/`, { download: queryParams });
      if (response) {
        downloadFileType(response, (`Pictorial_Findings_${fetchData?.currentFormDetail?.asset?.msn}_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), queryParams);
        setLoaderType({ ...loaderType, pageLoader: false })
      }else {
        setLoaderType({ ...loaderType, pageLoader: false })
        enqueueSnackbar("Unable to Process the Request due to Technical Error.", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
    return response;
  };


  const deleteFinding = async (deleteIds) => {
    setLoaderType({ ...loaderType, pageLoader: true })
    const response = await globalDeleteService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${deleteIds.section.id}/inspection-findings/${deleteIds.id}/`);
    setLoaderType({ ...loaderType, pageLoader: false })
    return response;
  };


  useEffect(() => {
    getCurrentFormDetail()
  }, [])


  return {
    loaderType,
    fetchData,
    setFetchData,
    changeFormStatus,
    getFindingList,
    addEditFinding,
    getFinding,
    deleteFinding,
    deleteFindingImage,
    annotateFindingImg,
    pictorialFindingReport,
    changeFindingImage
  };
};

