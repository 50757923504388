import React from 'react'
import { Button, IconButton,Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { PageLoader } from '../../../shared_elements';
const MaintenanceUpdates = ({ section, fetchData, plotFieldType,loaderType, sectionIndex, addMaintenanceComponent, removeSubComponentId, removeSubComponent }) => {
  return (
    <div>
      {section.component_questions.map((component, componentIndex) => (
        <div key={componentIndex}>
          <h4 style={{ fontSize: '16px', color: '#691ad3', display: "flex", justifyContent: "space-between" }}>
            {component.name}
            {fetchData?.currentFormDetail?.current_form?.permissions?.can_save ? (
              <Button
                style={{ marginLeft: '15px' }}
                onClick={() => addMaintenanceComponent(component, componentIndex, sectionIndex)}
                size="small"
                variant="contained"
                color="primary"
                component="span"
              >
                Add {component.name}
              </Button>
            ) : null}
          </h4>
         
          <table style={{ width: '100%', marginBottom: '20px' }}>
            <thead>
              <tr>
                {component.sub_components.map((subComponent, subComponentIndex) => (
                  <th style={{ padding: '10px', fontSize: '14px', textAlign: 'left' }} key={subComponentIndex}>
                    {subComponent.name}
                  </th>
                ))}
              </tr>
            </thead>
           
            <tbody>
              <tr>
                {component.sub_components.map((subComponent, subComponentIndex) => (
                  <td key={subComponentIndex}>
                    <table style={{ width: '100%' }}>
                      <tr>
                        {Object.keys(subComponent.specs).map((key, index) => (
                          <td style={{ padding: '10px' }} key={index}>
                            {plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                          </td>
                        ))}
                      {component.sub_components.length-1 !==subComponentIndex ? <div className='vertical-line outer-line-margin '></div> : null } 
                      </tr>
                    </table>
                    {subComponent.custom_components && subComponent.custom_components.length > 0 ? (
                      <table style={{ width: '100%', padding: '0px' }}>
                        {subComponent.custom_components.map((customSubComponent, customSubComponentIndex) => (
                          <tr key={customSubComponentIndex} style={{display:"flex",alignContent:"center",alignItems:"center"}}>
                            {Object.keys(customSubComponent.specs).map((key, index) => (
                              <td style={{ padding: '10px' }} key={index}>
                                {plotFieldType(customSubComponent.specs[key], key, customSubComponent.answers, 'custom_subComponent', sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                              </td>
                            ))}
                            {(subComponentIndex + 1) === component.sub_components.length && fetchData?.currentFormDetail?.current_form?.permissions?.can_save ? (
                              <span
                                onClick={() => customSubComponent.id ? removeSubComponentId(subComponentIndex, customSubComponentIndex, component, section.id) : removeSubComponent(sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                                style={{ float: 'right', cursor: 'pointer',marginTop : '12px' }}
                              >
                                <Tooltip title='Remove' arrow><IconButton><CancelIcon color='secondary' fontSize="small" /> </IconButton></Tooltip> 
                              </span>
                            ) : null}
                            {component.sub_components.length-1 !==subComponentIndex ? <div className='vertical-line inner-line-margin'></div> : null}
                            
                          </tr>
                        ))}
                      </table>
                    ) : null}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      ))}
      {loaderType?.pageLoader ? <PageLoader /> : null}
    </div>
  )
}

export default MaintenanceUpdates