import React, { useState } from 'react';
import { useSnackbar, SnackbarProvider } from 'notistack';
import { Button, Paper, FormControlLabel, Checkbox, Grid, Tooltip, IconButton, TextareaAutosize } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CancelIcon from '@material-ui/icons/Cancel';
import { helperFunc } from '../helperFunc';
import { useFetchFormApi } from '../apiServices';
import { CustomTabs } from '../shared';
import { AttachmentDialogue, MaintenanceUpdates } from '../components';
import ImagePreview from '../components/ImagePreview';
import { imageTypes } from '..';
import FilePreview from '../components/FilePreview';
import { DropDownComp, TextComp, DateComp, FormWrapper, FormSkeletonLoader } from '../../Elements';
import { ExportManu, PageLoader } from '../../../shared_elements';
import "../../assets/styles/_t005.scss"

const InspectionFormT005 = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tabIndex, setTabIndex] = useState(0);
  const {
    loaderType,
    fetchData,
    setFetchData,
    attachment,
    setAttachment,
    changeFormStatusApi,
    exportTechnicalSpecsApi,
    removeComponentApi,
    uploadEquipmentAttachmentApi,
    editTechnicalSpecsApi
  } = useFetchFormApi(props)
  let formViewPermission = fetchData?.currentFormDetail?.list?.length && fetchData?.currentFormDetail?.list?.find(form => form.slug == fetchData?.currentFormDetail?.current_form?.slug)
  let formType = window.location?.pathname?.toUpperCase()?.includes(fetchData?.currentFormDetail?.current_form?.name)
  const disabledField = fetchData?.currentFormDetail?.current_form?.permissions?.can_save === false
  const readTheAnswer = (answers, key) => {
    const answer = answers.filter(answer => answer.label === key);
    return answer.length ? answer[0].value : undefined;
  };

  const updateAnswer = (otherInfo, answers, key, value) => {
    let newAnswers = answers;
    if (answers.filter(answer => answer.label === key).length) {
      newAnswers = newAnswers.map(newAnswer => newAnswer.label === key ? { ...newAnswer, value: value } : newAnswer);
    } else {
      if (otherInfo.component_configuration_id) {
        newAnswers = [...newAnswers, { label: key, value: value, component_configuration_id: otherInfo.component_configuration_id }];
      } else if (otherInfo.sub_component_configuration_id) {
        newAnswers = [...newAnswers, { label: key, value: value, sub_component_configuration_id: otherInfo.sub_component_configuration_id }];
      } else {
        newAnswers = [...newAnswers, { label: key, value: value }];
      }
    }
    return newAnswers;
  };

  const handleChange = (event, newValue) => { setTabIndex(newValue); };

  const plotFieldType = (question, key, answer, specsType, sectionIndex, componentIndex = null, subComponentIndex = null, customSubComponentIndex = null) => {
    const disabled = fetchData?.currentFormDetail?.current_form?.permissions?.can_save === false || question.editable === false;
    if (question.field_type === 'dropdown') {
      let value = null;
      if (question.api && readTheAnswer(answer, key) && fetchData?.aircraftModel?.length) {
        value = fetchData?.aircraftModel.find(item => item.value === readTheAnswer(answer, key));
      }
      if (!question.api && readTheAnswer(answer, key)) {
        value = question.options.find(item => item.value === readTheAnswer(answer, key));
      }
      return (
        <div className="is-fitted-fields">
          {question.is_fitted === "true" && (
            <FormControlLabel
              className="checkbox-label"
              control={
                <Checkbox
                  checked={readTheAnswer(answer, 'is_fitted') === 'true'}
                  size="small"
                  color="primary"
                  onChange={(e) => onFieldChange({ label: 'Is Fitted', field_type: 'checkbox' }, 'is_fitted', e.target.checked ? 'true' : 'false', specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                />
              }
              label="Is Fitted"
            />

          )}
          <DropDownComp
            label={question.label}
            keyParam={key}
            value={value}
            options={question.api ? fetchData?.aircraftModel : question.options}
            onFieldChange={(event, keyParam, value) => onFieldChange(question, key, value, specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
            disabled={disabled}
          />
        </div>
      );
    } else if (question.field_type === 'date') {
      return (
        <DateComp
          label={question.label}
          keyParam={key}
          value={readTheAnswer(answer, key)}
          onFieldChange={(event, keyParam, value) => onFieldChange(question, key, value, specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
          disabled={disabled}
        />
      );
    } else if (question.field_type === 'string') {
      if (sectionIndex === 4 && question?.label === "Remarks") {
        return (
          <TextareaAutosize
            className={disabledField ? 'text-area cursor-not-allowed' : "text-area"}
            disabled={disabled}
            value={readTheAnswer(answer, key)}
            minRows={3}
            placeholder="Remarks"
            fullWidth
            multiline
            maxLength={500}
            onChange={(e) => onFieldChange(question, key, e.target.value.replace(/^\s+/, ""), specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)} style={{ border: "1px solid #e5e3e3", marginTop: "4px", width: "100%", padding: "8px", fontSize: "16px" }} />
        )
      } else {
        return (
          <div className="is-fitted-fields">
            <TextComp
              checkBoxComponent={<div className='d-flex'>{question?.label}:
                <div style={{ marginTop: "-11px", marginLeft: "3px" }}>
                  <FormControlLabel
                    className="checkbox-label"
                    control={
                      <Checkbox
                        checked={readTheAnswer(answer, 'is_fitted') === 'true'}
                        size="small"
                        color="primary"
                        onChange={(e) => onFieldChange({ label: 'Is Fitted', field_type: 'checkbox' }, 'is_fitted', e.target.checked ? 'true' : 'false', specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                      />
                    }
                    label={<span className='fitted-label'>Is Fitted</span>}
                  />
                </div>
              </div>}
              label={question.label}
              question={question}
              keyParam={key}
              value={readTheAnswer(answer, key)}
              onFieldChange={(event, keyParam, value) => onFieldChange(question, key, value, specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
              disabled={disabled}
            />

          </div>
        );
      }

    } else {
      return null;
    }
  };



  const removeSubComponent = (sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex) => {
    setFetchData(prevState => {
      const updatedTechnicalSpecs = prevState.technicalSpecs.map((section, index) => {
        if (index === sectionIndex) {
          const updatedComponentQuestions = section.component_questions.map((comp, innerIndex) => {

            if (innerIndex === componentIndex) {
              const updatedSubComponents = comp.sub_components.map((subComp, subIndex) => {


                // if (subIndex === subComponentIndex) {

                return {
                  ...subComp,
                  custom_components: subComp.custom_components.filter((_, customIndex) => customIndex !== customSubComponentIndex)
                };
              }
                // else {
                //   return subComp;
                // }
                // }
              );

              return {
                ...comp,
                sub_components: updatedSubComponents
              };
            } else {
              return comp;
            }
          });
          return {
            ...section,
            component_questions: updatedComponentQuestions
          };
        } else {
          return section;
        }
      });

      return {
        ...prevState,
        technicalSpecs: updatedTechnicalSpecs
      };
    });
  };

  const addMaintenanceComponent = (component, componentIndex, sectionIndex) => {
    setFetchData(prevState => ({
      ...prevState,
      technicalSpecs: prevState.technicalSpecs.map((section, index) =>
        index === sectionIndex ? {
          ...section,
          component_questions: section.component_questions.map((comp, innerIndex) =>
            innerIndex === componentIndex ? {
              ...comp,
              sub_components: comp.sub_components.map(subComponent => (
                {
                  ...subComponent,
                  custom_components: [
                    ...subComponent.custom_components,
                    { answers: [], specs: subComponent.specs, maintenance_specs: subComponent.maintenance_specs }
                  ]
                }))
            } : comp
          )
        } : section
      )
    }));
  };

  const removeSubComponentId = (subComponentIndex, customSubComponentIndex, component, sectionId) => {
    let ids = [];
    component.sub_components.forEach((subComponent, subComponentIndex) => {
      subComponent.custom_components.forEach((customSubComponent, customComponentIndex) => {
        if (customComponentIndex === customSubComponentIndex) {
          ids.push(customSubComponent.id);
        }
      });
    });

    removeComponentApi(ids, sectionId);
  };
  const onFieldChange = (questionObj, key, value, specsType, sectionIndex, componentIndex = null, subComponentIndex = null, customSubComponentIndex = null) => {
    setFetchData(prevState => {
      const updatedSpecs = [...prevState.technicalSpecs];
      const section = updatedSpecs[sectionIndex];

      if (specsType === 'sections') {
        section.sections = section.sections.map((sec, innerIndex) =>
          innerIndex === componentIndex ? {
            ...sec,
            answers: updateAnswer(questionObj, sec.answers, key, value)
          } : sec
        );
      } else if (componentIndex === null && subComponentIndex === null) {
        section.answers = updateAnswer(questionObj, section.answers, key, value);
      } else if (subComponentIndex === null) {
        section.component_questions[componentIndex].answers = updateAnswer(questionObj, section.component_questions[componentIndex].answers, key, value);
      } else {
        if (specsType === 'custom_components') {
          section.component_questions[componentIndex].custom_components[subComponentIndex].answers = updateAnswer(questionObj, section.component_questions[componentIndex].custom_components[subComponentIndex].answers, key, value);
        } else if (specsType === 'custom_subComponent') {
          section.component_questions[componentIndex].sub_components[subComponentIndex].custom_components[customSubComponentIndex].answers = updateAnswer(questionObj, section.component_questions[componentIndex].sub_components[subComponentIndex].custom_components[customSubComponentIndex].answers, key, value);
        } else {
          section.component_questions[componentIndex].sub_components[subComponentIndex].answers = updateAnswer(questionObj, section.component_questions[componentIndex].sub_components[subComponentIndex].answers, key, value);
        }
      }

      return { ...prevState, technicalSpecs: updatedSpecs };
    });
  };

  const saveTemplateForm = () => {
    const updatedData = fetchData.technicalSpecs || [];
    let valid = true;

    const aircraftDetails = updatedData.find(item => item.id === 97);
    if (!aircraftDetails) return;

    const getAnswerValue = (label) => aircraftDetails?.answers?.find(item => item.label === label)?.value;

    const dateOfManufacture = getAnswerValue("date_of_manufacture");
    const startDateOfLease = getAnswerValue("start_date_of_lease");
    const endDateOfLease = getAnswerValue("end_date_of_lease");

    const showError = (message) => {
      setTabIndex(0);
      valid = false;
      enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    };

    if (!dateOfManufacture) {
      showError("Manufacture Date is required");
    } else if (!helperFunc.isValidDate(dateOfManufacture)) {
      showError("Date of Manufacture is not valid");
    } else if (!helperFunc.isBeforeTime(dateOfManufacture)) {
      showError("Future date is not valid in Date of Manufacture");
    } else if (startDateOfLease && !helperFunc.isValidDate(startDateOfLease)) {
      showError("Start Date of Lease is not valid");
    } else if (endDateOfLease && !helperFunc.isValidDate(endDateOfLease)) {
      showError("End Date of Lease is not valid");
    } else if (!startDateOfLease && endDateOfLease) {
      showError("Please enter Start Date of Lease before End Date of Lease");
    } else if (dateOfManufacture && startDateOfLease && helperFunc.isBeforeTime(startDateOfLease, dateOfManufacture)) {
      showError("Start Date of Lease cannot be before the Date of Manufacture");
    } else if (endDateOfLease && startDateOfLease && helperFunc.isBeforeTime(endDateOfLease, startDateOfLease)) {
      showError("End Date of Lease cannot be before the Start Date of Lease");
    }

    if (valid) {
        editTechnicalSpecsApi(fetchData.technicalSpecs);
    }
  };

  const addComponent = (component, componentIndex, sectionIndex) => {
    let componentObj = { ...component };
    componentObj.answers = [];
    componentObj.allow_dynamic = false;
    componentObj.is_custom_component = true;
    componentObj.component_id = componentObj.id;
    delete componentObj.id;
    delete componentObj.name;
    delete componentObj.custom_components;
    delete componentObj.sub_components;

    setFetchData(prevState => ({
      ...prevState,
      technicalSpecs: prevState.technicalSpecs.map((section, index) =>
        index === sectionIndex ? {
          ...section,
          component_questions: section.component_questions.map((comp, innerIndex) =>
            innerIndex === componentIndex ? {
              ...comp,
              custom_components: [...comp.custom_components, componentObj]
            } : comp
          )
        } : section
      )
    }));
  };

  const removeComponent = (sectionIndex, componentIndex, subComponentIndex) => {
    setFetchData(prevState => ({
      ...prevState,
      technicalSpecs: prevState.technicalSpecs.map((section, index) =>
        index === sectionIndex ? {
          ...section,
          component_questions: section.component_questions.map((comp, innerIndex) =>
            innerIndex === componentIndex ? {
              ...comp,
              custom_components: comp.custom_components.filter((_, subIndex) => subIndex !== subComponentIndex)
            } : comp
          )
        } : section
      )
    }));
  };

  const addSection = (component, componentIndex, sectionIndex) => {
    let componentObj = { ...component };
    componentObj.answers = [];
    componentObj.allow_dynamic = false;
    componentObj.is_custom = true;
    componentObj.component_id = componentObj.id;

    delete componentObj.id;
    delete componentObj.name;

    const technicalInspectionArray = [...fetchData.technicalSpecs];

    technicalInspectionArray[sectionIndex].sections.splice(componentIndex + 1, 0, componentObj);

    setFetchData(prevState => ({
      ...prevState,
      technicalSpecs: technicalInspectionArray
    }));
  };
  const toggleModalFn = () => setAttachment({ ...attachment, modal: false, files: null, componentId: null, sectionId: null })
  const onAddAttachment = (attachment) => {
    if (attachment && Object.keys(attachment)?.length > 0 && attachment?.files?.length > 0) {
      uploadEquipmentAttachmentApi(attachment?.sectionId, attachment?.component?.id, attachment?.files[0])
    }
  }

  return (
    <section className='project-forms-section'>
      {fetchData?.currentFormDetail && formViewPermission && formType && Object.keys(fetchData?.currentFormDetail).length ?
        <FormWrapper
          saveTemplateForm={saveTemplateForm}
          showSaveBtn={true}
          hideAction={fetchData?.currentFormDetail?.current_form.progress === 0 ? true : false}
          formInfo={fetchData?.currentFormDetail}
          exportReportBtn={<ExportManu disabled={!fetchData?.technicalSpecs?.list?.length ? false : true} exportReportFn={(file) => exportTechnicalSpecsApi(file.extension)} title="Export Report" files={[{ title: 'PDF', extension: 'pdf', key: '' }, { title: 'Doc', extension: 'docx', key: '' }]} />}
          updateFormStatusFn={(data) => changeFormStatusApi(data)}
        /> : loaderType?.formLoader ? <FormSkeletonLoader /> : null
      }
      <div className='technical-specification'>
        <div className="technical-aircraft-froms">
          {!fetchData?.technicalSpecs?.length && loaderType?.skeletonLoader ? <Skeleton style={{ marginBottom: '2px' }} variant="rect" width={'100%'} height={(window.innerHeight - 128) + 'px'} /> : <div className="form-t005-cn">
            <Paper className='paper-border-bottom' >
              <CustomTabs value={tabIndex} onChange={handleChange} data={fetchData?.technicalSpecs} />
            </Paper>
            <Paper className='paper-border-top'>
              {fetchData?.technicalSpecs?.map((section, sectionIndex,) =>
                sectionIndex === tabIndex && (
                  <div key={sectionIndex} className='template-mui-root' style={{ maxHeight: (window.innerHeight - 192) + 'px', height: (window.innerHeight - 192) + 'px', }}>
                    {section.name !== 'Maintenance Check updates' ? (
                      <div>
                        <Grid container spacing={3}>
                          {section.asset_specs && Object.keys(section.asset_specs).length > 0 ? (
                            Object.keys(section.asset_specs).map((key, assetIndex) => (
                              <Grid item md={3} xs={12} key={assetIndex}>
                                {plotFieldType(section.asset_specs[key], key, section.answers, 'asset_specs', sectionIndex)}
                              </Grid>
                            ))
                          ) : null}

                          {section.maintenance_specs && Object.keys(section.maintenance_specs).length > 0 ? (
                            Object.keys(section.maintenance_specs).map((key, mntIndex) => (
                              <Grid item md={3} xs={12} key={mntIndex}>
                                {plotFieldType(section.maintenance_specs[key], key, section.answers, 'maintenance_specs', sectionIndex)}
                              </Grid>
                            ))
                          ) : null}

                          {section.specs && Object.keys(section.specs).length > 0 ? (
                            Object.keys(section.specs).map((key, secIndex) => (
                              <Grid item md={3} xs={12} key={secIndex}>
                                {plotFieldType(section.specs[key], key, section.answers, 'specs', sectionIndex)}
                              </Grid>
                            ))
                          ) : null}
                        </Grid>

                        <div>
                          {section.component_questions && section.component_questions.length > 0 ? (
                            section.component_questions.map((component, componentIndex) => (
                              <div key={componentIndex} style={{ background: '#F7F7F7', padding: '15px', marginTop: '20px' }}>
                                <h4 className='section-title'>
                                  {component.name}
                                  {component.allow_dynamic && fetchData?.currentFormDetail?.current_form?.permissions?.can_save ? (
                                    <Button
                                      style={{ marginLeft: '15px' }}
                                      onClick={() => addComponent(component, componentIndex, sectionIndex)}
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                    >
                                      Add {component.name}
                                    </Button>
                                  ) : null}
                                </h4>

                                {component.maintenance_specs && Object.keys(component.maintenance_specs).length > 0 ? (
                                  <Grid container spacing={3}>
                                    {Object.keys(component.maintenance_specs).map((key, index) => (
                                      component.maintenance_specs[key].field_type !== 'checkbox' && (
                                        <Grid item style={{ flexBasis: component?.id === 164 ? "45%" : component.custom_components && component.custom_components.length > 0 ? "19.3%" : key === "remarks" ? "100%" : "20%" }} key={index}>
                                          {plotFieldType(component.maintenance_specs[key], key, component.answers, 'maintenance_specs', sectionIndex, componentIndex)}
                                        </Grid>
                                      )
                                    ))}
                                  </Grid>
                                ) : null}

                                {component.custom_components && component.custom_components.length > 0 ? (
                                  component.custom_components.map((customComponents, subComponentIndex) => (
                                    <Grid container spacing={3}>
                                      {customComponents.maintenance_specs && Object.keys(customComponents.maintenance_specs).length > 0 ? (
                                        Object.keys(customComponents.maintenance_specs).map((key, index) => (
                                          customComponents.maintenance_specs[key].field_type !== 'checkbox' && (
                                            <Grid style={{ flexBasis: "19.3%", padding: "14px 12px 8px 12px" }} key={index}>
                                              {plotFieldType(customComponents.maintenance_specs[key], key, customComponents.answers, 'custom_components', sectionIndex, componentIndex, subComponentIndex)}
                                            </Grid>
                                          )
                                        ))
                                      ) : null}
                                      {fetchData?.currentFormDetail?.current_form?.permissions?.can_save ? (
                                        <span
                                          onClick={() => customComponents.id ? removeComponentApi([customComponents.id], section.id) : removeComponent(sectionIndex, componentIndex, subComponentIndex)}
                                          style={{ cursor: 'pointer', display: "flex", alignItems: "center", marginTop: "18px" }}
                                        >
                                          <Tooltip title='Remove' arrow><IconButton><CancelIcon color='secondary' fontSize="small" /> </IconButton></Tooltip>
                                        </span>
                                      ) : null}
                                      {customComponents.specs && Object.keys(customComponents.specs).length > 0 ? (
                                        Object.keys(customComponents.specs).map((key, index) => (
                                          customComponents.specs[key].field_type !== 'checkbox' && (
                                            <Grid md={3} xs={12} key={index}>
                                              {plotFieldType(customComponents.specs[key], key, customComponents.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                            </Grid>
                                          )
                                        ))
                                      ) : null}
                                    </Grid>
                                    //  </div> 
                                  ))
                                ) : null}
                                {component.sub_components && component.sub_components.length > 0 ? (
                                  component.sub_components.map((subComponent, subComponentIndex) => (
                                    <div key={subComponentIndex} style={{ background: '#edecef', padding: '15px', marginTop: '20px' }}>
                                      <h5 style={{ fontSize: '14px', }}>
                                        {subComponent.name}
                                        {subComponent.allow_dynamic && fetchData?.currentFormDetail?.current_form?.permissions?.can_save ? (
                                          <Button
                                            style={{ marginLeft: '15px' }}
                                            onClick={() => addComponent(subComponent, subComponentIndex, componentIndex, sectionIndex)}
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            component="span"
                                          >
                                            Add {subComponent.name}
                                          </Button>
                                        ) : null}
                                      </h5>
                                      <Grid container spacing={3}>
                                        {subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length > 0 ? (
                                          Object.keys(subComponent.maintenance_specs).map((key, index) => (

                                            subComponent.maintenance_specs[key].field_type !== 'checkbox' && (
                                              <Grid item md={3} xs={12} key={index}>
                                                {plotFieldType(subComponent.maintenance_specs[key], key, subComponent.answers, 'maintenance_specs', sectionIndex, componentIndex, subComponentIndex)}
                                              </Grid>
                                            )
                                          ))
                                        ) : null}

                                        {subComponent.specs && Object.keys(subComponent.specs).length > 0 ? (
                                          Object.keys(subComponent.specs).map((key, index) => (
                                            subComponent.specs[key].field_type !== 'checkbox' && (
                                              <Grid item md={3} xs={12} key={index}>
                                                {plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                              </Grid>
                                            )
                                          ))
                                        ) : null}
                                      </Grid>
                                    </div>
                                  ))
                                ) : null}
                                {component.specs && Object.keys(component.specs).length > 0 ? (
                                  <Grid container spacing={3}>
                                    {Object.keys(component.specs).map((key, index) => (
                                      component.specs[key].field_type !== 'checkbox' && (
                                        <Grid item md={3} xs={12} key={index}>

                                          {component.specs[key].field_type === 'attach' ? (
                                            <div>
                                              {disabledField ? null :
                                                <>  <p style={{ fontSize: "12px" }}>{component.specs[key].label}</p>
                                                  <Button onClick={() => setAttachment({ ...attachment, modal: true, component: component, sectionId: section?.id })} size="small" variant="outlined" color="primary" component="span">
                                                    Choose Attachment
                                                  </Button></>
                                              }

                                              <br />
                                              {readTheAnswer(component.answers, key) ?
                                                imageTypes.includes(helperFunc.getFileType(readTheAnswer(component.answers, key))) ?
                                                  <ImagePreview attachment={attachment} disabledField={disabledField} fileUrl={readTheAnswer(component.answers, key)} /> :
                                                  <FilePreview disabledField={disabledField} fileUrl={readTheAnswer(component.answers, key)} />
                                                : null}
                                            </div>
                                          ) : (
                                            plotFieldType(component.specs[key], key, component.answers, 'specs', sectionIndex, componentIndex)
                                          )}

                                          <AttachmentDialogue params={props.params} attachment={attachment} setAttachment={setAttachment} loaderType={loaderType} onAddAttachment={() => onAddAttachment(attachment)} toggleModalFn={toggleModalFn} />
                                        </Grid>
                                      )
                                    ))}
                                  </Grid>
                                ) : null}
                              </div>
                            ))
                          ) : null}
                        </div>

                        <div>
                          {section.sections && section.sections.length ?
                            section.sections.map((component, componentIndex) =>
                              <div key={componentIndex} className="" style={{ background: '#F7F7F7', marginTop: '20px', padding: '15px' }}>
                                <h4 style={{ paddingBottom: "2px", fontSize: '16px', color: '#691AD3', marginBottom: '20px' }}>{component.name}</h4>
                                {component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                                  <Grid container spacing={3}>
                                    {Object.keys(component.maintenance_specs).map((key, index) =>
                                      component.maintenance_specs[key].field_type !== 'checkbox' &&
                                      <Grid item md={3} xs={12}>
                                        {plotFieldType(component.maintenance_specs[key], key, component.answers, 'sections', sectionIndex, componentIndex)}
                                      </Grid>
                                    )}
                                  </Grid> : null
                                }
                                {component.specs && Object.keys(component.specs).length ? // Need to add checkbox
                                  <Grid container spacing={3}>
                                    {Object.keys(component.specs).map((key, index) =>
                                      component.specs[key].field_type !== 'checkbox' &&
                                      <Grid key={index + "componentSpecs"} item md={3} xs={12}>
                                        {plotFieldType(component.specs[key], key, component.answers, 'sections', sectionIndex, componentIndex)}
                                      </Grid>
                                    )}
                                  </Grid> : null
                                }
                                {component.sub_components && component.sub_components.length ?
                                  component.sub_components.map((subComponent, subComponentIndex) =>
                                    <div key={subComponentIndex} className="">
                                      <h5 style={{ fontSize: '14px', borderBottom: '1px solid #d7d7d7', paddingBottom: '10px', marginBottom: '20px' }}>
                                        {subComponent.name}
                                        {subComponent.allow_dynamic && (fetchData?.currentFormDetail && fetchData?.currentFormDetail.current_form && fetchData?.currentFormDetail.current_form.permissions && fetchData?.currentFormDetail.current_form.permissions.can_save) ?
                                          <Button style={{ marginLeft: '15px' }} onClick={() => addComponent(subComponent, subComponentIndex, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {subComponent.name}</Button> : null
                                        }
                                      </h5>
                                      <Grid container spacing={3}>
                                        {subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                          Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                            subComponent.maintenance_specs[key].field_type !== 'checkbox' &&
                                            <Grid item md={3} xs={12}>
                                              {plotFieldType(subComponent.maintenance_specs[key], key, subComponent.answers, 'maintenance_specs', sectionIndex, componentIndex, subComponentIndex)}
                                            </Grid>
                                          ) : null
                                        }
                                        {subComponent.specs && Object.keys(subComponent.specs).length ?
                                          Object.keys(subComponent.specs).map((key, index) =>
                                            subComponent.specs[key].field_type !== 'checkbox' &&
                                            <Grid item md={3} xs={12}>
                                              {plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                            </Grid>
                                          ) : null
                                        }
                                      </Grid>
                                    </div>
                                  ) : null
                                }
                                {component.allow_dynamic && (fetchData?.currentFormDetail && fetchData?.currentFormDetail.current_form && fetchData?.currentFormDetail.current_form.permissions && fetchData?.currentFormDetail.current_form.permissions.can_save) ?
                                  <Button style={{ marginLeft: '15px' }} onClick={() => addSection(component, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {component.name}</Button> : null
                                }
                              </div>
                            ) : null
                          }
                        </div>
                      </div>
                    ) : (
                      <MaintenanceUpdates section={section} fetchData={fetchData} plotFieldType={plotFieldType} loaderType={loaderType} sectionIndex={sectionIndex} addMaintenanceComponent={addMaintenanceComponent} removeSubComponentId={removeSubComponentId} removeSubComponent={removeSubComponent} />
                    )}
                  </div>
                )
              )}

            </Paper>
          </div>}

        </div>

      </div>
      {!loaderType?.skeletonLoader && loaderType?.pageLoader ? <PageLoader /> : null}
    </section>
  );
};

export default InspectionFormT005;