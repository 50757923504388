import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack";
import { Paper, Tooltip, CircularProgress, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckPermission, getLocalStorageInfo } from '../../../utils_v2';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { trackActivity } from '../../../utils/mixpanel';
import { taskAndIssueSelfPermission } from '../'
const TaskIssues = ({params,cardType, taskDetail, permission}) => {
    const userInfo = getLocalStorageInfo();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [taskIssues, setTaskIssues] = useState([]);
    const [issueSuggestion, setIssueSuggestion] = useState([]);
    const [selectedIssue, setSelectedIssue] = useState({modal:false, issues:[]});
    useEffect(() => {
        getTaskIssues();
    }, []);
    const getTaskIssues = () => {
        globalGetService(`technical/project/${params.project_slug}/${cardType === 'issue' ? 'issue':'task'}/${params.slug}/${cardType === 'issue' ? 'tasks':'issues'}/`,{view:'all', fields:'id,name,status'})
        .then(response => {
            if(checkApiStatus(response)){
                setTaskIssues(response.data.data);
            }
        });
    }
    const getIssues = (value) => {
        if(value){
            globalGetService(`technical/project/${params.project_slug}/${cardType === 'issue' ? 'tasks':'issue'}/`,{view:'all', fields:'id,name', name:value})
            .then(response => {
                const addedIssueIds = taskIssues.map((item)=> item.id)
                if(checkApiStatus(response)){
                    setIssueSuggestion(response.data.data.filter(item => !addedIssueIds.includes(item.id)));
                }
            });
        }else{
            setIssueSuggestion([])
        }
    }
    const onSelectIssue = (issue, opsType) => {
        if(opsType === 'add'){
            let selectedData = [...selectedIssue.issues, issue]
            setSelectedIssue({ ...selectedIssue, issues:[...new Map(selectedData.map(item => [item.id, item])).values()] })
            setIssueSuggestion([]);
            setSearch('');
        }else{
             setSelectedIssue({ ...selectedIssue, issues:selectedIssue.issues.filter(item => item.id !== issue.id)})
        }
    }
    const onAssociateIssue = () => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/${cardType === 'issue' ? 'issue':'task'}/${params.slug}/${cardType === 'issue' ? 'tasks':'issues'}/link/`, selectedIssue.issues)
        .then(response => {
            if(checkApiStatus(response)){
                getTaskIssues()
                setSelectedIssue({modal:false, issues:[]});
                 enqueueSnackbar( cardType === 'issue' ? 'Tasks associated successfully.' : 'Issues associated successfully.', { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
                 trackActivity('Project Management', {
                    event_type: 'Task/Issue Link Success',
                    page_title: 'Task Management Task/Issue Link ',
                    project_slug: params.slug,
                    card_type: cardType === 'issue' ? 'issue':'task',
                    request_body: selectedIssue.issues ? selectedIssue.issues : ''
                  });
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            enqueueSnackbar('Something went wrong!', { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, })
            trackActivity('Project Management', {
                event_type: 'Task/Issue Link Failed',
                page_title: 'Task Management Task/Issue Link ',
                project_slug: params.slug,
                card_type: cardType === 'issue' ? 'issue':'task',
                request_body: selectedIssue.issues ? selectedIssue.issues : '',
                error_source: 'Backend',
                error_msg: 'Something went wrong!'
              });
        });
    }
    const onUnlinkIssue = (issue) => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/${cardType === 'issue' ? 'issue':'task'}/${params.slug}/${cardType === 'issue' ? 'tasks':'issues'}/unlink/`, [issue])
        .then(response => {
            if(checkApiStatus(response)){
                setTaskIssues(taskIssues.filter(item => item.id !== issue.id));
                enqueueSnackbar(cardType === 'issue' ? 'Tasks  unlinked successfully.' : 'Issues unlinked successfully.', { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
                trackActivity('Project Management', {
                    event_type: 'Task/Issue Unlink Success',
                    page_title: 'Task Management Task/Issue Unlink ',
                    project_slug: params.slug,
                    card_type: cardType === 'issue' ? 'issue':'task',
                    request_body: selectedIssue.issues ? selectedIssue.issues : ''
                  });
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            enqueueSnackbar('Something went wrong!', { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, });
            trackActivity('Project Management', {
                event_type: 'Task/Issue Unlink Failed',
                page_title: 'Task Management Task/Issue Unlink ',
                project_slug: params.slug,
                card_type: cardType === 'issue' ? 'issue':'task',
                request_body: selectedIssue.issues ? selectedIssue.issues : '',
                error_source: 'Backend',
                error_msg: 'Something went wrong!'
              });

        });
    }
    return(
        <Fragment>
            <div className='task-management-linked-issue'>
                {taskIssues.map((item) => 
                    <div className='linked-issue'>
                        <div> 
                            <h5>{item.name}</h5>
                            {item.status && item.status.label ? <span className={`milestone-status ${item.status.label.toLowerCase()}`}>{item.status.label}</span>:null}
                        </div>
                        {taskAndIssueSelfPermission(cardType === 'issue' ? 'issues':'tasks', 'U', taskDetail, permission?.update?.value||null) ?
                            <Tooltip title={cardType === 'issue' ? 'Unlink Task' : 'Unlink Issue' } arrow><RemoveCircleIcon onClick={() => onUnlinkIssue(item)} fontSize='small' color='secondary' /></Tooltip>:null
                        }
                    </div>
                )}
                {taskAndIssueSelfPermission(cardType === 'issue' ? 'issues':'tasks', 'U', taskDetail, permission?.update?.value||null) ?
                    <div style={{marginTop:'15px'}}>
                        <Button onClick={() => setSelectedIssue({modal:true, issues:[]})} color='primary' variant='outlined' size='small'>  {cardType === 'issue' ? 'Associate Tasks' : 'Associate Issues' }</Button>
                    </div>:null
                }
            </div>
            { selectedIssue.modal ?
                <Dialog
                    open={selectedIssue.modal}
                    onClose={() => setSelectedIssue({modal:false, issues:[]})}
                >
                    <DialogTitle id="scroll-dialog-title">
                       {cardType === 'issue' ? 'Associate Tasks' : 'Associate Issues' } 
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div className='link-issues-modal' style={{width:'440px'}}>
                            { selectedIssue.issues.length ?
                                <div className='issue-selected'>
                                    <h4>{cardType === 'issue' ? 'Selected Task' : 'Selected Issue'}</h4>
                                    <div>
                                        { selectedIssue.issues.map((item) =>
                                            <div className='issue-list'>
                                                <h5>{item.name}</h5>
                                                <CancelIcon onClick={() => onSelectIssue(item, 'remove')} fontSize='small' color='secondary'/>
                                            </div>
                                        )}
                                    </div>
                                </div>:null
                            }
                            <div className='search-issue'>
                                <TextField
                                    variant="outlined"
                                    value={search}
                                    onChange={(e) => {setSearch(e.target.value);getIssues(e.target.value)}}
                                    placeholder={cardType === 'issue' ? 'Type task name for search ' : 'Type issue name for search'}
                                    fullWidth 
                                />
                                <Paper className='issue-suggestion'>
                                    {issueSuggestion.map((item) =>
                                    !selectedIssue.issues.some(selected => selected.id === item.id) && (
                                        <div className='issue-list'>
                                        <h5 onClick={() => onSelectIssue(item, 'add')}>{item.name}</h5>
                                        </div>
                                    )
                                    )}
                                </Paper>
                            </div>
                            
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setSelectedIssue({modal:false, issues:[]})} color="primary" variant='outlined' size='small'>Cancel</Button>
                        { selectedIssue.issues.length ?
                            <Button disabled={isLoading} onClick={() => onAssociateIssue()} color="primary" variant='contained' size='small'>
                                {isLoading ? <CircularProgress size={20} /> : 'Link'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null
            }
        </Fragment>
    )
}
export default withRouter(TaskIssues);