import React, { useState } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { onCheckFormPermission } from '../../../utils_v2';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { TextField } from '@material-ui/core';
import { trackActivity } from '../../../utils/mixpanel';
const SortableItem = SortableElement((props) => {
	const [desc, setDesc] = useState(props?.item?.description||'');	
	return(
		<li className="list-inline-item sort-picture-item inspection-picture" style={{width: '225px', height:'246px', zIndex:9, marginBottom:'20px'}}>
			<div className="dropped-pic-card" style={{backgroundSize:'cover',backgroundImage: "url(" + props.item.image + ")"}}>
				<div className="dropped-picture-cta" style={{border:'1px solid #d7d7d7', background:'#fff'}}>
					<span onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.updateSlider();}}>
						<VisibilityIcon color="primary" fontSize='small' onClick={() => trackActivity('Project Management', {page_title: 'Inspection Pictures', event_type: 'Item Clicked', event_desc:'Clicked on View Image Icon', form_info: props?.formInfo})}/>
					</span>
					{onCheckFormPermission(props.formInfo) ?
						<>
							<span onClick={() => props.onEditInspection()}>
								<EditIcon color="primary" fontSize='small' onClick={() => trackActivity('Project Management', {page_title: 'Inspection Pictures', event_type: 'Item Clicked', event_desc:'Clicked on Edit Image Icon', form_info: props?.formInfo})}/>
							</span>
							<span onClick={() => props.onRemoveInspection()}>
								<DeleteOutlineIcon color="secondary" fontSize='small' onClick={() => trackActivity('Project Management', {page_title: 'Inspection Pictures', event_type: 'Item Clicked', event_desc:'Clicked on Delete Image Icon', form_info: props?.formInfo})}/>
							</span>
						</>:null
					}
				</div>
			</div>
			<div className="">
				<TextField
					style={{border:'none'}}
					id="description"
					fullWidth
					margin="none"
					inputProps={{maxLength: 255}}
					InputLabelProps={{shrink: true}}
					value={desc}
					variant='outlined'
					onChange={(e) => setDesc(e.target.value)}
					onBlur={(e) => props.onComponentChange(e.target.value)}
				/>
			</div>
		</li>
	)
});

const SortableList = SortableContainer(({items, onRemoveInspection, onEditInspection, updateSlider, formInfo, pageTitle, onComponentChange}) => {
  return (
    <div>
		<ul className="list-inline inspection-picture-list">
		{items.map((item, index) => (
			<SortableItem
				formInfo={formInfo}
				key={`item-${item.id}`}
				index={index}
				item={item}
				updateSlider={() => updateSlider(index)}
				onRemoveInspection={() => onRemoveInspection(item)}
				onEditInspection={() => onEditInspection(item)}
				pageTitle={pageTitle}
				onComponentChange={(value) => onComponentChange(value, item.id)}
			/>
		))}
		</ul>
	</div>
  );
});
const InspectionPictures = (props) => {
	return(
		<SortableList
			formInfo={props.formInfo}
			axis="xy"
			distance={1}
			items={props.items}
			onSortEnd={props.onSortEnd}
			updateSlider={(index) => props.updateSlider(index)}
			onRemoveInspection={(item) => props.onRemoveInspection(item)}
			onEditInspection={(item) => props.onEditInspection(item)}
			onComponentChange={props.onComponentChange}
		/>
	)
}
export default InspectionPictures;
