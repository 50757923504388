import React from 'react'
import { Link } from 'react-router'
import { imgStoragePath } from '../../../constants'
import { getLocalStorageInfo } from '../../../utils_v2'

const Logo = () => {
     const userInfo = getLocalStorageInfo()
  return (
    <div>
      <Link to={userInfo?.user?.user_role !== 'lessee' ? '/' : '/technical/projects'} >
        <img width="32" className="main-logo" src={imgStoragePath + "sparta_logo.png"} alt="logo" />
      </Link>
  </div>
  )
}

export default Logo