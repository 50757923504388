import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
export default function DropDownComp({label, value, keyParam, options, disabled, onFieldChange}){
  return(
    <Autocomplete
      options = {options}
      getOptionLabel={option => option.label}
      id={keyParam}
      value={value}
      onChange={(e, value) => onFieldChange(e, keyParam, value ? value.value:'')}
      disabled={disabled}
      renderInput={params => <TextField  {...params} label={label} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
    />
  )
}
