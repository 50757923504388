import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { browserHistory } from 'react-router';
import { Paper, Grid, Button } from "@material-ui/core";
import { TaskManagementWrapper, IssueList, AddIssue} from "../components";
import { TableListComp, FilterUIComp, ExportManu, EmptyCollection, PageLoader, DeletePopUp } from "../../../shared_elements";
import { STableLoader } from "../../../shared_elements/loaders";
import { issueHd, issuesFilterOps } from "..";
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, onCheckPermission, downloadFileType, getLocalStorageInfo, removeEmptyKey } from "../../../utils_v2";
import moment, { updateLocale } from "moment";
import FilterComponent from '../../../shared_elements/filter_component';
import { trackActivity } from '../../../utils/mixpanel';
import { getProjectWorkOrdersApi, getWorkOrderFormsApi, getAllStagesApi, getAllMilestoneApi, getPlatformConstantsApi, getAllFormsApi } from "../apiService"
import RestrictedAccess from "../../../shared_elements/components/RestrictedAccess";
const queryString = require('query-string');
const Issues = ({params, location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [stages, setStages] = useState([]);
    const [milestones, setMilestone] = useState([]);
    const [workOrders, setWorkOrders] = useState([]);
    const [forms, setForms] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [issuesUsers, setIssuesUsers] = useState([]);
    const [deleteIssue, setDeleteIssue] = useState({modal:false, issue:null})
    const [addEditIssue, setAddEditIssue] = useState({modal:false, issue:null})
    const [sort, setSort] = useState("")
    const [sort_by, setSortBy] = useState("")
    const [filter, setFilter] = useState({})
    const [noAcess, setNoAcess] = useState(false)
    const [issuesInfo, setIssuesInfo] = useState({ pagination: {}, list: []})
    useEffect(() => {
        getIssuesData({ per_page: 10, ...location.query }, "skeletonLoader")
        getIssuesUsers({role:'issue_users'});
        getProjectWorkOrders();
        getAllStages();
        getAllMilestone();
        getPlatformConstants();
        getAllForms();
        getProjectDetails();
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'Task Management Issues',
          });
    }, []);
    const getProjectDetails = (loaderType) => {
      setLoading(true)
      globalGetService(`technical/project/${params.project_slug}/`)
      .then(res => {
        setLoading(false)
          if(res.data.statusCode == 404){
              setNoAcess(true)
          }
      })
  }
    const getIssuesData = (query = {}, loaderType) => {
        if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true) }else{ setLoading(true) }
        globalGetService( `technical/project/${params.project_slug}/issue/`, query ).
        then((response) => {
            if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false) }else{ setLoading(false) }
            if (checkApiStatus(response)) {
                delete query.sort
                delete query.sort_by
                delete query.page
                delete query.per_page
                setIssuesInfo(response.data.data)
                setFilter(query)
            }
        })
    }
    const getIssuesUsers = (query) => {
        globalGetService(`technical/users/`, {...query,project_slug:params.project_slug})
        .then(response => {
            if(checkApiStatus(response)){
                setIssuesUsers(response.data.data)
            }
        });
    }
    const getProjectWorkOrders = () => {
        getProjectWorkOrdersApi(params).then((response) => {
            if (checkApiStatus(response)) {
                setWorkOrders(response.data.data)
            }
        })
    }

    const getAllStages = () => {
        getAllStagesApi(params, {view:'all', fields:'id,name'}).then((response) => {
            if (checkApiStatus(response)) {
                setStages(response.data.data)
            }
        })
    }

    const getAllForms = () => {
        getAllFormsApi().then((response) => {
            if (checkApiStatus(response)) {
                setForms(response.data.data)
            }
        })
    }

    const getPlatformConstants = () => {
        getPlatformConstantsApi({constant_types:['priority','severity','issue_status']}).then((response) => {
            if (checkApiStatus(response)) {
              setPltConstants(response.data.data)
            }
        })
    }

    const getAllMilestone = () => {
        getAllMilestoneApi(params, {view:'all', fields:'id,name'}).then((response) => {
            if (checkApiStatus(response)) {
                setMilestone(response.data.data)
            }
        })
    }
    const onDeleteIssue = () => {
        setLoading(true)
        globalDeleteService( `technical/project/${params.project_slug}/issue/${deleteIssue.issue.id}/` )
        .then((response) => {
            setLoading(false)
          if (checkApiStatus(response)) {
            setDeleteIssue({modal:false, issue:null})
            enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
            getIssuesData({ per_page: 10 }, "pageLoader")
            trackActivity('Project Management', {
                event_type: 'Delete Issue Success',
                page_title: 'Task Management Delete Issue',
                project_slug: params.project_slug,
                issue_id: deleteIssue.issue.id ? deleteIssue.issue.id : '',
                response_msg: response.data.message
              })
          } else {
            enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, })
            trackActivity('Project Management', {
                event_type: 'Delete Issue Failed',
                page_title: 'Task Management Delete Issue',
                project_slug: params.project_slug,
                issue_id: deleteIssue.issue.id ? deleteIssue.issue.id : '',
                response_msg: response.data.message
              })
          }
        })
      }
      const exportIssuesApi = (query = {}, file) => {
        enqueueSnackbar('Issue Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalExportService(`technical/project/${params.project_slug}/issue/`, { ...query, download: file.extension, })
        .then((response) => {
          downloadFileType( response, `Issues_${getLocalStorageInfo().defaultLessor.name}_${moment().format( "YYYY-MM-DD" )}.`, file.extension )
          closeSnackbar();
          enqueueSnackbar('Issue Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}});
          trackActivity('Project Management', {
            event_type: 'Export Issues Success',
            page_title: 'Task Management Export Issue',
            project_slug: params.project_slug,
            file_extension: file.extension ? file.extension : '',
            file_name: `Issues_${getLocalStorageInfo().defaultLessor.name}_${moment().format( "YYYY-MM-DD" )}.` ? `Issues_${getLocalStorageInfo().defaultLessor.name}_${moment().format( "YYYY-MM-DD" )}.` : '',
            response_msg: response.data.message
          })
        })
      }
    const createSortHandler = (sortField) => {
        setSort(sortField)
        setSortBy(sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc")
        if (sortField === sort) {
          if (sort_by === "asc") {
            getIssuesData( { ...filter, sort: sortField, sort_by: "desc", per_page: issuesInfo.pagination.per_page, }, "pageLoader" )
          } else {
            getIssuesData( { ...filter, sort: sortField, sort_by: "asc", per_page: issuesInfo.pagination.per_page, }, "pageLoader" )
          }
        } else {
            getIssuesData( { ...filter, sort: sortField, sort_by: "asc", per_page: issuesInfo.pagination.per_page, }, "pageLoader" )
        }
    }
    let filterOptions = {
        ...issuesFilterOps,
        status: {
          ...issuesFilterOps.status,
          options: pltConstants.filter((item) => item.type === 'issue_status')
        },
        assign: {
          ...issuesFilterOps.assign,
          options: issuesUsers,
        },
        priority: {
          ...issuesFilterOps.priority,
          options: pltConstants.filter((item) => item.type === 'priority')
        },
        severity: {
          ...issuesFilterOps.severity,
          options: pltConstants.filter((item) => item.type === 'severity')
        },
        follower: {
          ...issuesFilterOps.follower,
          options: issuesUsers
        },
        work_order: {
          ...issuesFilterOps.work_order,
          options: workOrders
        },
        form: {
          ...issuesFilterOps.form,
          options: forms
        },
    }
    if(onCheckPermission('task_management','stages','R')){
      filterOptions = {
        ...filterOptions,
        project_stage: {
          ...issuesFilterOps.project_stage,
          options: stages
        }
      }
    }else{
      delete filterOptions.project_stage
    }

    if(onCheckPermission('task_management','milestone','R')){
      filterOptions = {
        ...filterOptions,
        project_milestone: {
          ...issuesFilterOps.project_milestone,
          options: milestones
        },
      }
    }else{
      delete filterOptions.project_milestone
    }
    return(
        <section className='projects-management-apps projects-management-section'>
          {
            noAcess ?<RestrictedAccess />:
            <TaskManagementWrapper>
                { skeletonLoader ? <STableLoader count={8} />:
                    <div style={{height:`${window.innerHeight-140}px`, overflow:'auto'}}>
                        <Paper square className="filter-cta">
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={12} sm={12} md={9}>
                                     <FilterComponent
                                        filter={filter}
                                        filterMenu={filterOptions}
                                        getResponseBack={(applyFilter) => {getIssuesData({...applyFilter, page:1, per_page:issuesInfo.pagination.per_page}, 'pageLoader'); browserHistory.push({...location,query:applyFilter})}}
                                     />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <ul className="list-inline cta-list">
                                        { onCheckPermission('task_management','issues','C') ?
                                            <li className="list-inline-item">
                                                <Button color="primary" variant="contained" size="small" onClick={() => setAddEditIssue({modal:true, issue:null})} > Add Issue </Button>
                                            </li>:null
                                        }
                                        { onCheckPermission('task_management','issues','EXP') ?
                                            <li className="list-inline-item">
                                                <ExportManu
                                                    disabled={issuesInfo.list.length === 0 ? true : false}
                                                    title="Export"
                                                    files={[{ title: "Excel", extension: "xls", key: "" }]}
                                                    exportReportFn={(file) => exportIssuesApi({download:file.extension, ...removeEmptyKey(filter)}, file)}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        {(() => {
                            let hds = issueHd;
                            if(!onCheckPermission('task_management','stages','R')){
                                hds = hds.filter(item => item.label !== 'Stages');
                            }
                            if(!onCheckPermission('task_management','milestone','R')){
                                hds = hds.filter(item => item.label !== 'Milestone');
                            }
                            return(
                                <TableListComp
                                    style={{height: !issuesInfo.list.length ? 'auto':`${window.innerHeight-235}px`,overflow:'auto'}}
                                    heads={hds}
                                    sort={sort}
                                    sort_by={sort_by}
                                    data={issuesInfo.list.map((item,index) =>
                                        <IssueList
                                            issuesInfo={issuesInfo}
                                            key={index}
                                            item={item}
                                            onEdit={() => setAddEditIssue({modal:true, issue:item})}
                                            onDelete={(item) => setDeleteIssue({modal:true,issue:item})}
                                            filter={filter}
                                        />
                                    )}
                                    noRecord={ issuesInfo.list.length ? null : <EmptyCollection title="No records found" /> }
                                    pagination={issuesInfo.pagination}
                                    onChangePage={(event, newPage) => getIssuesData( { ...filter, page: newPage + 1, per_page: issuesInfo.pagination.per_page, }, "pageLoader" ) }
                                    onChangeRowsPerPage={(event) => getIssuesData( { ...filter, page: 1, per_page: event.target.value, }, "pageLoader" ) }
                                    createSortHandler={createSortHandler}
                                />
                            )
                        })()}
                    </div>
                }
            </TaskManagementWrapper>}
            { addEditIssue.modal ?
                <AddIssue
                    toggleModalFn={() => setAddEditIssue({modal:false, issue:null})}
                    getResponseBack={() => getIssuesData({per_page:10}, 'pageLoader')}
                    milestones={milestones}
                    workOrders={workOrders}
                    stages={stages}
                    issuesUsers={issuesUsers}
                    pltConstants={pltConstants}
                    addEditIssue={addEditIssue}
                />: null
            }
            {deleteIssue.modal ? (
                <DeletePopUp
                    modal={deleteIssue.modal}
                    toggleModalFn={() => setDeleteIssue({modal:false, issue:null})}
                    title="Delete Issue"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteIssue()}
                    confirmText="Delete"
                    isLoading={isLoading}
                />
            ) : null}
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default Issues;
