import React, { useState } from 'react';
import { Table, TableBody, Paper, Grid, TextField, TableRow, TableCell } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CkEditorText } from '../../../shared_elements';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { onCheckFormPermission } from '../../../utils_v2';
import MomentUtils from '@date-io/moment';
import { displayDateFormatShort, backendDateFormat } from '../../../constants';

function EngineStand({ data, setData, onFieldChange, callthisapi, currentFormDetail, setInitialValue, isLoading }) {

    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5>Shop Visit Information :</h5>
                <h6 style={{ margin: '10px 0px 10px' }}>Lessee removal plan for next shop visit :</h6>
                <div>
                    <CkEditorText
                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                        htmlData={data?.engine_condition_stands?.lessee_removal_plan_next_shop_visit || ''}
                        onBlurData={() => callthisapi('', data?.engine_condition_stands?.lessee_removal_plan_next_shop_visit)}
                        onFocusData={(e, value) => setInitialValue(e, value)}
                        onChangeData={(value) => setData({
                            ...data,
                            engine_condition_stands: {
                                ...data.engine_condition_stands,
                                lessee_removal_plan_next_shop_visit: value
                            }
                        })}
                    />
                </div>
                <h6 style={{ margin: '20px 0px 10px' }}>The Lessee uses the following MRO facilities :</h6>
                <div>
                    <CkEditorText
                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                        htmlData={data?.engine_condition_stands?.lessee_uses_mro_facilities || ''}
                        onBlurData={() => callthisapi('', data?.engine_condition_stands?.lessee_uses_mro_facilities)}
                        onFocusData={(e, value) => setInitialValue(e, value)}
                        onChangeData={(value) => setData({
                            ...data,
                            engine_condition_stands: {
                                ...data.engine_condition_stands,
                                lessee_uses_mro_facilities: value
                            }
                        })}
                    />
                </div>
                <h6 style={{ margin: '20px 0px 10px' }}>Shop Visit Drivers in Fleet & Intervals :</h6>
                <div>
                    <CkEditorText
                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                        htmlData={data?.engine_condition_stands?.shop_visit_drivers_fleet_interals || ''}
                        onBlurData={() => callthisapi('', data?.engine_condition_stands?.shop_visit_drivers_fleet_interals)}
                        onFocusData={(e, value) => setInitialValue(e, value)}
                        onChangeData={(value) => setData({
                            ...data,
                            engine_condition_stands: {
                                ...data.engine_condition_stands,
                                shop_visit_drivers_fleet_interals: value
                            }
                        })}
                    />
                </div>
                <h6 style={{ margin: '20px 0px 10px' }}>Shop Visit Other Info (FHA / FMP, PAYG / PASV, Workscoping, Estimated Costs etc) :</h6>
                <div>
                    <CkEditorText
                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                        htmlData={data?.engine_condition_stands?.shop_visit_other_information || ''}
                        onBlurData={() => callthisapi('', data?.engine_condition_stands?.shop_visit_other_information)}
                        onFocusData={(e, value) => setInitialValue(e, value)}
                        onChangeData={(value) => setData({
                            ...data,
                            engine_condition_stands: {
                                ...data.engine_condition_stands,
                                shop_visit_other_information: value
                            }
                        })}
                    />
                </div>
            </Paper>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5>Engine Stand Information :</h5>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <span>Engine serial number </span>
                        <TextField
                            variant='outlined'
                            id='esn'
                            placeholder="Enter..."
                            size="small"
                            disabled
                            value={currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.msn : currentFormDetail?.asset?.esn}
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['was', 'was not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '125px' }}
                            id="delivered"
                            value={['was', 'was not'].filter(item => item === data?.engine_condition_stands?.stand_details?.delivered)[0] || null}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'delivered', value, 'engine_condition_stands', null, null, 'stand_details') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>delivered with an engine transportation stand.</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span>During the inspection </span>
                        <TextField
                            variant='outlined'
                            id='lessee_id'
                            placeholder="Enter..."
                            size="small"
                            disabled
                            value={data?.lessee_id || ''}
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['were', 'were not', 'N/A']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '135px' }}
                            id="inspection_aware"
                            value={['were', 'were not', 'N/A'].filter(item => item === data?.engine_condition_stands?.stand_details?.inspection_aware)[0] || null}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'inspection_aware', value, 'engine_condition_stands', null, null, 'stand_details') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>aware of the stand location and confirmed it was being stored at.</span>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            placeholder="Enter..."
                            size="small"
                            id='stand_location'
                            inputProps={{ maxLength: 50 }}
                            value={data?.engine_condition_stands?.stand_details?.stand_location || ''}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'stand_location', e.target.value, 'engine_condition_stands', null, null, 'stand_details') }}
                        />
                        <span className='span-style'>Which </span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['is', 'is not',]}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '110px' }}
                            value={['is', 'is not',].filter(item => item === data?.engine_condition_stands?.stand_details?.facility_owned)[0] || null}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value) }}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'facility_owned', value, 'engine_condition_stands', null, null, 'stand_details') }}
                            id="facility_owned"
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>a facility owned and managed by the lessee. </span>
                    </Grid>
                </Grid>
                <h5 style={{ margin: '10px 0px 5px' }}>Stand details are as follows :</h5>
                <Table className='mui-table-format-t015'>
                    <TableBody>
                        <TableRow>
                            <TableCell><h6>Engine Stand Manufacturer</h6></TableCell>
                            <TableCell >
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={data?.engine_condition_stands?.stand_manufacturer || ''}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'stand_manufacturer', e.target.value, 'engine_condition_stands') }}
                                />
                            </TableCell>
                            <TableCell><h6>Stand Part Number(s)</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={data?.engine_condition_stands?.stand_part_numbers || ''}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'stand_part_numbers', e.target.value, 'engine_condition_stands') }}
                                />
                            </TableCell>
                            <TableCell><h6>Stand Serial Number(s)</h6></TableCell>
                            <TableCell>
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={data?.engine_condition_stands?.stand_serial_numbers || ''}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'stand_serial_numbers', e.target.value, 'engine_condition_stands') }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><h6>Shock Mount Expiry Date</h6></TableCell>
                            <TableCell >
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        id="shock_mount_expiry_date"
                                        format={displayDateFormatShort}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        value={data?.engine_condition_stands?.shock_mount_expiry_date || null}
                                        onChange={(date) => onFieldChange('', 'shock_mount_expiry_date', date ? moment(date).format(backendDateFormat) : null, 'engine_condition_stands', '', 'date')}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell><h6> Stand Colour</h6></TableCell>
                            <TableCell >
                                <TextField
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    variant='outlined'
                                    placeholder="Enter.."
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={data?.engine_condition_stands?.stand_color || ''}
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value) }}
                                    onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'stand_color', e.target.value, 'engine_condition_stands') }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <p style={{ margin: '20px 0px 10px' }}>General comments on Stand Management :</p>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details, if no please enter.. "N/A"'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.engine_condition_stands?.stand_general_comments || ''}
                                onBlurData={() => callthisapi('', data?.engine_condition_stands?.stand_general_comments)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    engine_condition_stands: {
                                        ...data.engine_condition_stands,
                                        stand_general_comments: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </section>
    );
}

export default EngineStand