import React from 'react'
import { getLocalStorageInfo } from '../../../utils_v2';
import { browserHistory } from 'react-router';
import { imgStoragePath } from '../../../constants';
import AircraftBluePrints from './AircraftBluePrints';

const AircraftComponent = ({props, fetchData, onSectionClick }) => {
  let customClass = '';
  if (fetchData?.findingSection && Object.keys(fetchData?.findingSection).length && fetchData?.findingSection?.list?.length) {
    switch (fetchData?.findingSection?.aircraft_model?.blueprint?.id) {
      case 1:
        customClass = 'model-atr'
        break;
      case 2:
        customClass = 'model-three'
        break;
      case 3:
        customClass = 'model-four'
        break;
      case 4:
        customClass = 'model-eight'
        break;
      case 5:
        customClass = 'model-eight'
        break;
      case 6:
        customClass = 'model-one'
        break;
      case 7:
        customClass = 'model-one'
        break;
      case 8:
        customClass = 'model-nine'
        break;
      case 9:
        customClass = 'model-nine'
        break;
      case 10:
        customClass = 'model-seven'
        break;
      case 11:
        customClass = 'model-eleven'
        break;
      case 12:
        customClass = 'model-s2000'
        break;
      case 13:
        customClass = 'model-s2000'
        break;
      default:
        customClass = 'model-one'
    }
  }
  
  return (
    <div className="form-t004-cn">
      {getLocalStorageInfo()?.user?.account_type === 'Acumen' && fetchData?.currentFormDetail?.asset?.asset_type === 1 && (fetchData?.currentFormDetail?.asset?.aircraft_type === 'A320' || fetchData?.currentFormDetail?.asset?.aircraft_type === 'A320-CEO') ?
        <span onClick={() => browserHistory.push(`/${props.params.type}/${props.params.aircraft_slug}/technical/${props.params.workOrderSlug}/forms/${props.params.formSlug}/v_t007`)} style={{ position: 'fixed', top: '122px', left: '84px', border: '1px solid #fff', padding: '5px 10px', borderRadius: '5px', color: '#fff', cursor: 'pointer' }}>Switch To 3D View</span> : null
      }

      <div className="indicater-detail-block">
        {fetchData?.findingSection && Object.keys(fetchData?.findingSection)?.length && fetchData?.findingSection?.list?.length ?
          <ul className="list-unstyled indicater-block">
            <li>
              <span className="poor"></span> Findings ({fetchData?.findingSection && fetchData?.findingSection['list'].reduce((total, sectionList) => total + sectionList?.finding_count, 0)})
            </li>
          </ul>
          : null
        }
      </div>
      <div className={customClass} >
        {fetchData?.findingSection && Object.keys(fetchData?.findingSection)?.length && fetchData?.findingSection?.list?.length ?
          <div className="model-image" >
            {console.log(fetchData?.findingSection['list'])}
            <img className="model-image" src={imgStoragePath + fetchData?.findingSection?.aircraft_model?.blueprint?.image} alt="img" />
            {fetchData?.findingSection && fetchData?.findingSection['list']?.map((item, index) => {
              console.log("item",item);
              return <AircraftBluePrints
                bluePrintId={fetchData?.findingSection?.aircraft_model?.blueprint?.id}
                model={fetchData?.findingSection?.aircraft_model}
                auditSectionInfo={() => onSectionClick(item)}
                key={index}
                sectionDetail={item}
                formInfo={fetchData?.currentFormDetail}
              />
            }

            )}
          </div>
          : null
        }
      </div>
    </div>
  )
}

export default AircraftComponent