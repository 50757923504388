import React, { useState } from 'react'
import {   Grid,  } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import PreviewFileModal from './FilePreviewModal';
import { DialogueWrapper } from '../shared';
import { PageLoader } from '../../../shared_elements';
import { globalPostService } from '../../../utils_v2/globalApiServices';

export const AttachmentDialogue = ({attachment, setAttachment,loaderType, toggleModalFn, onAddAttachment }) => {
    const [isLoading, setLoading] = useState({ isPage: false, isButton: false });
    const [previewFile, setPreviewFile] = useState({ modal: false, data: {} });
   
    const handleChipClick = (event) => {
        setLoading({ ...isLoading, isPage: true })
        if (event.endsWith('.xlsx') || event.endsWith('.xls')||event.endsWith('.doc') ||event.endsWith('.docx')   ) {
            let formData = new FormData();
            formData.append('file', attachment?.files?.find(item => item?.path === event));
            globalPostService(`camo/preview/file/`, formData)
                .then(response => {
                    if (response?.status === 200 && response?.statusText === "OK") {
                        setLoading({ ...isLoading, isPage: false })
                        setPreviewFile({ modal: true, data: response?.data, })
                    }
                });
        }
         else {
            setLoading({ ...isLoading, isPage: false })
            setPreviewFile({ modal: true, data: [attachment?.files?.find(item => item?.path === event)], singlePreview: true })
        }
    };
    const handlePreviewClose = () => { setPreviewFile({ ...previewFile, modal: false }) }
    return (
        <>
            <DialogueWrapper open={attachment.modal}  toggleModalFn={toggleModalFn} isLoading={loaderType?.buttonLoader} dialogueTitle={`${attachment?.component?.name ? `Add Attachment for ${attachment?.component?.name}`:"Add Attachment"}`} handleSave={onAddAttachment} dialogueWidth="400px" buttonLabel="Add">
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <div>
                            <DropzoneArea
                                acceptedFiles={["image/*",".xlsx",".csv", "application/xls", ",application/excel", , ",application/vnd.ms-excel", 'application/pdf',".doc", ".docx"]}
                                filesLimit={1}
                                onDelete={() => setAttachment({...attachment,files : null})}
                                maxFileSize={104857600}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={true}
                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /></p>}
                                dropzoneClass="drag-drop-cnt"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                clearOnUnmount={true}
                                previewChipProps={{
                                    onClick: (event) => {
                                        handleChipClick(event?.target.textContent);
                                    }
                                }}
                                // onDrop={(files) => uploadEquipmentAttachmentApi(attachment?.sectionId, attachment?.componentId, files[0])}
                                onDrop={(files) => { setAttachment({ ...attachment, 'files': files,[attachment.component.name] : files }); }}
                            />
                           {(attachment?.files === null ||attachment?.files?.length === 0) &&  <p><span style={{background:'#ffe160', fontSize:'11px', padding:'2px 3px'}}><span style={{fontWeight: 500}}>Format Support :</span>.jpg, .jpeg, .png,.pdf, .xls, .xlsx, .doc, .docx, .csv(Only 1 File.) </span></p>}
                        </div>
                      
                    </Grid>
                </Grid>
            </DialogueWrapper>
            <PreviewFileModal previewFile={previewFile}toggleModalFn={handlePreviewClose} />
            {loaderType?.pageLoader ? <PageLoader /> : null}
        </>
    )
}


