import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router'
import { EngineInformation, TechnicalRecords, EngineCondition, EngineStand, PowerPlant, FleetComposition, TechnicalSummary } from '../components';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { FormSkeletonLoader, FormWrapper } from '../../Elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import moment from 'moment';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
import { assetTypeId } from '../../../constants';
import { STableLoader } from '../../../shared_elements';

const LeaseInsecpectionReport = ({ params }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [skeltonLoader, setSkeltonLoader] = useState(true);
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [data, setData] = useState(null)
    const [isLoading, setLoading] = useState(false);
    const [basicDropdown, setBasicDropdown] = useState({})
    const [usersList, setUsersList] = useState([]);
    const [initialValue, setInitialValue] = useState('');


    const onFieldChange = (e, key, value, extraParam, index, date, nestedParam, nestedIndex) => {
        if (key === 'inspection_date') {
            setData({ ...data, [key]: value });
            callthisapi({ ...data, [key]: value })
        } else if (nestedParam === 'next_sv_date' && date) {
            const updatedArray = data[extraParam] ? [...data[extraParam][nestedParam]] : [];
            updatedArray[index] = { ...(updatedArray[index] || {}), [key]: value };
            callthisapi({
                ...data, [extraParam]: { ...data[extraParam], [nestedParam]: updatedArray }
            })

        } else {
            setData(prev => {
                if (extraParam === 'key_attendees' || nestedParam === 'tech_open_action' || nestedParam === 'powerplant_management' || nestedParam === 'powerplant_maintenance' || nestedParam === 'power_fleet_composition' || nestedParam === 'next_sv_date') {
                    if (nestedParam === 'tech_open_action' || nestedParam === 'powerplant_maintenance' || nestedParam === 'powerplant_management' || nestedParam === 'power_fleet_composition' || nestedParam === 'next_sv_date') {
                        if (nestedParam === 'powerplant_maintenance') {
                            if (key === 'description') {
                                const updatedArray = prev[extraParam] ? [...prev[extraParam][nestedParam]] : [];
                                updatedArray[index] = { ...(updatedArray[index] || {}), [key]: value };
                                return { ...prev, [extraParam]: { ...prev[extraParam], [nestedParam]: updatedArray } };
                            } else {
                                const updatedModules = prev[extraParam][nestedParam].map((module, moduleIndex) => {
                                    if (moduleIndex === nestedIndex) {
                                        const updatedKeyValues = module.key_values.map((kv, kvIndex) => {
                                            if (kvIndex === index) {
                                                return { ...kv, [key]: value };
                                            }
                                            return kv;
                                        });
                                        return { ...module, key_values: updatedKeyValues };
                                    }
                                    return module;
                                });
                                return { ...prev, [extraParam]: { ...prev[extraParam], [nestedParam]: updatedModules } };
                            }
                        } else {
                            const updatedArray = prev[extraParam] ? [...prev[extraParam][nestedParam]] : [];
                            updatedArray[index] = { ...(updatedArray[index] || {}), [key]: value };
                            return { ...prev, [extraParam]: { ...prev[extraParam], [nestedParam]: updatedArray } }
                        }
                    } else {
                        const updatedArray = prev.key_attendees ? [...prev.key_attendees] : [];
                        updatedArray[index] = { ...(updatedArray[index] || {}), [key]: value };
                        return { ...prev, key_attendees: updatedArray };
                    }
                } else {
                    if (extraParam) {
                        if (extraParam && date) {
                            callthisapi({ ...prev, [extraParam]: { ...prev[extraParam], [key]: value } })
                        }
                        if (nestedParam) {
                            return {
                                ...prev,
                                [extraParam]: { ...prev[extraParam], [nestedParam]: { ...prev[extraParam]?.[nestedParam] || {}, [key]: value } }
                            };
                        }
                        return { ...prev, [extraParam]: { ...prev[extraParam], [key]: value }, };
                    }
                    return { ...prev, [key]: value };
                }
            });
        }
    };

    useEffect(() => {
        getCurrentFormDetail('skeltonLoader');
        getDropdown()
        getEngineFormsdata('skeltonLoader')
        getUsersListFn()
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T015 Form'
        });
    }, []);

  

    const getUsersListFn = () => {
        globalGetService('camo/camo_user_list/')
            .then(response => {
                if (checkApiStatus(response)) {
                    setUsersList(response.data.data)
                }
            })
    }

    const getDropdown = () => {
        globalGetService(`/technical/t015/basic_dropdown/`)
            .then(response => {
                if (response?.status == 200) {
                    setBasicDropdown(response?.data)
                }
            })
    }

    const callthisapi = (itemData, neValue) => {
        setLoading(true)
        let payload = Object.assign({}, itemData ? itemData : data)
        // const keyAtendeeValue = payload?.key_attendees && payload?.key_attendees.filter(item => !item?.id)[0]
        // const atndeeAnyValue = keyAtendeeValue ? Object.values(keyAtendeeValue).some(value => value !== null) : []
        delete payload?.lessor_id
        delete payload?.work_order
        delete payload?.engine_condition_stands?.fh_fc_ratio 
        delete payload?.engine_condition_stands?.expected_fh_interval?.achieve_fhs 
        delete payload?.engine_condition_stands?.expected_fh_interval?.interval_fhs
        payload = {
            ...payload,
            serial_number: currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.msn : currentFormDetail?.asset?.esn,
            model_id: currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.aircraft_type : currentFormDetail?.asset?.engine_type,
            technical_records: {
                ...payload?.technical_records,
                tsn: payload?.technical_records?.tsn ? parseFloat(payload?.technical_records?.tsn) : null,
                csn: payload?.technical_records?.csn ? parseInt(payload?.technical_records?.csn) : null,
            },
            engine_condition_stands: {
                ...payload?.engine_condition_stands,
                stand_color: payload?.engine_condition_stands?.stand_color || null,
                avg_fh: payload?.engine_condition_stands?.avg_fh ? parseFloat(payload?.engine_condition_stands?.avg_fh) : null,
                avg_fc: payload?.engine_condition_stands?.avg_fc ? parseInt(payload?.engine_condition_stands?.avg_fc) : null,
                csl_hsv: payload?.engine_condition_stands?.csl_hsv ? parseInt(payload?.engine_condition_stands?.csl_hsv) : null,
                avg_derate_percentage: payload?.engine_condition_stands?.avg_derate_percentage ? parseFloat(payload?.engine_condition_stands?.avg_derate_percentage) : null,
                tsl_hsv: payload?.engine_condition_stands?.tsl_hsv ? parseFloat(payload?.engine_condition_stands?.tsl_hsv) : null,
                expected_fh_interval: {
                    ...payload?.engine_condition_stands?.expected_fh_interval,
                    thrust_is: payload?.engine_condition_stands?.expected_fh_interval?.thrust_is ? parseInt(payload?.engine_condition_stands?.expected_fh_interval?.thrust_is) : null,
                    trending_at: payload?.engine_condition_stands?.expected_fh_interval?.trending_at >= 0 || '0'  ? parseFloat(payload?.engine_condition_stands?.expected_fh_interval?.trending_at) : null,
                    average_of: payload?.engine_condition_stands?.expected_fh_interval?.average_of  >= 0 || '0' ? parseFloat(payload?.engine_condition_stands?.expected_fh_interval?.average_of) : null,
                },
                expected_fc_interval: {
                    ...payload?.engine_condition_stands?.expected_fc_interval,
                    remaining_at: parseInt(payload?.current_tr) || null,
                    engine_fcs: payload?.engine_condition_stands?.expected_fc_interval?.engine_fcs ? parseInt(payload?.engine_condition_stands?.expected_fc_interval?.engine_fcs) : null,
                    fcs_remaining: payload?.engine_condition_stands?.expected_fc_interval?.fcs_remaining ? parseInt(payload?.engine_condition_stands?.expected_fc_interval?.fcs_remaining) : null,
                    removal_fcs: payload?.engine_condition_stands?.expected_fc_interval?.removal_fcs ? parseInt(payload?.engine_condition_stands?.expected_fc_interval?.removal_fcs) : null,
                }
            },
            // key_attendees: atndeeAnyValue ? payload?.key_attendees : payload?.key_attendees?.length && payload?.key_attendees?.filter(item => item?.id) || []
        }

        if (neValue !== initialValue) {
            globalPostService(`/technical/t015/basic-inspection/${params?.workOrderSlug}/forms/${params?.formSlug}/review/${assetTypeId[params?.type]}/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getEngineFormsdata()
                        setLoading(false)
                        getCurrentFormDetail();
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        setLoading(false)
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

                    }
                });
        } else {
            setLoading(false)
        }
    }

    const exportFormReport = (file) => {
        enqueueSnackbar('T015 Report Downloading ...', { variant: 'info', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } })
        globalStaticExportService(`/technical/t015/basic-inspection/${params?.workOrderSlug}/forms/${params?.formSlug}/export/${assetTypeId[params?.type]}/`, { download: file.extension, options: file.key })
            .then(response => {
                downloadFileType(response, (`T015_${getLocalStorageInfo().defaultLessor?.name}_${moment().format('YYYY-MM-DD')}.`), file?.extension);
                closeSnackbar();
                trackActivity('Project Management', {
                    event_type: 'File Exported',
                    page_title: 'T015 Form',
                    workOrderSlug: params?.workOrderSlug ? params.workOrderSlug : '',
                    file_name: `T015_${getLocalStorageInfo().defaultLessor?.name}_${moment().format('YYYY-MM-DD')}`,
                    file_extension: file?.extension,
                    success_msg: 'T015 Report Downloaded'
                })
            });
    }

    const getEngineFormsdata = (loaderType) => {
        if (loaderType) { setSkeltonLoader(true) }
        globalGetService(`/technical/t015/basic-inspection/${params?.workOrderSlug}/forms/${params?.formSlug}/review/${assetTypeId[params?.type]}/`)
            .then(response => {
                if (loaderType) { setSkeltonLoader(false) }
                if (checkApiStatus(response)) {
                    setData(response.data.data || {});
                } else {
                    setData({});
                }
            })
            .catch(() => {
                setData({});
                if (loaderType) { setSkeltonLoader(false) }
            });
    }

    const getCurrentFormDetail = (loaderType) => {
        if (loaderType) { setSkeltonLoader(true) }
        globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' : 'workorder'}/${params?.workOrderSlug}/forms/?asset_type=${params?.type}`, { form: params.formSlug, fields: 'id,name,project_number,start_date,expected_end_date,slug' })
            .then(response => {
                if (loaderType) { setSkeltonLoader(false) }
                if (checkApiStatus(response)) {
                    setCurrentFormDetail(response?.data?.data)
                }
            })
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params?.workOrderSlug}/form/${params?.formSlug}/status-update/?asset_type=${params?.type}`, data)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getCurrentFormDetail('formLoader');
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    trackActivity('Project Management', {
                        event_type: 'Form Status Updated',
                        page_title: 'T015 Form',
                        form_slug: params?.formSlug || '',
                        workOrder_slug: params?.workOrderSlug || '',
                        status: data?.status ? data?.status : '',
                        request_body: data || '',
                        success_msg: response?.data?.message
                    });
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    trackActivity('Project Management', {
                        event_type: 'Form Status Update Failed',
                        page_title: 'T014 Form',
                        form_slug: params?.formSlug || '',
                        workOrder_slug: params?.workOrderSlug || '',
                        status: data?.status || '',
                        request_body: data || '',
                        error_source: 'Backend',
                        error_msg: response?.data?.message
                    });
                }
            });
    }

    let formViewPermission = currentFormDetail?.list?.length && currentFormDetail?.list?.find(form => form.slug == currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(currentFormDetail?.current_form?.name)

    return (
        <section className='project-forms-section'>
            {currentFormDetail && Object.keys(currentFormDetail).length && formViewPermission && formType ?
                <FormWrapper
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true : false}
                    formInfo={currentFormDetail}
                    exportReportBtn={<ExportManu disabled={currentFormDetail?.current_form.progress === 0 ? true : false} exportReportFn={(file) => exportFormReport(file)} title="Export Report" files={[{ title: 'PDF', extension: 'pdf', key: '' }]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}

                /> : skeltonLoader ? <FormSkeletonLoader /> : null
            }
            {skeltonLoader ? <STableLoader count={5} /> :
                data ? (<>
                    <EngineInformation
                        data={data}
                        setData={setData}
                        setInitialValue={setInitialValue}
                        usersList={usersList}
                        basicDropdown={basicDropdown}
                        onFieldChange={onFieldChange}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        getResponseBack={() => getEngineFormsdata()}
                        currentFormDetail={currentFormDetail}
                        setLoading={setLoading}
                        isLoading={isLoading}
                    />
                    <TechnicalRecords
                        data={data}
                        setData={setData}
                        onFieldChange={onFieldChange}
                        setInitialValue={setInitialValue}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        getResponseBack={() => getEngineFormsdata()}
                        currentFormDetail={currentFormDetail}
                        isLoading={isLoading}
                    />
                    <EngineCondition
                        data={data}
                        setData={setData}
                        onFieldChange={onFieldChange}
                        setInitialValue={setInitialValue}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        getResponseBack={() => getEngineFormsdata()}
                        currentFormDetail={currentFormDetail}
                        setLoading={setLoading}
                        isLoading={isLoading}
                    />
                    <EngineStand
                        data={data}
                        setData={setData}
                        onFieldChange={onFieldChange}
                        setInitialValue={setInitialValue}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        currentFormDetail={currentFormDetail}
                        setLoading={setLoading}
                        isLoading={isLoading}
                    />
                    <PowerPlant
                        data={data}
                        setData={setData}
                        onFieldChange={onFieldChange}
                        setInitialValue={setInitialValue}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        getResponseBack={() => getEngineFormsdata()}
                        currentFormDetail={currentFormDetail}
                        setLoading={setLoading}
                        isLoading={isLoading}
                    />
                    <FleetComposition
                        data={data}
                        setData={setData}
                        onFieldChange={onFieldChange}
                        setInitialValue={setInitialValue}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        getResponseBack={() => getEngineFormsdata()}
                        currentFormDetail={currentFormDetail}
                        setLoading={setLoading}
                        isLoading={isLoading}
                    />
                    <TechnicalSummary
                        data={data}
                        setData={setData}
                        onFieldChange={onFieldChange}
                        setInitialValue={setInitialValue}
                        callthisapi={(item, neValue) => callthisapi(item, neValue)}
                        getResponseBack={() => getEngineFormsdata()}
                        currentFormDetail={currentFormDetail}
                        setLoading={setLoading}
                        isLoading={isLoading}
                    />
                </>
                ) : null
            }
            {isLoading ? <PageLoader /> : null}

        </section>
    )
}

export default withRouter(LeaseInsecpectionReport)