import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../../../globalServices/';
import { toastFlashMessage, removeEmptyKey, downloadFileType } from '../../../../../utils';
import { contactObj } from '../../AddFormObjects';
import { browserHistory } from 'react-router';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';

export const contactListAc = (props, queryParam = {}) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/workorder/' + props.params.workOrderSlug + '/contacts/', queryParam)
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.CONTACT_LIST,
            payload: response.data.data
          });
        }
        dispatch(triggerLoader(false));
      });
  }
}

export const contactViewCrudAc = (props, contactCrud, id = null) => {
  return (dispatch) => {
    if (contactCrud.type == 'add') {
      dispatch({
        type: actions.TOGGLE_SIDE_BAR,
        payload: { type: 'add', data: contactObj, flag: contactCrud.flag }
      });
    } else {
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/' + props.params.workOrderSlug + '/contacts/' + contactCrud.data.id + '/', {})
        .then(response => {
          dispatch(triggerLoader(false));
          if (response.data.statusCode == 200) {
            trackActivity('Project Management', {
              form_name: 'T006',
              event_type: 'Contact Viewed',
              page_title: 'Contact List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              item_id: contactCrud.data.id ? contactCrud.data.id : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
            })
            dispatch({
              type: actions.TOGGLE_SIDE_BAR,
              payload: { flag: true, data: response.data.data, type: contactCrud.type }
            })
          }
        })
    }
  }
}
export const contactAddEditAc = (props, data, type) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if (type == 'add') {
      globalPostService('technical/workorder/' + props.params.workOrderSlug + '/contacts/', data)
        .then(response => {
          dispatch(triggerLoader(false));
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success');
            trackActivity('Project Management', {
              form_name: 'T006',
              event_type: 'Contact Added',
              page_title: 'Contact List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              name: data.full_name ? data.full_name : '',
              email: data.email ? data.email : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : ''
            })
            dispatch({
              type: actions.ADD_CONTACT,
              payload: response.data.data
            });
          } else if (response.data.statusCode === 4004) {
            toastFlashMessage(response.data.message, 'error');
            trackActivity('Project Management', {
              form_name: 'T006',
              event_type: 'Add Contact Failed',
              page_title: 'Contact List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : '',
              error_message: response.data.data ? response.data.data : '',
              error_source: 'Backend'
            })
          }
        })
    } else {
      globalPutService('technical/workorder/' + props.params.workOrderSlug + '/contacts/' + data.id + '/', data)
        .then(response => {
          dispatch(triggerLoader(false));
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success');
            dispatch({
              type: actions.UPDATE_CONTACT,
              payload: response.data.data
            });
            trackActivity('Project Management', {
              form_name: 'T006',
              event_type: 'Contact Edited',
              page_title: 'Contact List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              name: data.full_name ? data.full_name : '',
              email: data.email ? data.email : '',
              contact_id: data.id ? data.id : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : ''
            })
          } else {
            trackActivity('Project Management', {
              form_name: 'T006',
              event_type: 'Edit Contact Failed',
              page_title: 'Contact List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              name: data.full_name ? data.full_name : '',
              email: data.email ? data.email : '',
              contact_id: data.id ? data.id : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : '',
              error_message: response.data.data ? response.data.data : '',
              error_source: 'Backend'
            })
          }
        })
    }
    dispatch(getFormDetailsAc(props));
  }
}

export const deleteContactAc = (props, ids, type) => {
  let location = browserHistory.getCurrentLocation();
  location = {
    ...location,
    query: {
      page: 1
    }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalDeleteService('technical/workorder/' + props.params.workOrderSlug + '/contacts/', { ids: ids })
      .then(response => {
        dispatch(triggerLoader(false));
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Project Management', {
            form_name: 'T006',
            event_type: 'Contact Deleted',
            page_title: 'Contact List',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            contact_id: ids ? ids : '',
            type: type ? type : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
          })
          dispatch({
            type: actions.DELETE_CONTACT,
            payload: ids
          });
          if (type == 'bulk') {
            browserHistory.push(location);
            dispatch(contactListAc(props));
          }
        } else {
          trackActivity('Project Management', {
            form_name: 'T006',
            event_type: 'Contact Delete Failed',
            page_title: 'Contact List',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            contact_id: ids ? ids : '',
            type: type ? type : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
            error_message: response.data.data ? response.data.data : '',
            error_source: 'Backend'
          })
        }
        dispatch(getFormDetailsAc(props));
      })
  }
}

export const contactListExportAc = (props, fileType = null, name) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    let query = Object.assign({}, props.location.query);
    globalExportService('technical/workorder/' + props.params.workOrderSlug + '/contacts/', { download: fileType, ...query })
      .then(response => {
        dispatch(triggerLoader(false));
        downloadFileType(response, name + '.', fileType)
        trackActivity('Project Management', {
          form_name: 'T006',
          event_type: 'Report Exported',
          page_title: 'Contact List',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          file_type: fileType ? fileType : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
        })
      })
  }
}

export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_CONTACT_LOADER,
      payload: flag
    });
  }
}
