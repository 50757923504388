import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const findingObj = {
  // section:null,
  finding_type: '',
  damage_dimension: '',
  damage_limit:'',
  remarks:'',
  classifications:'',
  category:'',
  repair_type: '',
  arm_srm_reference:'',
  dd_wo_reference:'',
  corrective_actions:'',
  intervals:'',
  images : []
};

export const classifications = [
  {id:"Major",name:"Major"},
  {id:"Minor",name:"Minor"},
];
export const category =[
  {id:"A",name:"A"},
  {id:"B",name:"B"},
  {id:"C",name:"C"}
]
export const repairType = [
  {id:"Doubler",name:"Doubler"},
  {id:"Composite",name:"Composite"},
]
export const findingErrorCode = {
    section:{
      0:'',
      1:'Please select Aircraft Section'
    },
    sectionObj:{
      required:true
    },
    finding_type: {
      0: '',
      1:'Please select Damage Type'
    },
    finding_typeObj: {
      required: true
    },
    damage_dimension: {
      0:'',
      4: errorMsg1
    },
    damage_dimensionObj:{
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    damage_limit: {
      0:'',
      4: errorMsg1
    },
    damage_limitObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    remarks: {
      0: '',
      1: 'Please enter Description',
      4: errorMsg1
    },
    remarksObj: {
      required: true,
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    dd_wo_reference: {
      0:'',
      4: errorMsg1
    },
    dd_wo_referenceObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    arm_srm_reference: {
      0:'',
      4: errorMsg1
    },
    arm_srm_referenceObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    corrective_actions: {
      0:'',
      4: errorMsg1
    },
    corrective_actionsObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    intervals: {
      0:'',
      4: errorMsg1
    },
    intervalsObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    }
}
export const findingsHd = [
  { label: 'SR No', sortOption: false },
  { label: 'Type of Damage', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Classification', sortOption: false },
  { label: 'Category', sortOption: false },
  { label: 'Repair type', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true,align : "center" }
];

export const aircraftStepIds = [
  {label:'Front',value:'2033a87b-b248-47eb-a99f-28fbe6ec2f0d'},
  {label:'Back',value:'6dc0b84f-4848-47b9-955c-686016be070e'},
  {label:'Top',value:'95084994-2845-47bc-b7f9-854528547c81'},
  {label:'Bottom',value:'5337d1ef-d874-46e2-a15b-bd30531fb5c2'},
  {label:'Left',value:'bbc50053-9204-4edb-b9be-791e9cd1ecdc'},
  {label:'Right',value:'10e5d5b2-6785-461a-885a-bab03ded36f2'},
  {label:'Top-Left',value:'c3e7f6d7-a3f4-49d6-9e6b-1b94517c871f'},
  {label:'Top-Right',value:'4ba34e22-f25f-46d1-bec0-066bcda9349f'},
  {label:'Bottom-Left',value:'9e2eb2d6-626f-4884-bb99-d6759b73efc5'},
  {label:'Bottom-Right',value:'3daefa7f-df39-421d-8ed2-fe126f0e5e4b'},
  {label:'Right-Inward',value:'57686463-2379-4786-ac4d-d242f523e997'},
  {label:'Left-Inward',value:'94aaa6bd-cae6-433d-9cc5-4e52486b6a5a'},
]

export const damageType =[
  {id:"Dent",name:"Dent"},
  {id:"Scratch",name:"Scratch"},
  {id:"Nick",name:"Nick"},
  {id:"Gouge",name:"Gouge"},
  {id:"Erosion",name:"Erosion"},
  {id:"Corrosion",name:"Corrosion"},
  {id:"Puncture",name:"Puncture"},
  {id:"Lightning Strike",name:"Lightning Strike"},
  {id:"Hail strike",name:"Hail strike"},
  {id:"Wave",name:"Wave"},
  {id:"Crack",name:"Crack"},
  {id:"Others", name:"Others"}
]
  
export const findingsFields = [
  { name: "finding_type", label: "Damage Type", type: "autocomplete", required: true, options: damageType},
  { name: "damage_dimension", label: "Damage Dimension", type: "text", inputProps: { maxLength: 250 } },
  { name: "damage_limit", label: "Damage Within Limit", type: "text", inputProps: { maxLength: 250 } },
  { name: "remarks", label: "Description", type: "text", required: true, inputProps: { maxLength: 250 } },
  { name: "classifications", label: "Classification", type: "autocomplete", options:classifications },
  { name: "category", label: "Category", type: "autocomplete",options:category },
  { name: "repair_type", label: "Repair Type", type: "autocomplete",options:repairType },
  { name: "arm_srm_reference", label: "AMM / SRM Reference", type: "text", inputProps: { maxLength: 250 } },
  { name: "dd_wo_reference", label: "D&D WO Reference", type: "text", inputProps: { maxLength: 250 } },
  { name: "corrective_actions", label: "Corrective Action", type: "text", inputProps: { maxLength: 250 } },
  { name: "intervals", label: "Threshold / Intervals ", type: "text", inputProps: { maxLength: 250 } }
]

