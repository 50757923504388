import React, { Fragment, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Paper, Grid, Button, Tab, Tabs, Chip } from "@material-ui/core";
import { TaskManagementWrapper, MilestoneTasks } from '../components';
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, convertFilterObject, downloadFileType, getLocalStorageInfo, removeEmptyKey } from "../../../utils_v2";
import { displayDateFormatShort } from "../../../constants";
import { trackActivity } from '../../../utils/mixpanel';
const MilestoneDetail = ({params}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [milestoneInfo, setMilestoneInfo] = useState({});
    useEffect(() => {
        getMilestoneInfo();
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'Task Management Milestone Detail',
          });
    }, []);
    const getMilestoneInfo = () => {
        globalGetService( `technical/project/${params.project_slug}/milestones/${params.slug}/`)
        .then((response) => {
            if (checkApiStatus(response)) {
                setMilestoneInfo(response.data.data)
            }
        });
    }
    return(
        <section className='projects-management-apps projects-management-section'>
            <TaskManagementWrapper>
                <div>
                    {Object.keys(milestoneInfo).length ?
                        <div className="milestone-detail">
                            <Grid container spacing={1} alignItems="center"> 
                                <Grid item md={6}>
                                    <div>
                                        <h3>{milestoneInfo.name}</h3>
                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                <span className={`milestone-status ${milestoneInfo.status.label.toLowerCase()}`}>{milestoneInfo.status.label}</span>
                                            </li>
                                            {milestoneInfo.start_date || milestoneInfo.end_date ?
                                                <li className="list-inline-item">
                                                    {milestoneInfo.start_date ? moment(milestoneInfo.start_date).format(displayDateFormatShort):' '} {milestoneInfo.end_date?moment(milestoneInfo.end_date).format(displayDateFormatShort):''}
                                                </li>:null
                                            }
                                            { milestoneInfo.owner && milestoneInfo.owner.name ?
                                                <li className="list-inline-item">{milestoneInfo.owner.name}</li>:null
                                            }
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item md={6}>
                                    <div className="milestone-task-issue-progress">
                                        {tabIndex === 0 ?
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    {milestoneInfo.tasks.total-milestoneInfo.tasks.closed} Open Tasks
                                                </li>
                                                <li className="list-inline-item">
                                                    {milestoneInfo.tasks.closed} Close Tasks
                                                </li>
                                            </ul>:
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    {milestoneInfo.issues.total-milestoneInfo.issues.closed} Open Issues
                                                </li>
                                                <li className="list-inline-item">
                                                    {milestoneInfo.issues.closed} Close Issues
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                    <div className="milestone-asset-info">
                                        <ul className="list-inline flex-centered">
                                            { milestoneInfo.project_stage && milestoneInfo.project_stage.name ?
                                                <li className="list-inline-item">
                                                    <p className="singleline-clamp" style={{maxWidth:'140px'}}>{milestoneInfo.project_stage.name}</p>
                                                </li>:null
                                            }
                                            { milestoneInfo.work_order && milestoneInfo.work_order.unique_name ?
                                                <li className="list-inline-item">
                                                    <Chip label={milestoneInfo.work_order.unique_name}  size='small'/>
                                                </li>:null
                                            }
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                            <p></p>
                        </div>:null
                    }
                    
                    <Paper style={{marginBottom:'10px'}}>
                        <Tabs
                            value={tabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, newValue)=> setTabIndex(newValue)}
                        >
                            <Tab label="Tasks" />
                            <Tab label="Issues" />
                        </Tabs>
                        { tabIndex === 0 ?
                            <div>
                            
                            </div>:
                            <div>
                            </div>
                        }
                    </Paper>
                </div>
            </TaskManagementWrapper>
        </section>
    )
}
export default MilestoneDetail;