import React from 'react'
import { Tabs, Tab,  } from '@material-ui/core';
const CustomTabs = ({data,value,onChange}) => {
  return (
    <Tabs
    value={value}
    onChange={onChange}
    indicatorColor="primary"
    textColor="primary"
    variant="fullWidth"
  >
    {data?.map((section, index) => (
      <Tab label={section.name} key={index} />
    ))}
  </Tabs>
  )
}

export default CustomTabs