import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { browserHistory } from 'react-router';
import { Paper, Grid, Button, Dialog } from "@material-ui/core";
import { TaskManagementWrapper, StageList, AddEditStage } from "../components";
import { TableListComp, EmptyCollection, DeletePopUp,PageLoader,ExportManu,FilterUIComp} from "../../../shared_elements";
import { STableLoader } from '../../../shared_elements/loaders';
import { stagesFilterOps, stagesHd } from "..";
import FilterComponent from '../../../shared_elements/filter_component';
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, onCheckPermission, downloadFileType, getLocalStorageInfo,removeEmptyKey} from "../../../utils_v2";
import ClosurePopUp from "../components/ClosurePopUp";
import moment from "moment";
import { trackActivity } from '../../../utils/mixpanel';
import RestrictedAccess from "../../../shared_elements/components/RestrictedAccess";
const Stages = ({ params ,location}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [deleteStage, setDeleteStage] = useState({modal:false, stage:null})
  const [closureModal, setClosureModal] = useState(false)
  const [closureIds, setClosureIds] = useState(null)
  const [addEditStage, setAddEditStage] = useState({modal:false,stage:null})
  const [sort, setSort] = useState("")
  const [sort_by, setSortBy] = useState("")
  const [filter, setFilter] = useState({})
  const [noAcess, setNoAcess] = useState(false)
  const [status, setStatus] = useState([{ id: 1, name: "Active" },{ id: 2, name: "Close" }])
  const [stagesInfo, setStagesInfo] = useState({pagination: {},list: []})
  useEffect(() => {
    getProjectDetails()
    getStages({ per_page: 10 }, "skeletonLoader")
    trackActivity('Project Management', {
      event_type: 'Page Visited',
      page_title: 'Task Management Stages',
    });
  }, [])
  const getProjectDetails = (loaderType) => {
    setLoading(true)
    globalGetService(`technical/project/${params.project_slug}/`)
    .then(res => {
      setLoading(false)
        if(res.data.statusCode == 404){
            setNoAcess(true)
        }
    })
}
  const getStages = (query = {}, loaderType) => {
    if (loaderType === "skeletonLoader") {setSkeletonLoader(true)}else{setLoading(true)}
    globalGetService(`technical/project/${params.project_slug}/stages/`,query)
    .then((response) => {
      if (loaderType === "skeletonLoader") {setSkeletonLoader(false)}else{setLoading(false)}
      if (checkApiStatus(response)) {
        delete query.sort
        delete query.sort_by
        delete query.page
        delete query.per_page
        setStagesInfo(response.data.data)
        setFilter(query)
      }
    })
  }
  const onDeleteStage = () => {
    setLoading(true)
    globalDeleteService(`technical/project/${params.project_slug}/stages/${deleteStage.stage.id}/`)
    .then((response) => {
      setLoading(false)
      if (checkApiStatus(response)) {
        setDeleteStage({modal:false, stage:null})
        enqueueSnackbar(response.data.message, {variant: "success",anchorOrigin: { horizontal: "right", vertical: "top" }})
        getStages({ per_page: 10 }, "pageLoader")
        trackActivity('Project Management', {
          event_type: 'Delete Stages Success',
          page_title: 'Task Management Delete Stages',
          project_slug: params.project_slug,
          stages_id: deleteStage.stage.id ? deleteStage.stage.id : '',
          response_msg: response.data.message
        })
      } else {
        enqueueSnackbar(response.data.message, {variant: "error",anchorOrigin: { horizontal: "right", vertical: "top" }})
        trackActivity('Project Management', {
          event_type: 'Delete Stages Failed',
          page_title: 'Task Management Delete Stages',
          project_slug: params.project_slug,
          stages_id: deleteStage.stage.id ? deleteStage.stage.id : '',
          response_msg: response.data.message
        })
      }
    })
  }
  const onClosureStage = (data) => {
    globalPutService(`technical/project/${params.project_slug}/stages/${closureIds.id}/update-actual-closure`,data)
    .then((response) => {
      if (checkApiStatus(response)) {
        setClosureModal(false)
        setClosureIds(null)
        enqueueSnackbar(response.data.message, {variant: "success",anchorOrigin: { horizontal: "right", vertical: "top" }})
        getStages({ per_page: 10 }, "pageLoader")
        trackActivity('Project Management', {
          event_type: 'Close Stages Success',
          page_title: 'Task Management Close Stages',
          project_slug: params.project_slug,
          closure_id: closureIds.id ? closureIds.id : '',
          response_msg: response.data.message,
          request_body: data ? data : {}
        })
      } else {
        enqueueSnackbar(response.data.message, {variant: "error",anchorOrigin: { horizontal: "right", vertical: "top" }})
        trackActivity('Project Management', {
          event_type: 'Close Stages Failed',
          page_title: 'Task Management Close Stages',
          project_slug: params.project_slug,
          closure_id: closureIds.id ? closureIds.id : '',
          response_msg: response.data.message,
          request_body: data ? data : {}
        })
      }
    })
  }
  const onExportStages = (query = {}, file) => {
    enqueueSnackbar('Stages Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
    globalExportService(`technical/project/${params.project_slug}/stages/`, {...query,download: file.extension,})
    .then((response) => {
      downloadFileType(response,`Stages_${getLocalStorageInfo().defaultLessor.name}_${moment().format("YYYY-MM-DD")}.`,file.extension)
      closeSnackbar();
      enqueueSnackbar('Stages Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}});
      trackActivity('Project Management', {
        event_type: 'Export Stages Success',
        page_title: 'Task Management Export Stages',
        project_slug: params.project_slug,
        file_extension: file.extension ? file.extension : '',
        file_name: `Stages_${getLocalStorageInfo().defaultLessor.name}_${moment().format("YYYY-MM-DD")}.` ? `Stages_${getLocalStorageInfo().defaultLessor.name}_${moment().format("YYYY-MM-DD")}.` : '',
      })
    })
  }

  const createSortHandler = (sortField) => {
    setSort(sortField)
    setSortBy(sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc")
    if (sortField === sort) {
      if (sort_by === "asc") {
        getStages({...filter,sort: sortField,sort_by: "desc",per_page: stagesInfo.pagination.per_page},"pageLoader")
      } else {
        getStages({...filter,sort: sortField,sort_by: "asc",per_page: stagesInfo.pagination.per_page},"pageLoader")
      }
    } else {
      getStages({...filter,sort: sortField,sort_by: "asc",per_page: stagesInfo.pagination.per_page},"pageLoader")
    }
  }
  let filterOptions = {
    ...stagesFilterOps,
    status: {
      ...stagesFilterOps.status,
      options: status,
    },
  }
  return (
    <section className="projects-management-apps projects-management-section">
      {noAcess? <RestrictedAccess />:
      <TaskManagementWrapper>
        { skeletonLoader ? <STableLoader count={8} />:
          <div style={{height:`${window.innerHeight-140}px`, overflow:'auto'}}>
            <Paper square className="filter-cta">
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={12} md={9}>
                    <FilterComponent 
                      filter={filter}
                      filterMenu={filterOptions}
                      getResponseBack={(applyFilter) => {getStages({...applyFilter, page:1, per_page:stagesInfo.pagination.per_page}, 'pageLoader'); browserHistory.push({...location,query:applyFilter})}}
                     />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <ul className="list-inline cta-list">
                    { onCheckPermission('task_management','stages','C') ?
                      <li className="list-inline-item">
                        <Button color="primary" variant="contained" size="small" onClick={() => setAddEditStage({modal:true,stage:null})} > Add Stage </Button>
                      </li>:null
                    }
                    { onCheckPermission('task_management','stages','EXP') ?
                      <li className="list-inline-item">
                        <ExportManu
                          disabled={stagesInfo.list.length === 0 ? true : false}
                          title="Export"
                          files={[{ title: "Excel", extension: "xls", key: "" }]}
                          exportReportFn={(file) => onExportStages({download:file.extension, ...removeEmptyKey(filter)}, file)}
                        />
                      </li>:null
                    }
                  </ul>
                </Grid>
              </Grid>
            </Paper>
            <TableListComp
              style={{height: !stagesInfo.list.length ? 'auto':`${window.innerHeight-235}px`,overflow:'auto'}}
              heads={stagesHd}
              sort={sort}
              sort_by={sort_by}
              data={stagesInfo.list.map((item, index) => (
                <StageList
                  key={index}
                  stagesInfo={stagesInfo}
                  item={item}
                  onEdit={() => setAddEditStage({modal:true, stage:item})}
                  onRemoveStages={(item) => setDeleteStage({modal:true, stage:item})}
                  onStageClosure={(item) => { setClosureModal(true); setClosureIds(item) }}
                />
              ))}
              noRecord={ stagesInfo.list.length ? null: <EmptyCollection title="No records found" />}
              pagination={stagesInfo.pagination}
              onChangePage={(event, newPage) => getStages({...filter,page: newPage + 1,per_page: stagesInfo.pagination.per_page},"pageLoader")}
              onChangeRowsPerPage={(event) => getStages({...filter,page: 1,per_page: event.target.value},"pageLoader")}
              createSortHandler={createSortHandler}
            />
            
          </div>
        }
        
      </TaskManagementWrapper>}
      {deleteStage.modal ? (
        <DeletePopUp
          modal={deleteStage.modal}
          toggleModalFn={() => setDeleteStage({modal:false, stage:null})}
          title="Delete Stage"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => onDeleteStage()}
          confirmText="Delete"
          isLoading={isLoading}
        />
      ) : null}
      {closureModal ? 
        <ClosurePopUp
          modal={closureModal}
          toggleModalFn={() => setClosureModal(false)}
          title="Change Stages Status"
          submitClosureFn={onClosureStage}
          confirmText="YES"
          cancelText="NO"
        />: null
      }
      { addEditStage.modal ?
        <AddEditStage
          addEditStage={addEditStage}
          toggleModalFn={() => setAddEditStage({modal:false, stage:null})}
          getResponseBack={() => getStages({ per_page: 10 }, "pageLoader")}
        />:null
      }
      { isLoading ? <PageLoader/>:null }
    </section>
  )
}
export default Stages
