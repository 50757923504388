import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions,Button,CircularProgress } from '@material-ui/core'
const DialogueWrapper = (props) => {
    const {className,dividers,maxWidth,open,toggleModalFn,dialogueTitle,isLoading,handleSave,dialogueWidth,buttonLabel,disableButton,isPreview,children}= props
    return (
        <Dialog
            maxWidth={maxWidth ? maxWidth  : "md"}
            open={open}
            className={className ? className :'project-management-modal'}
        >
            <DialogTitle id="scroll-dialog-title">{dialogueTitle}</DialogTitle>
            <DialogContent dividers={dividers ? dividers :true} style={{ width: dialogueWidth }}>{children}</DialogContent>
            <DialogActions>
                <Button disabled={isLoading } variant='outlined' color="primary" onClick={toggleModalFn} size="small">{isPreview ? "Close" : "Cancel"}</Button>
               {!isPreview ? <Button disabled={isLoading || disableButton}  onClick={handleSave} variant="contained" color="primary" size="small">{isLoading ? <CircularProgress size={20} /> : buttonLabel ? buttonLabel : 'Save'}</Button> : null } 
            </DialogActions>
        </Dialog>
    )
}

export default DialogueWrapper