import React, { useState, useContext } from 'react'
import { Button, Grid, Tooltip, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import UploadPictures from './UploadPictures';
import { findingsFields } from '..';
import { FindingContext } from '../containers';
import CustomFields from '../shared/CustomFields';
import noPreviewImage from "../../assets/images/nopreviewimage.png"
import { DialogueWrapper, EditImageDialog, ImageSlider, PreviewFileModal } from '../../Elements';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { DeletePopUp } from '../../../shared_elements';

const FindingActionModal = ({ errors, setErrors }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loaderType, fetchData, modalAction, findingsData, setFindingsData, addEditFinding, toggleModalFn, deleteFindingImage, annotateFindingImg, changeFindingImage } = useContext(FindingContext);
  const [imgArr, setImgArr] = useState([])
  const [previewFile, setPreviewFile] = useState({})
  const [sliderInfo, setSliderInfo] = useState([])
  const [imgAnnotation, setImgAnnotation] = useState({ modal: false, data: null, sectionId: null });
  const handleDropzoneChange = (uploadedFiles) => setImgArr(uploadedFiles);
  const handleImageSave = async () => {
    if (findingsData?.images?.length > 0) {
      if (findingsData?.imageId && imgArr?.length) {
        const response = await changeFindingImage(findingsData?.imageId, imgArr[0])
        if (checkApiStatus(response)) {
          const updatedData = findingsData?.images?.map(img => {
            if (img.id === response?.data?.data.id) {
              return { ...img, image: response?.data?.data?.image }
            }
            return img
          })
          setFindingsData({ ...findingsData, images: updatedData, imageId: null, imageIndex: null })
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
      } else if ((findingsData?.imageIndex || findingsData?.imageIndex === 0) && imgArr?.length) {
        let updatedData = [...findingsData.images]
        updatedData[findingsData.imageIndex] = imgArr[0]
        setFindingsData({ ...findingsData, images: updatedData, imageIndex: null, imageId: null });
        enqueueSnackbar("Finding image updated successfully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      } else if (findingsData?.id && (findingsData?.imageIndex === null || findingsData.imageIndex === undefined) && (findingsData?.imageId === null || findingsData.imageId === undefined)) {
        const response = await addEditFinding({ ...findingsData, images: imgArr }, "uploadLoader")
        if (response?.data?.statusCode === 100) {
          setErrors(response?.data?.error)
        } else if (checkApiStatus(response)) {
          setFindingsData({ ...response?.data?.data, imageIndex: null, imageId: null })
        }
      }
      else {
        setFindingsData({ ...findingsData, images: [...findingsData.images, ...imgArr], imageIndex: null, imageId: null });
      }
    }
    else { setFindingsData({ ...findingsData, images: imgArr }); }
    toggleModalFn(false, "uploadModal");
  };

  const updateSlider = (pictures, title, index) => {
    setSliderInfo({ modal: true, title: title, startIndex: index, images: pictures.map(item => { return { original: item.id ? item.image : URL.createObjectURL(item), thumbnail: item.id ? item.image : URL.createObjectURL(item) } }), })
  }

  const handleImageDelete = async () => {
    const updatedRows = [...findingsData?.images];
    if (findingsData?.imageId) {
      const response = await deleteFindingImage(findingsData.imageId)
      if (checkApiStatus(response)) {
        updatedRows.splice(findingsData?.imageIndex, 1);
        setFindingsData({ ...findingsData, images: updatedRows, imageId: null, imageIndex: null });
        toggleModalFn(false, "deleteImageModal")
        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      }
    } else {
      updatedRows.splice(findingsData?.imageIndex, 1);
      setFindingsData({ ...findingsData, images: updatedRows });
      toggleModalFn(false, "deleteImageModal")
      enqueueSnackbar("Finding image deleted successfully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }

  }

  const annotateGenPicture = async (file) => {
    const response = await annotateFindingImg(file, imgAnnotation?.data?.id)
    if (checkApiStatus(response)) {
      const updatedData = findingsData?.images?.map(img => {
        if (img.id === response?.data?.data.id) {
          return { ...img, image: response?.data?.data?.image }
        }
        return img
      })
      setFindingsData({ ...findingsData, images: updatedData })
      setImgAnnotation({ modal: false, data: null })
      enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    } else {
      enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }
  }
  const handleChipClick = (event) => {
    setPreviewFile({ modal: true, data: [imgArr?.find(item => item?.path === event)], singlePreview: true })
  };
  const onFieldChange = (key, value) => { setFindingsData({ ...findingsData, [key]: value }); setErrors({}) }
  return (
    <div>
      <div className='project-management-modal'>
        {findingsData?.images?.length !== 5 ?
          <Grid container style={{ justifyContent: 'right', padding: '0px 4px 4px 0px' }}>
            <Button size='small' color='primary' variant='outlined' disabled={findingsData?.images?.length === 5} onClick={() => toggleModalFn(true, "uploadModal")}>Upload</Button>
          </Grid> : null}
        <Grid container spacing={1}>
          <CustomFields gridSize={4}
            fields={findingsFields}
            fieldData={findingsData}
            onFieldChange={onFieldChange}
            error={errors}
          />
        </Grid>
        <div style={{ marginTop: "12px" }}>
          {findingsData?.images?.length ?
            findingsData?.images?.map((ele, index) => {
              let url
              if (ele?.id) {
                const extension = ele?.image?.split('.').pop().split('?')[0];
                if (extension === "jpg" || extension === "jpeg" || extension === "png") {
                  url = `url(${ele.image})`
                } else { url = `url(${noPreviewImage})` }
              } else if (ele?.type?.startsWith('image/jpeg') || ele?.type?.startsWith('image/jpg') || ele?.type?.startsWith('image/png')) {
                url = `url(${ele?.id ? ele.image : URL.createObjectURL(ele)})`
              } else url = `url(${noPreviewImage})`

              return <li className="list-inline-item sort-picture-item" style={{ width: '177px', height: '150px', zIndex: 9, border: 'groove 1px', borderColor: 'lightgray', margin: "0.2rem" }}>
                <div className="dropped-pic-card" style={{
                  backgroundSize: 'cover',
                  backgroundImage: url,
                  height: '148px'
                }}>
                  <div className="dropped-picture-cta" style={{ display: "flex" }}>
                    <Tooltip title='View' arrow><IconButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); updateSlider(findingsData?.images, `Finding image for `, index); }} size="small"> <VisibilityIcon color="primary" fontSize='small' /></IconButton></Tooltip>
                    {onCheckFormPermission(fetchData.currentFormDetail) ?
                      <div>
                        {/* <Tooltip title="Download File" arrow>
                          <IconButton size="small" color="primary">
                            <a href={ele?.id ? ele.image : imgStoragePath+ele.path} download><GetAppIcon fontSize="small" /></a>
                          </IconButton>
                        </Tooltip> */}
                        {ele?.id ?
                          <Tooltip title='Annotate' arrow><IconButton onClick={() => { setImgAnnotation({ modal: true, data: { image: ele.id ? ele.image : URL.createObjectURL(ele), id: ele.id, index: index } }) }} size="small"> <EditIcon color="primary" fontSize='small' /></IconButton></Tooltip>
                          : null}

                        <Tooltip title='Change' arrow><IconButton onClick={() => { toggleModalFn(true, "uploadModal"); setFindingsData({ ...findingsData, imageIndex: index, imageId: ele.id }) }} size="small"> <AutorenewIcon color="primary" fontSize='small' /></IconButton></Tooltip>
                        <Tooltip title='Delete' arrow><IconButton onClick={() => { setFindingsData({ ...findingsData, imageId: ele?.id ? ele.id : null, rowName: ele?.name, imageIndex: index }); toggleModalFn(true, "deleteImageModal") }} size="small"><DeleteOutlineIcon color="secondary" fontSize='small' /></IconButton></Tooltip>
                      </div>
                      : null
                    }
                  </div>
                </div>
              </li>
            })
            :
            null
          }
        </div>
      </div>

      <DialogueWrapper open={modalAction?.uploadModal} disableButton={!imgArr?.length} handleSave={handleImageSave} isLoading={loaderType?.buttonLoader || loaderType?.uploadLoader} toggleModalFn={() => { toggleModalFn(false, "uploadModal"); setImgArr([]); setFindingsData({ ...findingsData, imageId: null, imageIndex: null }); }}>
        <UploadPictures disableNote={imgArr?.length > 0} fileLimit={findingsData?.imageIndex ? 1 : 5 - (findingsData?.images?.length || 0)} handleDropzoneChange={handleDropzoneChange} handleChipClick={handleChipClick} />
      </DialogueWrapper>
      {sliderInfo.modal ?
        <ImageSlider
          sliderInfo={sliderInfo}
          toggleModalFn={() => setSliderInfo({ modal: false, title: '', startIndex: null, images: [] })}
        /> : null
      }
      {modalAction.deleteImageModal ?
        <DeletePopUp
          modal={modalAction.deleteImageModal}
          toggleModalFn={() => { toggleModalFn(false, "deleteImageModal"); setFindingsData({ ...findingsData, imageId: null, imageIndex: null }) }}
          title={`Delete Picture`}
          content={<h4>Are you sure you want to Delete this Picture?</h4>}
          deleteRecordFn={() => handleImageDelete()}
        /> : null
      }

      {imgAnnotation.modal ?
        <EditImageDialog
          imgAnnotation={imgAnnotation}
          saveAnnotateImg={(file) => annotateGenPicture(file)}
          toggleModalFn={() => setImgAnnotation({ modal: false, data: null })}
          isLoading={loaderType.buttonLoader}
        /> : null
      }
      {previewFile?.modal ? <PreviewFileModal previewFile={previewFile} maxWidth="xl" toggleModalFn={() => setPreviewFile({ modal: false, data: [] })} /> : null}

    </div>
  )
}

export default FindingActionModal