import React, { useState, useContext } from 'react'
import { useSnackbar } from 'notistack';
import { Paper, Button, Table, TableHead, TableRow, TableCell, TableBody, Grid, Tooltip, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FindingActionModal from './FindingActionModal';
import { findingObj, findingsHd } from '..';
import { FindingContext } from '../containers';
import { DeletePopUp, EmptyCollection } from '../../../shared_elements';
import { checkApiStatus } from '../../../utils_v2';
import { DialogueWrapper } from '../../Elements';

const FindingListModal = () => {
  const [errors, setErrors] = useState({})
  const { enqueueSnackbar } = useSnackbar();
  const { fetchData, actionDisabled, findingsData, setFindingsData, toggleModalFn, modalAction, loaderType, getFinding, addEditFinding, getFindingList, deleteFinding } = useContext(FindingContext);
  const handleFindDelete = (index, deleteData) => {
    toggleModalFn(true, "deleteModal")
    setFindingsData({ ...findingsData, id: deleteData.id, rowName: deleteData.finding_type })
  }

  const handleSave = async () => {
    const response = await addEditFinding(findingsData)
    if (response?.data?.statusCode === 100) {
      setErrors(response?.data?.error)
    } else if (checkApiStatus(response)) {
      getFindingList(findingsData?.section?.id)
      setFindingsData({ ...findingsData, ...findingObj, id: null })
      toggleModalFn(false, "addModal")
      enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }
  }

  const handleFindEdit = async (rowId) => {
    if (rowId) {
      toggleModalFn(true, "addModal")
      const response = await getFinding(findingsData?.section?.id, rowId)
      if (checkApiStatus(response)) {
        setFindingsData({ ...findingsData, ...response?.data?.data, id: rowId, })
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      }
    }
  }

  const onDeleteFindings = async () => {
    const response = await deleteFinding(findingsData)
    if (checkApiStatus(response)) {
      toggleModalFn(false, "deleteModal")
      setFindingsData({ ...findingsData, ...findingObj, id: null })
      enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      getFindingList(findingsData?.section?.id)
    } else {
      enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }
  }

  return (
    <div><div className='project-management-modal'>
      {!actionDisabled ? <Grid container style={{ justifyContent: 'right', padding: '0px 0px 4px 0px' }}>
        <Button size='small' color='primary' variant='contained' onClick={() => toggleModalFn(true, "addModal")}>Add Finding</Button>
      </Grid> : null}
      <Paper square style={{ maxHeight: (window.innerHeight - 400) + 'px', height: (window.innerHeight - 400) + 'px' }} >
        <Table stickyHeader className='mui-table-format' >
          <TableHead><TableRow>{findingsHd?.map((headerField, index) => <TableCell align={headerField?.align} key={headerField?.label + index} style={{ width: headerField?.width }}>{headerField?.label}</TableCell>)}</TableRow></TableHead>
          <TableBody>
            {fetchData?.findingList?.length > 0 ? fetchData?.findingList?.map((bodyField, index) => {
              return (<TableRow key={index + bodyField.name}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{bodyField?.finding_type || "--"}</TableCell>
                <TableCell>{bodyField?.remarks || "--"}</TableCell>
                <TableCell>{bodyField?.classifications || "--"}</TableCell>
                <TableCell>{bodyField?.category || "--"}</TableCell>
                <TableCell>{bodyField?.repair_type || "--"}</TableCell>
                <TableCell align='center'>
                  {!actionDisabled ? < >
                    <Tooltip title='Edit' arrow><IconButton onClick={() => handleFindEdit(bodyField?.id)} ><EditIcon color='primary' fontSize='small' /></IconButton></Tooltip>
                    <Tooltip title='Delete' arrow><IconButton onClick={() => handleFindDelete(index, bodyField)}><DeleteOutlineIcon color='secondary' fontSize="small" /> </IconButton></Tooltip>
                  </>
                    : null}
                </TableCell>
              </TableRow>)
            }) : null}
          </TableBody>
        </Table>
        <div style={{ marginTop: "85px" }}>
          {!fetchData?.findingList.length && !loaderType?.pageLoader ? <EmptyCollection title="No findings found" className="empty-collection-ui-no-padding" /> : null}
        </div>

      </Paper>
    </div>
      {modalAction.deleteModal ?
        <DeletePopUp
          modal={modalAction.deleteModal}
          toggleModalFn={() => { toggleModalFn(false, "deleteModal"); setFindingsData({ ...findingsData, ...findingObj, id: null }) }}
          title={`Delete ${findingsData?.rowName}`}
          content={<h4>Are you sure you want to Delete {findingsData?.rowName}?</h4>}
          deleteRecordFn={() => onDeleteFindings()}
        /> : null
      }
      <DialogueWrapper open={modalAction?.addModal} handleSave={handleSave} isLoading={loaderType?.buttonLoader} dialogueWidth={800} maxWidth="lg" dialogueTitle={`${findingsData?.id !== null ? "Edit" : "Add"} Findings - ${findingsData?.section?.name}`} toggleModalFn={() => { toggleModalFn(false, "addModal"); setFindingsData({ ...findingsData, id: null, images: [], ...findingObj, }) }} >
        <div style={{ maxHeight: (window.innerHeight - 365) + 'px', height: (window.innerHeight - 365) + 'px' }}>  <FindingActionModal errors={errors} setErrors={setErrors} /></div>
      </DialogueWrapper>
    </div>
  )
}

export default FindingListModal