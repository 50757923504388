import React, { Component, } from 'react';
import { Grid,  } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { browserHistory } from 'react-router'
import { FormWrapper } from '../../Elements';
import { TableListComp,  DeletePopUp, ExportManu, EmptyCollection, PageLoader } from '../../../shared_elements';
import { FindingList, AddEditFinding, AircraftModelStep } from '../components'
import {  pictorialFinding3DReportApi, getCurrentFormDetailApi, changeFormStatusApi, getFindingSectionsApi, getPictorialFindingsApi, pictorialFindingReportApi, deleteFindingApi } from '../apiServices';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils_v2';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { findingsHd, aircraftStepIds } from '../'
const fabrikUrl = 'https://vark.fbrk.in/viewer/cpd/2967';
class PictorialFindings3d extends Component{
    constructor(props){
        super(props);
        this.state = {
            extension:'',
            screenshotIndex:0,
            screenshotFiles:[],
            stepId:'2033a87b-b248-47eb-a99f-28fbe6ec2f0d',
            stepType:'Front',
            findingModal:false,
            findingListFlag:false,
            skeletonLoader: true,
            pageLoader: false,
            deleteModal:false,
            deleteIds:null,
            currentFormDetail:null,
            pictorialFindings:{
                list:[],
                pagination:{}
            },
            findingSections:{aircraft_model:{},list:[]},
        };
        this.getCurrentFormDetailApi = getCurrentFormDetailApi.bind(this);
        this.changeFormStatusApi = changeFormStatusApi.bind(this);
        this.getPictorialFindingsApi = getPictorialFindingsApi.bind(this);
        this.getFindingSectionsApi = getFindingSectionsApi.bind(this);
        this.pictorialFindingReportApi = pictorialFindingReportApi.bind(this);
        this.deleteFindingApi = deleteFindingApi.bind(this);
        this.pictorialFinding3DReportApi = pictorialFinding3DReportApi.bind(this);
    }
    
    componentDidMount(){
        this.getCurrentFormDetailApi(this.props);
        this.getFindingSectionsApi(this.props);
        window.addEventListener('message', ({data}) => {
            switch(data?.type){
                case 'initialize':
                    this.getFindingList();
                    if (!data?.setup_fabrik_success) {
                        return;
                    }
                break;
                case "screenshotData":
                    this.setState(prevState => ({
                        ...prevState,
                        screenshotIndex:prevState.screenshotIndex+1,
                        screenshotFiles:[...prevState.screenshotFiles, data.file]
                    }));
                    if(this.state.screenshotFiles.length === aircraftStepIds.length){
                        this.export3dFormReport();
                    }
                    break;
            }
        })
    }
    export3dFormReport = () => {
        const { screenshotFiles, extension } = this.state;
        this.pictorialFinding3DReportApi(this.props, screenshotFiles, extension);
        
    }
    getFindingList = () => {
        this.getPictorialFindingsApi(this.props)
        .then(response => {
            if(checkApiStatus(response)){
                this.setState({
                    pictorialFindings:response.data.data
                });
                this.setUp3DPlots(response.data.data.list, this.state.stepId)
            }else{

            }
        })
    }
    getFindingResponse = (response, type) => {
        const { pictorialFindings, stepId } = this.state;
        if(type === 'add'){
            this.setUp3DPlots([...pictorialFindings.list, response.data.data], stepId);
            this.setState(prevState =>({
                ...prevState,
                pictorialFindings: {
                    ...prevState.pictorialFindings,
                    list: [...prevState.pictorialFindings.list, response.data.data]
                }
            }))
        }else{  
            let spots = pictorialFindings.list.map(item => item.id === response.data.data.id ? response.data.data:item)
            this.setUp3DPlots(spots, stepId);
            this.setState(prevState =>({
                ...prevState,
                pictorialFindings: {
                    ...prevState.pictorialFindings,
                    list: spots
                }
            }))
        }
    }
    setUp3DPlots = (hotSpots, stepId='') => {
        let customHotSpots= [];
        customHotSpots = hotSpots.filter(item => item.x_coordinate).map((item) => {return{step:stepId,title:`${item.id}_${item.section.id}`, bgColor:'#ff0000',hotspot_flickr: false,xPos:item.x_coordinate,yPos:item.y_coordinate,zPos:item.z_coordinate}});
        if(stepId){
            customHotSpots = hotSpots.filter(item => item.x_coordinate).filter(item => item.step_id === stepId).map((item) => {return{step:stepId,title:`${item.id}_${item.section.id}`, bgColor:'#ff0000',hotspot_flickr: false,xPos:item.x_coordinate,yPos:item.y_coordinate,zPos:item.z_coordinate}});
        }else{
            customHotSpots = hotSpots.filter(item => item.x_coordinate).map((item) => {return{step:stepId,title:`${item.id}_${item.section.id}`, bgColor:'#ff0000',hotspot_flickr: false,xPos:item.x_coordinate,yPos:item.y_coordinate,zPos:item.z_coordinate}});
        }
        const messages = [{type: "updateHotspots",customHots:customHotSpots}];
        document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
    }

    deleteRecordFn = () => {
        const { deleteIds, pictorialFindings, stepId } = this.state;
        this.deleteFindingApi(this.props, deleteIds)
        .then(response => {
            if(checkApiStatus(response)){
                let spots = pictorialFindings.list.filter(item => item.id !== deleteIds.id)
                this.setUp3DPlots(spots, stepId);
                this.setState(prevState =>({
                    ...prevState,
                    deleteModal:false,
                    deleteIds:null,
                    pictorialFindings: {
                        ...prevState.pictorialFindings,
                        list: spots
                    }
                }));
                this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }else{
                this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        })
    }
    goToStep = (stepId, stepType) => {
        const { pictorialFindings }  = this.state;
        const messages = [{type: "goToStep",step_id:stepId}];
        document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
        this.setState({stepId, stepType});
        this.setUp3DPlots(pictorialFindings.list, stepId);
    }
    reset3DToInitial = () => { // Trigger the 3D model to Initial Stage
        const { pictorialFindings, stepId } = this.state;
        const messages = [{type: 'reset'}];
        document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
        this.setUp3DPlots(pictorialFindings.list);
    }
    editCoOrdinates = (id, sectionId) => {
        const { pictorialFindings } = this.state;
        let customHotSpots = pictorialFindings.list.filter(item => item.id === id).map((item) => {return{step:'2033a87b-b248-47eb-a99f-28fbe6ec2f0d',title:`${item.id}_${item.section.id}`, bgColor:'#ff0000',hotspot_flickr: true,xPos:item.x_coordinate,yPos:item.y_coordinate,zPos:item.z_coordinate}});
        const messages = [{type: "updateHotspots",customHots:customHotSpots}];
        document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
        const messages1 = [{type:"selectHotspot", title: `${id}_${sectionId}`}];
        document.getElementById('fabrikIframe').contentWindow.postMessage(messages1, fabrikUrl);
    }
    onScreenShot = (extension,index=0) => { // sends the message to Fabrik
        const { pictorialFindings } = this.state;
        this.setState({extension:extension, pageLoader:true});
        let stepId = aircraftStepIds[index].value;
        const messages = [{type: "goToStep",step_id:stepId}];
        document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
        this.setUp3DPlots(pictorialFindings.list, stepId);
        const $that = this;
        setTimeout(() => {
            const messages1 = [{type: "getScreenshot"}];
            document.getElementById('fabrikIframe').contentWindow.postMessage(messages1, fabrikUrl);    
            if(index < 12){
                $that.onScreenShot(extension, index+1)
            }
        }, 30000);
    }
    


    render(){
        const { skeletonLoader, stepId, stepType, deleteModal, deleteIds, pageLoader, findingModal, findingSections, initiateHotSpotCoOrdinate, findingListFlag, currentFormDetail, pictorialFindings } = this.state;
        return(
            <section className='project-forms-section'>
                { currentFormDetail && Object.keys(currentFormDetail).length ?
                    <FormWrapper 
                        formInfo={currentFormDetail}
                        exportReportBtn={<ExportManu exportReportFn={(file) => this.onScreenShot(file.extension)} title="Export Report" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Doc', extension: 'docx', key:''}]} />}
                        updateFormStatusFn={(data) => this.changeFormStatusApi(this.props,data)}
                    />:null
                }
                <div className='pictorial-findings'>
                    <Grid container spacing={1}>
                        <Grid item md={findingListFlag || findingModal ? 6:12} xs={12}>
                            <div className='fabrik-frame'>
                                { getLocalStorageInfo().user.account_type === 'Acumen' && currentFormDetail?.asset?.asset_type === 1 && (currentFormDetail?.asset?.aircraft_type === 'A320' || currentFormDetail?.asset?.aircraft_type === 'A320-CEO') ?
                                    <span onClick={()=>browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t007`)} style={{position:'absolute', zIndex:99, top:'20px', right:'86px', border:'1px solid #040a51', padding:'5px 10px', borderRadius:'5px', color:'#040a51', cursor:'pointer'}}>Switch To 2D View</span>:null
                                }
                                <iframe
                                    id="fabrikIframe"
                                    style={{height:window.innerHeight-98, width:'100%'}}
                                    src={fabrikUrl}
                                >
                                        Sorry your browser does not support inline frames.
                                </iframe>
                                <ul className='list-inline new-finding-cta flex-centered'>
                                    <AddEditFinding 
                                        fabrikUrl={fabrikUrl} 
                                        mode="add" 
                                        stepId={stepId}
                                        stepType={stepType}
                                        reset3DToInitial={this.reset3DToInitial} 
                                        getFindingResponse={this.getFindingResponse} 
                                        findingSections={findingSections} 
                                        pictorialFindings={pictorialFindings}
                                        toggleFindingModal={(flag) => this.setState({findingModal:flag})}
                                        findingModal={findingModal}
                                    />
                                    <li className='list-inline-item' onClick={() => this.setState(prevState => ({...prevState, findingListFlag:!prevState.findingListFlag}))}>
                                        <ListAltIcon color={findingListFlag?'primary':'default'} />
                                    </li>
                                </ul>
                                <AircraftModelStep stepId={stepId} goToStep={this.goToStep} />
                            </div>
                        </Grid>
                        { findingListFlag ?
                            <Grid item md={6} xs={12}>
                                <div style={{height:`${window.innerHeight-110}px`, overflow:'auto'}}>
                                    <TableListComp
                                        heads={findingsHd}
                                        data={pictorialFindings.list.map((item,index) =>
                                            <FindingList
                                                currentFormDetail={currentFormDetail}
                                                key={index}
                                                item={item}
                                                reset3DToInitial={this.reset3DToInitial}
                                                getFindingResponse={this.getFindingResponse} 
                                                findingSections={findingSections} 
                                                fabrikUrl={fabrikUrl}
                                                pictorialFindings={pictorialFindings}
                                                editCoOrdinates={this.editCoOrdinates}
                                                toggleFindingModal={(flag) => this.setState({findingModal:flag})}
                                                findingModal={findingModal}
                                                toggleDeleteModal={() => this.setState({deleteModal:true, deleteIds:item})}
                                                stepId={stepId}
                                                stepType={stepType}
                                            />
                                        )}
                                        onChangePage={(event, newPage) => {this.getPictorialFindingsApi(this.props, { page:newPage+1, per_page: pictorialFindings.pagination.per_page}, 'pageLoader')}}
                                        onChangeRowsPerPage={(event) => this.getPictorialFindingsApi(this.props, { page:1, per_page: event.target.value}, 'pageLoader')}
                                        noRecord={ pictorialFindings.list.length ? null:
                                            <EmptyCollection title="No records found" />
                                        }
                                        pagination={pictorialFindings.pagination}
                                    />
                                </div>
                            </Grid>:null
                        }
                    </Grid>
                </div>
                <a href="" id="download"></a>
                <DeletePopUp
                    modal={deleteModal}
                    toggleModalFn={() => this.setState({deleteModal:false, deleteIds:{}}) }
                    title="Delete Finding"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => this.deleteRecordFn()}
                />
                {pageLoader ? <PageLoader />:null}
            </section>
        )
    }
}
export default withSnackbar(PictorialFindings3d);