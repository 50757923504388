import moment from "moment";

export const helperFunc = {
    getAttachmentName: (file) => {
        const filenameWithEncodedSpaces = file.split('/').pop().split('?')[0];
        const decodedFilename = filenameWithEncodedSpaces.replace(/^\d+\.\d+/, '')
        const cleanedFilename = decodeURIComponent(decodedFilename);
        let decoded = decodeURIComponent(decodeURIComponent(cleanedFilename));
        const queryStringIndex = decoded.indexOf('?');
        if (queryStringIndex !== -1) {
          decoded = decoded.substring(0, queryStringIndex);
        }
        const cleaned = decoded.replace(/%[0-9a-fA-F]{2}/g, ''); // Regular expression
        return cleaned
    },
    isValidDate: (date) => {
        return moment(date, "YYYY-MM-DD", true).isValid()
    },
    isBeforeTime: (date1, date2) => {
        return moment(date1).isBefore(date2 ? date2 : moment());
    },
    getFileType: (urlString) => {
        try {
            const urlObj = new URL(urlString);
            const pathname = urlObj.pathname;
            const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
            const fileType = filename.substring(filename.lastIndexOf('.'));
            return fileType;
        } catch (error) {
            return null;
        }
    },
    downloadFile : (file)=>{
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", "your-file.pdf"); // Suggested filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

}