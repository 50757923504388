import React, { useState } from 'react';
import { Table, TableBody, TableHead, Paper, Grid, TextField, Button, TableRow, IconButton, TableCell } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CkEditorText } from '../../../shared_elements';
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useSnackbar } from 'notistack';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { globalDeleteService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
import { DeletePopUp } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';

function FleetComposition({ data, setData, onFieldChange, callthisapi, getResponseBack, currentFormDetail, setInitialValue, setLoading, isLoading }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState({ modal: false, data: null })

    const fleetCompDis = data?.powerplant_fleet_tech_summaries ?
        data?.powerplant_fleet_tech_summaries?.power_fleet_composition?.filter(item => item?.id)?.length === data?.powerplant_fleet_tech_summaries?.power_fleet_composition?.length : [];

    const fleetCompfn = (modeTye, row) => {
        if (modeTye === 'add') {
            setData(prev => (
                {
                    ...prev,
                    powerplant_fleet_tech_summaries: {
                        ...prev.powerplant_fleet_tech_summaries,
                        power_fleet_composition: [
                            ...prev.powerplant_fleet_tech_summaries?.power_fleet_composition,
                            { aircraft_type: null, quantity: null, spare_engines: null }]
                    }
                }

            ));
        } else if (row?.id) {
            setLoading(true)
            globalDeleteService(`/technical/t015/basic-inspection/${row?.id}/delete/?module=fleet`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Fleet Composition Delete',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    } else {
                        getResponseBack()
                        setLoading(false)
                        setDeleteModal({ modal: false, data: null })
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        trackActivity('Project Management', {
                            event_type: 'Fleet Composition Delete',
                            page_title: 'T015 Form',
                            deleted_id: row?.id || '',
                            success_msg: response?.data?.message
                        });
                    }
                });
        } else {
            setData(prev => (
                {
                    ...(prev),
                    powerplant_fleet_tech_summaries: {
                        ...prev.powerplant_fleet_tech_summaries,
                        power_fleet_composition:
                            [...data?.powerplant_fleet_tech_summaries.power_fleet_composition]?.filter(item => item?.id)
                    }
                }

            ));
        }

    }


    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <ul className='list-inline' >
                    <li className="list-inline-item">
                        <h5 style={{ margin: '10px 0px 10px' }}> Fleet Composition :</h5>
                    </li>
                    <li className="list-inline-item" style={{ float: 'right' }}>
                        <Button disabled={!onCheckFormPermission(currentFormDetail) ? true : fleetCompDis ? false : true} onClick={() => fleetCompfn("add")} color="primary" variant="contained" size="small" style={{ margin: '5px' }} >+ ADD</Button>
                    </li>
                </ul>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <span>The composition of </span>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <span className='span-style'>fleet</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['was', 'was not']}
                            getOptionLabel={option => option}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '125px' }}
                            value={['was', 'was not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.fleet_composition?.discussed)[0] || null}
                            id="discussed"
                            onChange={(e, value) => onFieldChange(e, 'discussed', value, 'powerplant_fleet_tech_summaries', '', '', 'fleet_composition')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>discussed.</span>
                    </Grid>
                </Grid>

                <Table className='mui-table-format-t015' >
                    <TableBody >
                        {data?.powerplant_fleet_tech_summaries?.power_fleet_composition?.map((item, index) => (
                            <TableRow>
                                <TableCell><h6>Aircraft Type</h6></TableCell>
                                <TableCell >
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        id='aircraft_type'
                                        onChange={(e) => onFieldChange(e, 'aircraft_type', e.target.value, 'powerplant_fleet_tech_summaries', index, '', 'power_fleet_composition')}
                                        placeholder="Enter.."
                                        fullWidth
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.aircraft_type || ''}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </TableCell>
                                <TableCell><h6>Quantity</h6></TableCell>
                                <TableCell>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        id='quantity'
                                        placeholder="Enter.."
                                        fullWidth
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'quantity', e.target.value, 'powerplant_fleet_tech_summaries', index, '', 'power_fleet_composition') : e.preventDefault() }}
                                        value={item?.quantity || ''}
                                        inputProps={{ maxLength: 5 }}
                                    />
                                </TableCell>
                                <TableCell><h6>Spare Engines</h6></TableCell>
                                <TableCell>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        id='spare_engines'
                                        onChange={(e) => onFieldChange(e, 'spare_engines', e.target.value, 'powerplant_fleet_tech_summaries', index, '', 'power_fleet_composition')}
                                        placeholder="Enter.."
                                        fullWidth
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={item?.spare_engines || ''}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton disabled={!onCheckFormPermission(currentFormDetail)} color='secondary' >
                                        <DeleteOutlinedIcon onClick={() => { item?.id ? setDeleteModal({ modal: true, data: item }) : fleetCompfn() }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                        }

                    </TableBody>
                </Table>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                            inputProps={{ maxLength: 50 }}
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['will', 'will not']}
                            getOptionLabel={option => option}
                            className='engine-stand-select'
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '125px' }}
                            id="require"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['will', 'will not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.fleet_composition?.require)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'require', value, 'powerplant_fleet_tech_summaries', '', '', 'fleet_composition')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>require further spare engine support at this time.</span>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details, if no please enter.. "N/A"'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.fleet_composition?.time_note || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.fleet_composition?.time_note)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data?.powerplant_fleet_tech_summaries,
                                        fleet_composition: {
                                            ...data?.powerplant_fleet_tech_summaries?.fleet_composition,
                                            time_note: value
                                        }
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <span>If yes, Sales and Marketing</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "125px" }}
                            id="marketing"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.fleet_composition?.marketing)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'marketing', value, 'powerplant_fleet_tech_summaries', '', '', 'fleet_composition')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>been made aware of this requirement.</span>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <span className='span-style'>route network</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['was', 'was not']}
                            getOptionLabel={option => option}
                            className='engine-stand-select'
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "125px" }}
                            id="route_network"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['was', 'was not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.fleet_composition?.route_network)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'route_network', value, 'powerplant_fleet_tech_summaries', '', '', 'fleet_composition')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>discussed.</span>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details, if no please enter.. "N/A"'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.fleet_composition?.route_network_note || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.fleet_composition?.route_network_note)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data?.powerplant_fleet_tech_summaries,
                                        fleet_composition: {
                                            ...data?.powerplant_fleet_tech_summaries?.fleet_composition,
                                            route_network_note: value
                                        }
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <span className='span-style'>expansion plan</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['was', 'was not']}
                            getOptionLabel={option => option}
                            className='engine-stand-select'
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "125px" }}
                            id="expansion_plan"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['was', 'was not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.fleet_composition?.expansion_plan)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'expansion_plan', value, 'powerplant_fleet_tech_summaries', '', '', 'fleet_composition')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>discussed.</span>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details, if no please enter.. "N/A"'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.fleet_composition?.expansion_plan_note || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.fleet_composition?.expansion_plan_note)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data?.powerplant_fleet_tech_summaries,
                                        fleet_composition: {
                                            ...data?.powerplant_fleet_tech_summaries?.fleet_composition,
                                            expansion_plan_note: value
                                        }
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                    <p style={{ margin: '20px 0px 10px' }}>General comments on Customer Fleet : </p>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details, if no please enter.. "N/A"'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.powerplant_fleet_tech_summaries?.fleet_general_comment || ''}
                                onBlurData={() => callthisapi('', data?.powerplant_fleet_tech_summaries?.fleet_general_comment)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    powerplant_fleet_tech_summaries: {
                                        ...data.powerplant_fleet_tech_summaries,
                                        fleet_general_comment: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            size='small'
                        />
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['has', 'has not']}
                            getOptionLabel={option => option}
                            className='engine-stand-select'
                            style={{ display: 'inline-grid', marginLeft: '5px', width: "125px" }}
                            id="shared"
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['has', 'has not'].filter(item => item === data?.powerplant_fleet_tech_summaries?.fleet_composition?.shared)[0] || null}
                            onChange={(e, value) => onFieldChange(e, 'shared', value, 'powerplant_fleet_tech_summaries', '', '', 'fleet_composition')}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' style={{ display: 'contents' }} margin="normal" fullWidth />}
                        />
                        <span className='span-style'>shared a copy of their Air Operator Certificate.</span>
                    </Grid>
                </Grid>
            </Paper>
            {deleteModal?.modal ?
                <DeletePopUp
                    modal={deleteModal.modal}
                    toggleModalFn={() => setDeleteModal({ modal: false, data: null })}
                    title="Fleet Composition"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => fleetCompfn('', deleteModal?.data)}
                /> : null
            }
        </section>
    );
}

export default FleetComposition