import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { browserHistory } from 'react-router';
import { globalGetService, globalPostService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, getGlobalCookie, getLocalStorageInfo, setGlobalCookie } from '../../utils_v2';
import config from '../../config';
import { trackLogin } from '../../utils/mixpanel';
import { globalSignUpPostService } from '../../globalServices';


export const useFetchApi = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [fetchData, setFetchData] = useState({});
  const [loaderType, setLoaderType] = useState({ pageLoader: false, skeletonLoader: false })

  const getNotificationBarDaa = async () => {
    const response = await globalGetService('/console/cart/count/');
    if (checkApiStatus(response)) {
      setFetchData(prevState => ({
        ...prevState,
        notificationCount: response?.data?.data?.notification_count,
      }));
    } else {
      enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  };

  useEffect(() => {
    getNotificationBarDaa()
  }, [])

  return {
    fetchData,
  };
};

