import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReviewIssueDrawer, ReviewHeader, IssueDetail } from '../components';
import '../../../assets/styles/technical_inspections.scss';
import { fetchReviewDataAc, fetchFormsFeedbackAc, fetchIssueDetailAc, notifyClientAc, addCommentsAc, changeIssueStatusAc, addIssuesAc, updateCommentsAc } from '../actionCreators';
import { imgStoragePath } from "../../../../../constants";
import { TOGGLE_DETAILS, TOOGLE_ADD_ISSUES, TOOGLE_ISSUES_LIST, TOOGLE_EDIT_ISSUES } from '../actions';
import { getFormDetailsAc } from "../../T001/actionCreators";
import { FormHeader, SecondaryTechHeaderMid, SecondaryTechHeaderRH, EditFormBar } from "../../../Elements";
import { MSNSwitcherHeader, ListLoader } from "../../../../../shared";
import AddIssues from '../components/AddIssues';
import EditIssues from '../components/EditIssues';
import { trackActivity } from '../../../../../utils/mixpanel';
class ReviewT001 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureId: '',
      aircraftSection: '',
      workOrderId: '',
      editIssue: '',
    };
  }
  componentDidMount() {
    this.props.fetchReviewData();
    this.props.fetchFormsFeedback();
    this.props.getFormDetails();
    trackActivity('Project Management', {
      event_type: 'Page Visited',
      page_title: 'ReviewT001'
    })
  }

  toggleComments = (pictures) => {
    this.setState({
      pictureId: pictures ? pictures.id : '',
      aircraftSection: pictures ? pictures.aircraft_section : '',
      workOrderId: pictures ? pictures.work_order : '',
    });
    this.props.toggleComment();
  };

  toggleEditIssues = () => {
    this.props.toggleEditIssue();
  };

  toggleEdits = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      editIssue: issue,
    });
    this.props.toggleEditIssue();
  };

  toggleIssues = () => {
    this.props.toggleIssue();
  };

  toggleDetail = (issue) => {
    issue ? this.props.reviewFeedBackDetail(issue.location_slug) : this.props.toggleDetails();
  };

  updateComments = (value) => {
    let data = {
      description: value,
      aircraft_section: this.state.aircraftSection,
      picture_id: this.state.pictureId,
      form_id: this.props.formInfo.current_form.id,
      work_order_id: this.state.workOrderId
    }
    this.props.addIssue(data);
  }

  updateComments = (value) => {
    let data = {
      description: value,
      aircraft_section: this.state.aircraftSection,
      picture_id: this.state.pictureId,
      form_id: this.props.formInfo.current_form.id,
      work_order_id: this.state.workOrderId
    }
    this.props.addIssue(data);
  }


  updateIssues = (value) => {
    this.props.reviewIssueStatusChange(this.state.editIssue.location_slug, value, 'edit');
  }



  changeStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status);
  };

  deleteStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status, 'delete');
  };

  changeStatusDetails = (e, location_slug, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(location_slug, status, 'details');
  };

  updateCommentStatus = (e, location_slug, commentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.updateCommentStatus(location_slug, commentId);
  }

  reviewUIComp = (issue, pictures) => {

    if (issue.order_number == null || issue.order_number == undefined) {
      return (
        <span onClick={() => this.toggleComments(pictures)} className="comment-icon" >
          <img src={imgStoragePath + "cmnt_active.svg"} className="blue-icon" alt="" />
          <img src={imgStoragePath + "cmnt-inactive.svg"} className="grey-icon" alt="" />
        </span>

      )
    } else {
      return (
        <span onClick={() => this.toggleIssues()} className="issue-number" >
          <span>#{issue.order_number}</span>
        </span>
      )
    }
  }
  render() {
    const { t001ReviewList, reviewFeedbacksList, issueDetailInfo, commentsLoader, notifyFlag } = this.props;

    return (
      <div className="technical-review-model">
        {Object.keys(this.props.formInfo).length ?
          <MSNSwitcherHeader
            SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
            SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset} />}
            activeAsset={this.props.formInfo.asset}
            projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
          /> : null
        }
        <ReviewHeader
          formInfo={this.props.formInfo}
          notifyFlag={notifyFlag}
          notifyClient={this.props.notifyClient}
          workOrderSlug={this.props.params.workOrderSlug}
        />
        {commentsLoader ? <ListLoader /> : null}
        <div className="form-t001">
          {t001ReviewList.map((section, sectionIndex) =>
            <div className="section-cn" key={sectionIndex}>
              <h3>{section.name}</h3>
              <ul className="list-unstyled text-center">
                {section.pictures.map((pictures, index) =>
                  <li key={index}>
                    <img className="aircraft-images" src={pictures.image} alt="" />
                    {this.reviewUIComp(pictures.issue, pictures)}
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <a href="javascript:void(0)" className="view-issues-btn" onClick={() => this.toggleIssues()} >
          <img src={imgStoragePath + 'icon_CommentDash.svg'} alt="" />
        </a>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.props.toggleComments}
          style={this.props.toggleComments ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleComments ?
            <AddIssues
              updateComments={this.updateComments}
              toggleEditSideBar={() => this.toggleComments()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Edit Issue'} </h3></div>}
          toggleEditSideBar={() => this.toggleEditIssues()}
          isOpen={this.props.toggleEditIssues}
          style={this.props.toggleEditIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleEditIssues ?
            <EditIssues
              updateComments={this.updateIssues}
              issueData={this.state.editIssue}
              toggleEditSideBar={() => this.toggleEditIssues()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues List'} </h3></div>}
          toggleEditSideBar={() => this.toggleIssues()}
          isOpen={this.props.toggleIssues}
          style={this.props.toggleIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleIssues ?
            <ReviewIssueDrawer
              issueList={this.props.reviewFeedbacksList}
              toggleEditSideBar={() => this.toggleComments()}
              toggleDetailBar={this.toggleDetail}
              changeStatus={this.changeStatus}
              deleteStatus={this.deleteStatus}
              editStatus={this.toggleEdits}
            /> : null}
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues Discussion'} </h3></div>}
          toggleEditSideBar={() => this.toggleDetail()}
          isOpen={this.props.toggleDetail}
          style={this.props.toggleDetail ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleDetail ?
            <IssueDetail
              issueDetailInfo={issueDetailInfo}
              toggleEditSideBar={() => this.toggleDetail()}
              toggleSideBars={() => this.toggleIssues()}
              addComments={this.props.addComments}
              changeStatus={this.changeStatusDetails}
              updateCommentStatus={this.updateCommentStatus}
            />
            : null}
        </EditFormBar>
      </div>

    )
  }
}
const mapStateToProps = (state) => ({
  t001ReviewList: state.FormReviewReducer.t001ReviewList,
  reviewFeedbacksList: state.FormReviewReducer.reviewFeedbacksList,
  toggleComments: state.FormReviewReducer.toggleComments,
  toggleEditIssues: state.FormReviewReducer.toggleEditIssues,
  toggleIssues: state.FormReviewReducer.toggleIssues,
  toggleDetail: state.FormReviewReducer.toggleDetail,
  formInfo: state.FormT001Reducer.formInfo,
  issueDetailInfo: state.FormReviewReducer.issueDetailInfo,
  commentsLoader: state.FormReviewReducer.commentsLoader,
  notifyFlag: state.FormReviewReducer.notifyFlag
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReviewData: () => dispatch(fetchReviewDataAc(ownProps, 't001')),
    fetchFormsFeedback: () => dispatch(fetchFormsFeedbackAc(ownProps, 't001')),
    reviewFeedBackDetail: (locationSlug) => dispatch(fetchIssueDetailAc(ownProps, locationSlug, 't001')),
    reviewIssueStatusChange: (locationSlug, status, flag = '') => dispatch(changeIssueStatusAc(ownProps, locationSlug, status, 't001', flag)),
    updateCommentStatus: (locationSlug, commentID) => dispatch(updateCommentsAc(ownProps, locationSlug, commentID)),
    getFormDetails: () => dispatch(getFormDetailsAc(ownProps)),
    addComments: (location_slug, message) => dispatch(addCommentsAc(location_slug, message)),
    notifyClient: () => dispatch(notifyClientAc(ownProps)),
    addIssue: (data) => dispatch(addIssuesAc(ownProps, data, 't001')),
    toggleComment: () => dispatch({
      type: TOOGLE_ADD_ISSUES,
      payload: {}
    }),
    toggleEditIssue: () => dispatch({
      type: TOOGLE_EDIT_ISSUES,
      payload: {}
    }),
    toggleIssue: () => dispatch({
      type: TOOGLE_ISSUES_LIST,
      payload: {}
    }),
    toggleDetails: () => dispatch({
      type: TOGGLE_DETAILS,
      payload: {}
    }),

  }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewT001);
