import React from 'react';
import { Link } from 'react-router';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { dashboardIcon, homeIcon, templateIcon } from '../assets/img';
import appConfig from '../../config';
import { getLocalStorageInfo, onCheckPermission } from '../../utils_v2';
import { imgStoragePath } from '../../constants';
import "../assets/styles/sidebar.scss"
import "../assets/styles/CommonHeaderBlock.scss"
const SidebarComponent = ({ pathName, topNotice, notificationOpen }) => {
  const userDetail = JSON.parse(localStorage.getItem('userInfo'));
  const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (appConfig.env.key === "api" ? 465 : 532);
  return (
    <div className="prySidebarExp" style={topNotice || notificationOpen ? { marginTop: '30px' } : null}>
      <div className="dashboard-block">
        <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? "30px" : "0px" }}>
          <li>
            <Link to={appConfig.domain.subDomian} target="_blank" className="flex-centered">
              <img src={homeIcon} alt="Home" width="20" />
              <br />
              <span className="sideBarSubheading">Home</span>
            </Link>
          </li>
          {onCheckPermission("project_management", "dashboard", "R") ? <li>
            <Link to="/fleet/technical/dashboard" className={pathName.includes('/fleet/technical/dashboard') ? "flex-centered active" : "flex-centered"}>
              <img src={dashboardIcon} alt="Dashboard" width="20" />
              <br />
              <span className="sideBarSubheading">Dashboard</span>
            </Link>
          </li> : null}
          <li>
            <Link to="/technical/projects?project_status=all" className={pathName.includes("technical/project") && !pathName.includes("technical/projects/tracker") || pathName.includes("/forms/") || pathName === '' || pathName === '/' ? "flex-centered active" : "flex-centered"}>
              <img src={`${imgStoragePath}phase-2/technical_projects.png`} alt="Projects" width="20" />
              <br />
              <span className="sideBarSubheading">Projects</span>
            </Link>
          </li>
          {onCheckPermission("project_management", "tracker", "R") ? <li>
            <Link to="/technical/projects/tracker" className={pathName.includes("/technical/projects/tracker") || pathName.includes("technical/man-power/tracker") ? "flex-centered active" : "flex-centered"}>
              <img src={`${imgStoragePath}phase-2/project_tracker.png`} alt="Tracker" width="20" />
              <br />
              <span className="sideBarSubheading">Trackers</span>
            </Link>
          </li> : null}
          {getLocalStorageInfo().user.account_type === "Acumen" &&
            (getLocalStorageInfo().user.is_root_user || getLocalStorageInfo().user?.security_groups.filter(item => [24, 29].includes(item?.id))?.length) ? <li>
            <Link to="/analytics/resources" className={pathName.includes("/analytics/resources") || pathName.includes('/analytics/projects') ? "flex-centered active" : "flex-centered"}>
              <img src={`${imgStoragePath}tableau_analytics.svg`} alt="Tracker" width="20" />
              <br />
              <span className="sideBarSubheading">Analytics</span>
            </Link>
          </li> : null}
          {[321, 442].includes(getLocalStorageInfo()?.defaultLessor?.id) ? <li>
            <Link to="/technical/program-planner" className={pathName.includes("/technical/program-planner") ? "flex-centered active" : "flex-centered"}>
              <img src={`${imgStoragePath}Bitmap.png`} alt="Program Planner" width="20" />
              <br />
              <span className="sideBarSubheading">Program Planner</span>
            </Link>
          </li> : null}
          {!smbcInstance && onCheckPermission("project_management", "opr_report", "R") ? <li>
            <Link to="/technical/opr/report" className={pathName.includes("/technical/opr/report") ? "flex-centered active" : "flex-centered"}>
              <img src={`${imgStoragePath}report_icon.png`} alt="OPR Report" width="20" />
              <br />
              <span className="sideBarSubheading">OPR Report</span>
            </Link>
          </li> : null}
          {onCheckPermission("project_management", "template", "R") ? <li>
            <Link to="/technical/form/templates" className={pathName.includes("/technical/form/templates") ? "flex-centered active" : "flex-centered"}>
              <img src={templateIcon} alt="Templates" width="20" />
              <br />
              <span className="sideBarSubheading">Templates</span>
            </Link>
            <Link to="/technical/tags" className={pathName.includes("/technical/tags") ? "flex-centered active" : "flex-centered"}>
              <LocalOfferOutlinedIcon fontSize="small" style={{ color: "white", opacity: "0.8" }} />
              <br />
              <span className="sideBarSubheading">Tags</span>
            </Link>
          </li> : null}
        </ul>
      </div>
    </div>
  );
};

export default SidebarComponent;