import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';

const UploadPictures = ({fileLimit,handleDropzoneChange,handleChipClick,disableNote}) => {
    return (
        <>
        <div style={{ width: '380px' }}>
            <DropzoneArea
                filesLimit={fileLimit}
                maxFileSize={104857600}
                showPreviewsInDropzone={false}
                useChipsForPreview={true}
                acceptedFiles={[".png, .jpg, .jpeg"]}
                showPreviews={true}
                dropzoneText={ <p style={{ fontSize: '13px' }}>Drag & Drop Pictures<br /> OR <br /> Click Here<br /> </p> }
                dropzoneClass="drag-drop-cnt"
                maxWidth="sm"
                showAlerts={['info', 'error']}
                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                clearOnUnmount={true}
                onChange={handleDropzoneChange}
                previewChipProps={{
                    onClick: (event) => {
                        handleChipClick(event?.target.textContent);
                    }
                }}
            />
        </div>
          {disableNote ?null : <div style={{fontSize : "12px",width : "320px"}} > Note : Max images upload limit is {fileLimit} in single time.<br/> Supported format : .jpeg,.jpg,.png </div>}
        </>
    )
}
export default UploadPictures;