import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, } from "@material-ui/core";
import { backendDateFormat, fieldDateFormat } from "../../../constants";
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { checkApiStatus, onCheckPermission } from "../../../utils_v2";
import { milestoneForm } from "..";
import { globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { trackActivity } from '../../../utils/mixpanel';
import { smbcInstance, testLessor } from "../../../shared_elements/components";
const AddEditMilestone = ({ addEditMilestone, params, owners, workOrders, stages, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState(addEditMilestone.milestone ?addEditMilestone.milestone:milestoneForm)
  const [error, setError] = useState({})
  const [doubleClick, setDoubleClick] = useState(false)
  const onFieldChange = (keyParam, value) => {
    setFormData({ ...formData, [keyParam]: value })
  }
  const onRestErrorKey = (keyParam) => {
    setError({ ...error, [keyParam]: "" })
  }
  const onAddEditMilestone = () => {
    let validationInputs = {
      name:formData.name ? '':'Please enter Name',
      start_date:formData.start_date ? '': 'Please enter Start Date',
      end_date:formData.end_date ? '': 'Please enter End Date',
      owner:formData.owner ? '': 'Please select Owner',
    }
    if(formData.start_date && formData.end_date){
      if(smbcInstance || testLessor ){
        if(!moment(formData.end_date).isSameOrAfter(formData.start_date)){
          validationInputs.end_date = 'End Date should be greater or equal to the Start Date'
        }
      }
      else{
        if(!moment(formData.end_date).isAfter(formData.start_date)){
          validationInputs.end_date = 'End Date should be greater than Start Date'
        }
      }
    }
    if ( Object.keys(validationInputs).every((k, v) => { return validationInputs[k] === "" }) ) {
      setLoading(true);
      if(addEditMilestone.milestone){
        delete formData['created_by']
        delete formData['updated_by']
        globalPutService( `technical/project/${params.project_slug}/milestones/${addEditMilestone.milestone.id}/`, formData)
        .then((response) => {
          setLoading(false);
          if (checkApiStatus(response)) {
            enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
            toggleModalFn(); getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Update Milestone Success',
              page_title: 'Task Management Update Milestone',
              project_slug: params.project_slug,
              milestone_id: addEditMilestone.milestone.id ? addEditMilestone.milestone.id : '',
              request_body: formData ? formData : '',
              response_msg: response.data.message
            });
          } else {
            enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
            trackActivity('Project Management', {
              event_type: 'Update Milestone Failed',
              page_title: 'Task Management Update Milestone',
              project_slug: params.project_slug,
              milestone_id: addEditMilestone.milestone.id ? addEditMilestone.milestone.id : '',
              request_body: formData ? formData : '',
              response_msg: response.data.message,
              error_source: 'Backend',
              error_message: response.data.message
            });
          }
        })
      }else{
        globalPostService( `technical/project/${params.project_slug}/milestones/`, formData)
        .then((response) => {
          setLoading(false);
          if (checkApiStatus(response)) {
            enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
            toggleModalFn(); getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Add Milestone Success',
              page_title: 'Task Management Add Milestone',
              project_slug: params.project_slug,
              request_body: formData ? formData : '',
              response_msg: response.data.message
            });
          } else {
            enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
            trackActivity('Project Management', {
              event_type: 'Add Milestone Failed',
              page_title: 'Task Management Add Milestone',
              project_slug: params.project_slug,
              request_body: formData ? formData : '',
              error_source: 'Backend',
              error_message: response.data.message
            })
          }
        })
      }
    }else{
      setError(validationInputs)
      trackActivity('Project Management', {
        event_type: 'Add/update Milestone Failed',
        page_title: 'Task Management Add/update Milestone',
        project_slug: params.project_slug,
        milesone_id: addEditMilestone?.milestone?.id ? addEditMilestone.milestone.id : '',
        error_source: 'Frontend',
        error: validationInputs ? validationInputs : ''
      });
    } 
  }
      
  return (
    <Fragment>
      <Dialog
        className="project-management-modal"
        open={addEditMilestone.modal}
        onClose={toggleModalFn}
        maxWidth={"sm"}
      >
        <DialogTitle>{addEditMilestone.milestone ? 'Edit Milestone':'Add Milestone'}</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                id="name"
                fullWidth
                required
                label="Name"
                margin="normal"
                inputProps={{ maxLength: 255 }}
                value={formData.name}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e, value) => { onFieldChange( "name", e?.target.value?.trim()?.length ? e.target.value: '')}}
                error={error.name ? true : false}
                helperText={error.name ? error.name : ""}
                onFocus={() => onRestErrorKey("name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  required
                  id="start_date"
                  label="Start Date"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formData.start_date}
                  error={error.start_date ? true : false}
                  helperText={error.start_date ? error.start_date : ""}
                  onChange={(data, value) => { onFieldChange( "start_date", data ? moment(data).format(backendDateFormat) : data );onRestErrorKey("start_date") }}
                  minDate={moment().subtract(25, "years")}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="end_date"
                  required
                  label="End Date"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formData.end_date}
                  error={error.end_date ? true : false}
                  helperText={error.end_date ? error.end_date : ""}
                  onChange={(data, value) => { onFieldChange( "end_date", data ? moment(data).format(backendDateFormat) : data );onRestErrorKey("end_date") }}
                  inputVariant="outlined"
                  minDate={moment().subtract(25, "years")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                options={owners}
                getOptionLabel={(option) => option.name}
                id="owner"
                value={formData.owner}
                onChange={(e, value) => onFieldChange("owner", value)}
                renderInput={(params) => (
                  <TextField
                    required
                    onFocus={() => onRestErrorKey("owner")}
                    error={error.owner ? true : false}
                    helperText={error.owner ? error.owner : ""}
                    {...params}
                    label="Owner"
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            { onCheckPermission('task_management','stages','R') ?
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={stages}
                  getOptionLabel={(option) => option.name}
                  id="project_stage"
                  value={formData.project_stage}
                  onChange={(e, value) => onFieldChange("project_stage", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Stage"
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>:null
            }
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={workOrders}
                getOptionLabel={(option) => option.unique_name}
                id="work_order"
                value={formData.work_order}
                onChange={(e, value) => onFieldChange("work_order", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Asset"
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="remarks"
                label="Remarks"
                fullWidth
                margin="normal"
                multiline
                minRows={3}
                inputProps={{ maxLength: 255 }}
                value={formData.remarks}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e, value) => onFieldChange("remarks", e.target.value)}
              />
            </Grid>
          </Grid>          
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button disabled={isLoading} color="primary" variant="outlined" size="small" onClick={toggleModalFn} > Cancel </Button>
          <Button disabled={isLoading} color="primary" variant="contained" className="blueFill-btn" size="small" onClick={onAddEditMilestone}> 
            { isLoading ? <CircularProgress size={22} /> :'Save'} 
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default withRouter(AddEditMilestone)
