
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { helperFunc } from '../helperFunc';

const ImagePreview = ({ fileUrl ,disabledField}) => {
    return (
        <div>
            <div className="list-inline-item sort-picture-item attachment-img" >
                <div className="dropped-pic-card" style={{ height: "200px", width: "200px", backgroundSize: 'cover', backgroundImage: `url(${fileUrl})` }}>
                    <div className="dropped-picture-cta">
                      {disabledField ? null : <span className='cursor-pointer' onClick={() => helperFunc.downloadFile(fileUrl)}>
                            <GetAppIcon color="primary" fontSize='small' />
                        </span>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ImagePreview


