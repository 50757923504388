import React, { useState } from 'react';
import { Table, TableBody, TableHead, Paper, Grid, TextField, Typography, TableContainer, TableRow, TableCell} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CkEditorText } from '../../../shared_elements';
import { withRouter } from 'react-router'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { onCheckFormPermission } from '../../../utils_v2';
import { displayDateFormatShort, backendDateFormat } from '../../../constants';
import MomentUtils from '@date-io/moment';
import { regexConstants } from '../../../constants/regEx';
function TechnicalRecords({ data, onFieldChange, callthisapi, setData, currentFormDetail, setInitialValue, isLoading }) {

    return (
        <section className='form-t015'>
            <Paper square style={{ padding: '10px', marginBottom: "5px" }} elevation={8}>
                <h5>Technical Records Review :</h5>
                <Grid container spacing={0}>
                    <ul className='list-inline' >
                        <li className="list-inline-item">
                            <h5 style={{ marginTop: '5px' }}>As of date (Reference of LLP Status):</h5>
                        </li>
                        <li className="list-inline-item" style={{ display: 'inline-flex' }}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    margin="normal"
                                    id="as_of_date_llp"
                                    format={displayDateFormatShort}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={data?.technical_records?.as_of_date_llp ? data?.technical_records?.as_of_date_llp : null}
                                    onChange={(date) => onFieldChange('', 'as_of_date_llp', date ? moment(date).format(backendDateFormat) : null, 'technical_records', '', 'date')}
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </li>
                    </ul>
                </Grid>
                <Table className='mui-table-format-t015'>
                    <TableHead className='table-align-head'>
                        <TableRow>
                            <TableCell> <h6>Engine Model</h6> </TableCell>
                            <TableCell> <h6>MSN</h6> </TableCell>
                            <TableCell> <h6>TSN</h6> </TableCell>
                            <TableCell> <h6>CSN</h6> </TableCell>
                            <TableCell> <h6>Leased Thrust</h6> </TableCell>
                            <TableCell> <h6>Max Thrust Exceeded</h6> </TableCell>
                            <TableCell> <h6>Data Plate Photo</h6> </TableCell>
                            <TableCell> <h6>Owner Plate Photo</h6> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='table-align-body'>
                        <TableRow>
                            <TableCell >
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    placeholder="Enter.."
                                    id='model_id'
                                    inputProps={{ maxLength: 50 }}
                                    value={currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.aircraft_type : currentFormDetail?.asset?.engine_type}
                                    disabled
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    id='msn'
                                    margin='normal'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 50 }}
                                    value={currentFormDetail?.asset?.asset_type === 1 ? currentFormDetail?.asset?.msn : currentFormDetail?.asset?.esn}
                                    disabled
                                />
                            </TableCell>
                            <TableCell>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        placeholder="Enter.."
                                        id='tsn'
                                        margin='normal'
                                        inputProps={{ maxLength: 10 }}
                                        value={data?.technical_records?.tsn || ''}
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, 'technical_records') : e.preventDefault() }}

                                    />
                            </TableCell>
                            <TableCell>
                                    <TextField
                                        disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                        variant='outlined'
                                        fullWidth
                                        margin='normal'
                                        id='csn'
                                        inputProps={{ maxLength: 10 }}
                                        placeholder="Enter.."
                                        onFocus={(e) => setInitialValue(e.target.value)}
                                        onBlur={(e) => { callthisapi('', e.target.value); }}
                                        value={data?.technical_records?.csn || ''}
                                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value ? e.target.value : null, 'technical_records') : e.preventDefault() }}
                                    />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    margin='normal'
                                    id='leased_thrust'
                                    placeholder="Enter.."
                                    inputProps={{ maxLength: 50 }}
                                    value={data?.leased_tr || ''}
                                    disabled
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: false, name: "NO" }, { id: true, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_max_thrust_exceeded"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.is_max_thrust_exceeded))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_max_thrust_exceeded', value?.id, 'technical_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: false, name: "NO" }, { id: true, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_dateplate_photo"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.is_dateplate_photo))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_dateplate_photo', value?.id, 'technical_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: false, name: "NO" }, { id: true, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_owner_plate_photo"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.is_owner_plate_photo))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_owner_plate_photo', value?.id, 'technical_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <h5 >Records Received: </h5>
                <Table className='mui-table-format-t015'>
                    <TableHead className='table-align-head'>
                        <TableRow>
                            <TableCell> <h6>Last EASA/FAA ARC</h6> </TableCell>
                            <TableCell> <h6>LLP Status</h6> </TableCell>
                            <TableCell> <h6>LLP Trace</h6> </TableCell>
                            <TableCell> <h6>AD Status</h6> </TableCell>
                            <TableCell> <h6>Last BSI Report</h6> </TableCell>
                            <TableCell> <h6>ECM/Trend Data</h6> </TableCell>
                            <TableCell> <h6>Operation History</h6> </TableCell>
                            <TableCell> <h6>Accy/QEC Listing</h6> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='table-align-body'>
                        <TableRow>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_last_easa_faa_arc"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_last_easa_faa_arc))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_last_easa_faa_arc', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_llp_status"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_llp_status))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_llp_status', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_llp_trace"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_llp_trace))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_llp_trace', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_ad_status"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_ad_status))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_ad_status', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_last_sbi_report"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_last_sbi_report))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_last_sbi_report', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_ecm_trend_data"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_ecm_trend_data))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_ecm_trend_data', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>

                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_operation_historyop"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_operation_history))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_operation_history', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                            <TableCell >
                                <Autocomplete
                                    disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                    options={[{ id: 0, name: "NO" }, { id: 1, name: "YES" }]}
                                    getOptionLabel={option => option?.name}
                                    id="is_accy_qec_listing"
                                    onFocus={(e) => setInitialValue(e.target.value)}
                                    onBlur={(e) => { callthisapi('', e.target.value); }}
                                    value={[{ id: false, name: "NO" }, { id: true, name: "YES" }].filter((item => item?.id == data?.technical_records?.received_records?.is_accy_qec_listing))[0] || null}
                                    onChange={(e, value) => onFieldChange(e, 'is_accy_qec_listing', value?.id, 'technical_records', '', '', 'received_records')}
                                    renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <h5 style={{ margin: '10px 0px 10px' }}>Other Records Received :</h5>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.technical_records?.tech_other_records_review || ''}
                                onBlurData={() => callthisapi('', data?.technical_records?.tech_other_records_review)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    technical_records: {
                                        ...data.technical_records,
                                        tech_other_records_review: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
                <h5 style={{ margin: '10px 0px 10px' }}>Technical Records Findings / Discrepancies / Additional Notes :</h5>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.technical_records?.tech_record_additional_notes || ''}
                                onBlurData={() => callthisapi('', data?.technical_records?.tech_record_additional_notes)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    technical_records: {
                                        ...data.technical_records,
                                        tech_record_additional_notes: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
                <h5 style={{ margin: '10px 0px 10px' }}>Tech Record Management :</h5>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField variant='outlined'
                            disabled
                            value={data?.lessee_id || ''}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter"
                            style={{ verticalAlign: 'middle' }}
                        />
                        <span className='span-style'>policy for managing physical engine record.</span>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <CkEditorText
                                placeholder='Please enter.. details on offsite/onsite storage, '
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data.technical_records?.site_storage || ''}
                                onBlurData={() => callthisapi('', data.technical_records?.site_storage)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    technical_records: {
                                        ...data.technical_records,
                                        site_storage: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <span>The engine technical records storage facility was</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['viewed', 'not viewed', 'N/A']}
                            getOptionLabel={option => option}
                            id="is_facility"
                            style={{ display: 'inline-grid', marginLeft: '5px', }}
                            className='engine-stand-select'
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['viewed', 'not viewed', 'N/A'].filter(item => item === data?.technical_records?.tech_record_management_para?.is_facility)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'is_facility', value, 'technical_records', '', '', 'tech_record_management_para') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' margin="normal" fullWidth />}
                        />
                        <span className='span-style'>by the inspector.</span>
                        <span>The storage rooms</span>
                        <Autocomplete
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            options={['was', 'was not']}
                            getOptionLabel={option => option}
                            id="is_room"
                            style={{ display: 'inline-grid', marginLeft: '5px', width: '125px' }}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={['was', 'was not'].filter(item => item === data?.technical_records?.tech_record_management_para?.is_room)[0] || null}
                            onChange={(e, value) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'is_room', value, 'technical_records', '', '', 'tech_record_management_para') }}
                            renderInput={params => <TextField variant='outlined' {...params} placeholder='Select...' />}
                        />
                        <span className='span-style'>adequately secured and fire protected.</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span>The maintenance of physical engine records is managed by the  </span>
                        <TextField
                            disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                            variant='outlined'
                            id='manage'
                            InputLabelProps={{ shrink: true }}
                            onFocus={(e) => setInitialValue(e.target.value)}
                            onBlur={(e) => { callthisapi('', e.target.value); }}
                            value={data?.technical_records?.tech_record_management_para?.manage || ''}
                            onChange={(e) => { e.preventDefault(); e.stopPropagation(); onFieldChange(e, 'manage', e.target.value, 'technical_records', '', '', 'tech_record_management_para') }}
                            placeholder="Enter"
                            style={{ verticalAlign: 'middle' }}
                        />
                        <span className='span-style'>team.</span>
                    </Grid>
                    <h5 style={{ margin: '10px 0px 10px' }}> General comments on Tech Records :</h5>
                    <Grid item xs={12} md={12} sm={12}>
                        <div>
                            <CkEditorText
                                placeholder='If yes, please enter details, if no please enter.. "N/A"'
                                disabled={!onCheckFormPermission(currentFormDetail) || isLoading}
                                htmlData={data?.technical_records?.tech_general_comment || ''}
                                onBlurData={() => callthisapi('', data?.technical_records?.tech_general_comment)}
                                onFocusData={(e, value) => setInitialValue(e, value)}
                                onChangeData={(value) => setData({
                                    ...data,
                                    technical_records: {
                                        ...data.technical_records,
                                        tech_general_comment: value
                                    }
                                })}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </section>
    );
}

export default withRouter(TechnicalRecords)