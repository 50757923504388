import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../constants';
import { validateDateKeyArr } from '../t005';
export default function DateComp({ label, value, keyParam, disabled, onFieldChange }) {
  let required
  if(keyParam === "date_of_manufacture"){
    required = true
  }else required= false
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        inputVariant="outlined"
        margin="normal"
        id={keyParam}
        label={label}
        format={fieldDateFormat}
        fullWidth
        helperText=""
        error = ""
        required={required}
        // inputProps={{ readOnly: true }}
        clearable={true}
        minDate={moment().subtract(40, 'years')}
        disableFuture={validateDateKeyArr?.includes(keyParam) ? true : false}
        InputLabelProps={{ shrink: true }}
        value={value ? value : null}
        disabled={disabled}
        onChange={(data, value) => onFieldChange(value, keyParam, data ? moment(data).format(backendDateFormat) : null)}
      />
    </MuiPickersUtilsProvider>
  )
}
