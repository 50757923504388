import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Paper } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { PageLoader, EmptyCollection } from '../../../shared_elements';
import { checkApiStatus, getLocalStorageInfo, downloadFileType, onCheckPermission } from '../../../utils_v2';
import { displayDateFormat } from '../../../constants';
import { globalGetService, globalExportService } from '../../../utils_v2/globalApiServices';
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';

const OprTracker = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [oprReport, setOprReport] = useState([]);
    useEffect(() => {
        getOprReport('skeletonLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'Opr Tracker',
        });
    },[]);
    const getOprReport = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/project/opr/report/fleet-level/`)
        .then(response => {
            if(checkApiStatus(response)){
                setOprReport(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const exportOprReport = (extension='xls') => {
        enqueueSnackbar('OPR Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalExportService(`technical/project/opr/report/fleet-level/`, {download:'xls',type:'gantt'})
        .then(response => {
            downloadFileType(response, `OPR_Report_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`, 'xls');
            closeSnackbar();
            enqueueSnackbar('OPR Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
            trackActivity('Project Management', {
                event_type: 'Item Exported',
                page_title: 'Opr Tracker',
                file_extension: 'xls',
                file_name: `OPR_Report_${getLocalStorageInfo()?.defaultLessor?.name}_${moment().format('YYYY-MM-DD')}`
            });
        })
    }
    return(
        <section className='projects-management-section'>
            <DeploymentMessage />
            <div style={{display:'flex', padding:'5px 0'}}>
                { onCheckPermission('project_management','opr_report','EXP') && oprReport.length ?
                    <Button style={{marginLeft:'auto'}} onClick={exportOprReport} color='primary' variant='outlined' size='small'>Export</Button>:null
                }
            </div>
            <Paper className='portfolio-fbv-table'>
                {skeletonLoader ? <STableLoader count={5} />:
                    <div>
                        {oprReport.length ?
                            <table border={1} className='outer-table'>
                                {(()=>{
                                    let minDate = moment(moment.min(oprReport.filter(item => item.next_inspection_window_lower).map(date => moment(date.next_inspection_window_lower))));
                                    let maxDate = moment(moment.max(oprReport.filter(item => item.next_inspection_window_upper).map(date => moment(date.next_inspection_window_upper))));
                                    let betweenMonths = [];
                                    if (minDate < maxDate){
                                        let date = minDate.startOf('month');
                                        while (date < maxDate.endOf('month')) {
                                            betweenMonths.push(date.format('MMM-YY'));
                                            date.add(1,'month');
                                        }
                                    }
                                    return(
                                        <>
                                            <thead className='outer-head'>
                                                <tr>
                                                    <th className='child-one'><div style={{width:'140px'}}>Lessee/Operator</div></th>
                                                    <th className='child-two'><div style={{width:'140px'}}>Inspection Date</div></th>
                                                    <th className='child-three'><div style={{width:'140px'}}>OPR Score <span style={{fontSize:'11px'}}>(out of 100)</span></div></th>
                                                    <th className='child-four'><div style={{width:'140px'}}>Inspection By Date</div></th>
                                                    <th className='child-five'><div style={{width:'140px'}}>Inspection Window</div></th>
                                                    { betweenMonths.map((period) => <th align='center'><div style={{width:'100px', textAlign:'center'}}>{period}</div></th>)}
                                                </tr>
                                            </thead>
                                            <tbody className="outer-body">
                                                {oprReport.map((item, index) =>
                                                    <tr key={index}>
                                                        <th className='child-one'>
                                                            <div>{item.operator}</div>
                                                        </th>
                                                        <th className='child-two'>{item.date_of_inspection? moment(item.date_of_inspection).format('MMMM-YY'):'Pending Initial Inspection'}</th>
                                                        <th className='child-three'>{item.total_rating?item.total_rating:'NA'}</th>
                                                        <th className='child-four'>{item.limiting_inspection_date ? moment(item.limiting_inspection_date).format('MMMM-YY'):'--'}</th>
                                                        <th className='child-five'>{item.next_inspection_window_lower ? moment(item.next_inspection_window_lower).format('MMMM-YY'):''} - {item.next_inspection_window_upper ? moment(item.next_inspection_window_upper).format('MMMM-YY'):''}</th>
                                                        {(() => {
                                                            let startIndex = betweenMonths.indexOf(moment(item.next_inspection_window_lower).format('MMM-YY'));
                                                            let endIndex = betweenMonths.indexOf(moment(item.next_inspection_window_upper).format('MMM-YY'))
                                                            if(startIndex === -1 || endIndex === -1){
                                                                return null
                                                            }else{
                                                                return(
                                                                    <>
                                                                        {startIndex !== 0 ? Array(startIndex).fill().map((v,i)=> <td></td>) :null}
                                                                        <td colSpan={endIndex-startIndex+1}>
                                                                            <div style={{background:'#4b903f', height:'20px', width:'100%', borderRadius:'20px'}}></div>
                                                                        </td>
                                                                        {(betweenMonths.length-1) !== endIndex ? Array((betweenMonths.length-1)-endIndex).fill().map((v,i) => <td></td>):null}
                                                                    </>
                                                                )
                                                            }

                                                        })()}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </>
                                    )
                                })()}
                            </table>:null
                        }
                        {oprReport.length === 0 ?<div style={{textAlign:'center', padding:'80px 0'}}><EmptyCollection title='No Record Found'/></div>:null}
                    </div>
                }
            </Paper>
            {isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default OprTracker;
