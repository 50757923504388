import React from "react"
import { DialogueWrapper } from "../shared"

const PreviewFileModal = ({ previewFile, toggleModalFn }) => {

    return <DialogueWrapper isPreview={true} maxWidth="lg" open={previewFile.modal} dialogueTitle="Preview" dialogueWidth="1200px" toggleModalFn={toggleModalFn}  >
        {previewFile?.singlePreview ? previewFile?.data?.map((file, index) => (
            <div key={index}>
                {
                    file?.type === 'application/pdf' ? (
                        <object type='text/html' data={URL.createObjectURL(file)} style={{ width: '100%', height: '100vh' }}>
                            <param name='type' value='text/html' />
                        </object>
                    ) : 
                    
                    file.type.startsWith('image/') ? (
                        <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100%', height: '100vh' }} />
                    ) : file?.type ===  "text/csv"&& <div style={{display:"flex",alignItems : "center", justifyContent:"center"}}>Please Download csv file for Preview.</div>
                }
            </div>
        ))
            :
            previewFile?.data?.file_name?.endsWith('.xls') || previewFile?.data?.file_name?.endsWith('.xlsx') || previewFile?.data?.file_name?.endsWith('.docx') || previewFile?.data?.file_name?.endsWith('.doc') ?
                <div>
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewFile?.data?.file_url)} width='100%' height={window.innerHeight} frameborder='0'></iframe></div> : null
        }
    </DialogueWrapper>
}

export default PreviewFileModal