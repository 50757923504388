import React from 'react';
import { TextField, } from '@material-ui/core';

export default function TextComp({ label, keyParam, value, disabled, onFieldChange, question,checkBoxComponent }) {
  let updatedQuestion
 if(keyParam === "installed_not_installed_provisioned_not_installed" || keyParam=== "tcas_version"){
    updatedQuestion = { ...question, width:"350px" }
    } else updatedQuestion = { ...question }
  return (
    <div   style={{width : updatedQuestion?.width ? updatedQuestion?.width : null}}>
       <TextField
       label={<span>{question&& question?.is_fitted === "true" ? checkBoxComponent : label}</span>}
      id={keyParam}
      fullWidth
      margin="normal"
      value={value}
      onChange={(e) => {onFieldChange(e, keyParam, e.target.value.replace(/^\s+/, "")) }}
      helperText=''
      InputLabelProps={{ shrink: true,style: { pointerEvents: 'auto' } }}
      disabled={disabled}
      variant="outlined"
      inputProps={ { maxLength: 250 }}
    />
    </div>
   
  )
}

