import React, { useState, createContext } from 'react';
import { browserHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import AircraftComponent from '../components/AircraftComponent';
import { useFetchFormApi } from "../apiServices"
import { FormSkeletonLoader, FormWrapper, DialogueWrapper } from '../../Elements';
import FindingListModal from '../components/FindingListModal';
import { ExportManu, PageLoader } from '../../../shared_elements';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../shared_elements/components/InvalidUrl';
import "../../assets/styles/_t007.scss";

export const FindingContext = createContext();

const PictorialFindings = (props) => {
    const [modalAction, setModalAction] = useState({ listModal: false, addModal: false, uploadModal: false, editModal: false, deleteModal: false, previewModal: false, deleteImageModal: false })
    const [findingsData, setFindingsData] = useState({ section: {},imageIndex : null,imageId:null,id:null })
    const { loaderType, fetchData, setFetchData, changeFormStatus, getFindingList, addEditFinding, getFinding, deleteFinding, deleteFindingImage, annotateFindingImg, pictorialFindingReport, changeFindingImage } = useFetchFormApi(props)
    let formViewPermission = fetchData?.currentFormDetail && Object.keys(fetchData?.currentFormDetail).length && fetchData?.currentFormDetail?.list?.length && fetchData?.currentFormDetail?.list?.find(form => form.slug === fetchData?.currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(fetchData?.currentFormDetail?.current_form?.name)
    const hasNonZeroFindingCount = fetchData?.findingSection?.list?.some((item) => item.finding_count !== 0);
    const actionDisabled = fetchData?.currentFormDetail?.current_form?.permissions?.can_save === false
    const toggleModalFn = (status, type) => { setModalAction({ ...modalAction, [type]: status }); }
    const onSectionClick = (finds) => { toggleModalFn(true, "listModal"); getFindingList(finds.id); setFindingsData({ ...findingsData, section: finds }) }
   
    return (
        <section className='project-forms-section-t007'>
            {formViewPermission && formType && fetchData?.currentFormDetail && Object.keys(fetchData?.currentFormDetail).length ?
                <FormWrapper
                    hideAction={fetchData?.currentFormDetail?.current_form.progress === 0 ? true : false}
                    formInfo={fetchData?.currentFormDetail}
                    exportReportBtn={<ExportManu disabled={!hasNonZeroFindingCount} exportReportFn={(file) => pictorialFindingReport(file.extension)} title="Export Report" files={[{ title: 'PDF', extension: 'pdf', key: '' }, { title: 'Doc', extension: 'docx', key: '' }]} />}
                    updateFormStatusFn={(data) => changeFormStatus(data)}
                />
                : loaderType?.skeletonLoader ? <FormSkeletonLoader /> : null
            }

            {loaderType?.skeletonLoader ? [1].map(item => <Skeleton style={{ padding: "5px 15px 5px 10px", marginBottom: '2px', margin: "18px" }} variant="rect" width={(window.innerWidth - 109) + 'px'} height={(window.innerHeight - 80) + 'px'} />) :
                <>
                    {
                        formType ? formViewPermission ?
                            <AircraftComponent props={props} fetchData={fetchData} onSectionClick={onSectionClick} />
                            : loaderType?.skeletonLoader || loaderType?.pageLoader ? null :<RestrictedAccess />:
                            <InvalidUrl getResponseBack={() => browserHistory.push(`/technical/project/view/${fetchData?.currentFormDetail?.project?.slug}`)}/>
                    }
                </>
            }
            <DialogueWrapper dialogueWidth={800} maxWidth="lg" open={modalAction?.listModal} dialogueTitle={findingsData?.section?.name} toggleModalFn={() => { toggleModalFn(false, "listModal"); setFetchData({ ...fetchData, findingList: [] }) }} isPreview={true}>
                <FindingContext.Provider value={{ props, loaderType, fetchData, actionDisabled, findingsData, modalAction, setFindingsData, toggleModalFn, addEditFinding, getFindingList, getFinding, deleteFinding, deleteFindingImage, annotateFindingImg, changeFindingImage }}> <FindingListModal /></FindingContext.Provider>
            </DialogueWrapper>
            {loaderType?.pageLoader && !loaderType?.skeletonLoader ? <PageLoader /> : null}
        </section>
    );
};

export default PictorialFindings
