import React, { Fragment } from "react";
import { Link, withRouter } from 'react-router';
import ProjectDetailTab from '../../projects/components/ProjectDetailTab';
import { ProjectBasicDetail } from '../../Elements'
import EditCurrencyNote from './EditCurrencyNote'
import { Paper } from "@material-ui/core";
import { trackActivity } from '../../../utils/mixpanel';
import { onCheckPermission } from "../../../utils_v2";
const BudgetWrapper = ({ params, children, handleTabChange, project, totalExpense, getResponseBack }) => {
    return (
        <div>
            <ProjectDetailTab tabIndex={'budget'} handleTabChange={() => console.log('')} />
            {project && Object.keys(project).length ? project.currency ? null : <EditCurrencyNote /> : null}
            <ProjectBasicDetail getResponseBack={getResponseBack} project={project} totalExpense={totalExpense} />
            <Paper className="budget-management-section">
                <div className="sidebar-menu">
                    { onCheckPermission('expense_management','expense','R') ?
                        <div className="menu-list">
                            <Link activeClassName="active" to={`/technical/project/${params.project_slug}/budget/expense`} onClick={() => trackActivity('Project Management', {page_title: 'Project Detail', event_type: 'Item Clicked', event_desc: 'Clicked on Expense Menu Tab' })}>Expense</Link>
                        </div>
                        : null
                    }
                    <Fragment>
                        { onCheckPermission('expense_management','budget_allocation','R')?
                            <div className="menu-list">
                                <Link activeClassName="active" to={`/technical/project/${params.project_slug}/budget/allocation`} onClick={() => trackActivity('Project Management', {page_title: 'Project Detail', event_type: 'Item Clicked', event_desc: 'Clicked on Budget Allocation Menu Tab' })}>Budget Allocation</Link>
                            </div>
                            : null
                        }
                        {onCheckPermission('expense_management', 'analytics', 'R')?
                            <div className="menu-list">
                                <Link activeClassName="active" to={`/technical/project/${params.project_slug}/budget/analytics`} onClick={() => trackActivity('Project Management', {page_title: 'Project Detail', event_type: 'Item Clicked', event_desc: 'Clicked on Analytics Menu Tab' })}>Analytics</Link>
                            </div>
                            : null
                        }
                    </Fragment>
                </div>
                <div className="budget-management-content" style={{minHeight: `${window.innerHeight-165}px`}}>
                    <div style={{ padding: '15px 15px 5px 15px' }}>
                        {children}
                    </div>
                </div>
            </Paper>
        </div>
    )
}
export default withRouter(BudgetWrapper);
